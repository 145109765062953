import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProcesses } from '../../actions/processesActions';
import { PAGINATION } from '../../actions/constants';
import Loading from '../Loading';
import Process from './Process';
import PaginationSearch from "../../components/utils/PaginationSearch";
import { Link } from 'react-router-dom';
import IconTooltip from '../utils/IconTooltip';
import  Searcher  from '../utils/Searcher';
import { isEmpty,map } from '../../utils';


const ProcessesList = ({location,currentUser}) => {
  const [loading, setLoading] = useState(false);
  const fromObserver = location.pathname.includes('/observers/');

  const processes = useSelector(state => state.processes)
  const pagination = useSelector(state => state.processes.pagination)
  const search = useSelector(state => state.processes.search)  

  const dispatch = useDispatch()

    useEffect(()=>{
      processesGet({pagination: PAGINATION, isObserver: fromObserver
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const processesGet = (pagination) => {
      setLoading(true);
      const errorCallback = (error) => {
        setLoading(false);
        console.log('Error in fetching', error);
      };
      dispatch(getProcesses(pagination, (data) => {
        setLoading(false);
      }, errorCallback))
    }
  

    const renderHeader = () => {
      if (!isEmpty(processes.processes)) {
        return (
          <thead>
            <tr>
              <td style={{textAlign: 'center'}}>#</td>
              <td>Título</td>
              <td style={{textAlign: 'center'}}>Fecha de vencimiento</td>
              { (currentUser.isObserverAdmin && !fromObserver) ? (
                  <td style={{textAlign: 'center'}}>Acciones</td>
                ): 
              <></>}
            </tr>
          </thead>
        );
      } else {
        return null;
      }
    }

    const headerItems = [
      {
        label: '#',
        value: 'observation_processes.id',
        type: 'number'
      },
      {
        label: 'Titulo',
        value: 'observation_processes.title',
        type: 'text'
      }
    ]

    const renderProcess = () => {
      if (isEmpty(processes.processes)) {
        return <h4 style={{textAlign: 'center'}}>sin procesos de observación</h4>;
      } else {
        return map(processes.processes, (process) => {
          return (
            <Process key={process.id} process={process} isObserver={fromObserver} currentUser={currentUser} />
          );
        });
      }
    }

  return (
    <>
      <h1>Procesos de observación</h1>
      <Searcher
          columns ={headerItems}
          apiUrl= {PAGINATION}
          action = {processesGet}
          extraParams = {{isObserver: fromObserver}}
      />
      <br />
      <div className="row col-md-12 col-xs-12">
        <ul className="list-group col-md-12 col-xs-12">
          <div className="table-responsive">
            <table className="table table-bordered dt-responsive table-striped">
              {renderHeader()}
              <tbody>
              {loading ? (
                <tr>
                  <td id="loadingUser">
                    <Loading/>
                  </td>
                </tr>
              ): renderProcess()}
              </tbody>
            </table>
          </div>
        </ul>
      </div>
      {pagination &&
        <PaginationSearch
          paginationOptions={pagination}
          onPagination={processesGet}
          extraParams = {{isObserver: fromObserver}}
          search = {search}
        />
      }

      { (currentUser.isObserverAdmin && !fromObserver) ? (
        <div className="row">
          <div className="col-md-6 col-xs-6 ">
            <Link to={`/`} className="btn btn-danger ">
              Volver
            </Link>
          </div>
          <div className="col-md-6 col-xs-6 ">
            <Link to="/processes/new" className="btn btn-default pull-right">
              <IconTooltip text="Crear proceso" icon="fa-plus" size="fa-2x"/>
            </Link>
          </div>
        </div>
      ):<></>
    }

    
  </>
  );

};

export default ProcessesList
