import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';


export const GET_LABEL_ANSWERS = 'GET_LABEL_ANSWERS';

export const GET_LABEL_ANSWER = 'GET_LABEL_ANSWER';

export const DELETE_LABEL_ANSWER = 'DELETE_LABEL_ANSWER';

export const SELECT_LABEL_ANSWER = 'SELECT_LABEL_ANSWER';

export const UPDATE_TEXT_LABELS = 'UPDATE_TEXT_LABELS';

export const REPORT_LABEL_ANSWERS = 'REPORT_LABEL_ANSWERS';

export const RESET = 'RESET';



/**
 * Obtiene todas las ventanas de aplicación de un examen en la aplicación.
 *
 * @export GET_LABELS
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getLabelAnswersByAnswer(idAnswer, successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/label_answers/${idAnswer}/byAnswer`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_LABEL_ANSWERS,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function getLabelAnswerByAnswerGrader(idAnswer, successCallback = () => {}, errorCallback = () => {}) {

	const request = axios.get(`${ROOT_URL}/label_answers/${idAnswer}/byAnswerGrader`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_LABEL_ANSWER,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function SetFinal(idLabelAnswer, successCallback = () => {}, errorCallback = () => {}) {

	const request = axios.get(`${ROOT_URL}/label_answers/${idLabelAnswer}/set_final`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_LABEL_ANSWER,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function namualRefresh(data) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_TEXT_LABELS,
			payload: data
		});

	}
}

export function selectLabelAnswer(labelAnswer) {
	return (dispatch) => {
		dispatch({
			type: SELECT_LABEL_ANSWER,
			payload: labelAnswer
		});

	}
}

export function resetLabelAnswers() {
	return (dispatch) => {
		dispatch({
			type: RESET,
			payload: {}
		});

	}
}

export function saveLabelAnswer(data, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.post(`${ROOT_URL}/label_answers`, data, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_LABEL_ANSWER,
				payload: data
			});
			successCallback(data);
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function updateSelections(id, data, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.patch(`${ROOT_URL}/label_answers/${id}/updateSelections`, data, getAuthHeaders());

	return (dispatch) => {
		
		request.then(({data}) => {
			console.log(id, data)
			dispatch({
				type: GET_LABEL_ANSWER,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function changeLabelAnswer(data) {
	return (dispatch) => {

		dispatch({
			type: GET_LABEL_ANSWER,
			payload: data
		});
		
	}
}


export function getAllForExam(id, successCallback = ()=> {}, errorCallback = ()=> {}) {
	const request = axios.get(`${ROOT_URL}/label_answers/${id}/download_like_ls`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: REPORT_LABEL_ANSWERS,
				payload: data
			});
			successCallback(data);
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function allByExam(id, successCallback = ()=> {}, errorCallback = ()=> {}) {
	const request = axios.get(`${ROOT_URL}/label_answers/${id}/download_by_exam`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: REPORT_LABEL_ANSWERS,
				payload: data
			});
			successCallback(data);
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

