import axios from 'axios';
import swal from 'sweetalert2';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las respuestas de banco.

// Actualiza todas las respuestas de banco en el estado de la aplicación.
export const GET_ANSWER_BANK = 'GET_ANSWER_BANK';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_ANSWER_FROM_BANK = 'GET_ANSWER_FROM_BANK';

// Remueve la pregunta del estado de la aplicación.
export const DELETE_ANSWER_FROM_BANK = 'DELETE_ANSWER_FROM_BANK';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_ANSWERS_GRADER_FROM_BANK = 'GET_ANSWERS_GRADER_FROM_BANK';


// Actualiza la pregunta dada del estado de la aplicación.
export const AGREGATE_ANSWERS_GRADER_TO_BANK = 'AGREGATE_ANSWERS_GRADER_TO_BANK';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_ANSWER_BANK_MESSAGE = 'GET_ANSWER_BANK_MESSAGE';


/**
 * Obtiene las respuestas de banco disponibles.
 *
 * @export getAnswerBank
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerBank(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/answer_banks${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la respuesta de banco con el id dado por parámetro.
 *
 * @export getTest
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerFromBank(id,isAnswerBank, successCallback = () => {}, errorCallback = (error) => {}) {
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { isAnswerBank: isAnswerBank }
  }
  const request = axios.get(`${ROOT_URL}/answer_banks/${id}`, config);

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_FROM_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la respuesta de banco con el id dado por parámetro.
 *
 * @export updateAnswerBankAnswer
 * @param {*} id : El id del test dado por parámetro
 * @param {*} test : El objeto con los datos de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateAnswerFromBank(id, answer, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_banks/${id}`, {answer: answer}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_FROM_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina la pregunta con el id dado por parámetro.
 *
 * @export deleteAnswerFromBank
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteAnswerFromBank(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/answer_banks/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: DELETE_ANSWER_FROM_BANK,
        payload: id
      });
      if(data==[]) {
        successCallback();
      }else{
        swal({
          type: 'warning',
          title: 'La respuesta ya esta asociada a un examen y no puede ser eliminada.',
          showConfirmButton: false,
          timer: 2600
        })
      }
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina la pregunta con el id dado por parámetro.
 *
 * @export getAnswerGradersFromBank
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getAnswerGradersFromBank(matrixId,graderId, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_banks/answers_grader?matrix_id=${matrixId}&grader_id=${graderId}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWERS_GRADER_FROM_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Actualiza la respuesta de banco con el id dado por parámetro.
 *
 * @export agregateAnswerGraderToBank
 * @param {*} id : El id del test dado por parámetro
 * @param {*} test : El objeto con los datos de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function agregateAnswerGraderToBank(values, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_banks/agregate_answer`, {answers_graders: values}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: AGREGATE_ANSWERS_GRADER_TO_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el resultado de la operacion de asociar docentes.
 *
 * @export addAnswerBankFromExcel
 
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function addAnswerBankFromExcel(answersInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_banks/agregate_answer_from_excel`, answersInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_BANK_MESSAGE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}