import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';


/**
 * Componente Reacondicionado con React Hooks !! por w.garcia24 
 * 
 * Componente para cambiar el application.css según el dominio desde el que se esté ingresando
 */

const Tenant = () => {
    // Use the useSelector hook to access the currentUser and currentTenant from the Redux store
    const currentTenant = useSelector(state => state.tenant.tenant);

    const [tenantConfig, setTenantConfig] = useState(null);

    useEffect(() =>{
        getTenantConfig(currentTenant)
        if (process.env.NODE_ENV === 'development') {
            console.log(` activating currentTenant '${currentTenant}'`)
            console.log (`process environment = ${JSON.stringify(process.env, null, 4)}`)
        }
    },[currentTenant])

    const getTenantConfig = async (currentTenant) => {
        const config = await import(`../../assets/tenant_config/${currentTenant}/config.js`);
        setTenantConfig(config.Tenant_config);
        // console.log (`config!! ${config}`)
    }
    if (!tenantConfig) {
        return null;
    }
    return (
        <Helmet>
            <title>{`${tenantConfig.title}`}</title>
            <meta name="description" content={tenantConfig.description.content}/>
            <meta name="keywords" content={tenantConfig.keywords.content}/>
            <link rel="manifest" href={process.env.PUBLIC_URL+`/${currentTenant}/manifest.json`}></link>
        </Helmet>
        )  
    
}

export default Tenant;
