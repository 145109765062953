import _ from 'lodash';
import {  GET_GRADERS,  GET_REVIEWERS, GET_USER_GRADING_MATRIX } from '../actions/usersGradingMatricesActions';

export default function (state = {}, action) {
    switch(action.type) {
    case GET_USER_GRADING_MATRIX:
        const userGradingMatrix =  action.payload;
    return { ...state, userGradingMatrix: userGradingMatrix };
    case GET_GRADERS:
      const graders = _.mapKeys(action.payload, 'id');
      return { ...state, graders: graders };
    case GET_REVIEWERS:
      const reviewers = _.mapKeys(action.payload, 'id');
      return { ...state, reviewers: reviewers };
    default:
    return state;
    }
}
