import React, {useEffect} from "react";
import useGetRequest from "../../../../customHooks/useGetRequest";
import McAnswer from "./McAnswer";
// import usePatchRequest from "../../../../customHooks/usePatchRequest";
// import Answer from "./Answer";

const QuestionaryAnswer = ({questionaryId}) => {

  const {data:mcQuesrionaryInfo, setData:setMcQuesrionaryInfo,  isLoading, error, refetch} = useGetRequest(
    `mc_answers/${questionaryId}/by_mc_questionaries`
  );

  // Si la carga de datos está en progreso, mostrar un mensaje de "Cargando..."
  if (isLoading) {
    return <div>Cargando...</div>;
  }

  // Si se produce un error al cargar los datos, mostrar un mensaje de error
   if (error) {
    return <div>Hubo un error al cargar la respuesta</div>;
  }

// Si los datos se han cargado correctamente, mostrar la información de respuesta
  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      {/* Mostrar un mensaje diferente según el estado de la respuesta */}
      
      {mcQuesrionaryInfo.status === "in_progress" && (
        <div>
          < McAnswer data={mcQuesrionaryInfo} examInfo={mcQuesrionaryInfo} setExamInfo={setMcQuesrionaryInfo} />
        </div>
      )}

      {mcQuesrionaryInfo.status === "done" && <h4>Respuesta Terminada</h4>}
    </div>
  );
}

export default QuestionaryAnswer