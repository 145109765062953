/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector , useDispatch} from 'react-redux';
import { createTheme, editTheme, getTheme } from "../../actions/themeActions";
import swal from "sweetalert2";


const STYLES = {
  main: {
    display: 'flex', 
    minHeight: '75vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  box_style: {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  }
}

const ThemeForm = (props) => {
	const id = props.match.params.id
	const [form, setForm] = useState({ name: '' })
  const dispatch = useDispatch()
	const theme = useSelector(state => state.themes.theme)

	const handleSubmit = e => {
		e.preventDefault()

		const successUpdate = () => {
			swal({
				type: 'success',
				title: `Tema ${id ? 'actualizado' : 'Creado'}`,
				showConfirmButton: false,
				timer: 1000
			}).then(()=> props.history.push('/themes'))
		}

		if (id) {
			dispatch(editTheme(id,form, successUpdate()))
		} else {
			dispatch(createTheme(form, successUpdate()))
		}
	}

	useEffect(() => {
		id && dispatch(getTheme(id))
	},[])

	useEffect(() => {
		(id && theme) && setForm({ ...form, name: theme.name })
	},[theme])

	return (
		<main>
			<form style={STYLES.main} onSubmit={handleSubmit}>
				<section>
					<h1>{id ? 'Editar' : 'Crear'} Tema</h1>
					<label>
						Nombre
						<input
							required
							type="text"
							className='form-control'
							value={form.name}
							onChange={({target}) => setForm({ ...form, name: target.value })}
						/>
					</label>
				</section>
				<section 
					style={{
						display: 'flex',
						alignItems: 'center',
						flexFlow: 'row nowrap',
						justifyContent: 'space-between',
					}}
				>
					<button
						onClick={() => props.history.push('/themes')}
						type="button"
						className="btn btn-danger">
						Volver
					</button>
					<button
						type="submit"
						className="btn btn-info">
						{id ? 'Actualizar' : 'Crear'} Tema
					</button>
				</section>
			</form>
    </main>
	)
}

export default ThemeForm