import axios from 'axios';

// Constantes para el manejo de los departamentos.
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_STUDENT_INFO = 'GET_STUDENT_INFO';

/**
 * Obtiene los estudiantes disponible.
 *
 * @export getStudent
 * @param id : identificador del estudiante.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getStudent(id, successCallback = () => {}, errorCallback = () => {}) {
    const request = axios.get(`${ROOT_URL}/users/studentInfo?id=${id}`, getAuthHeaders());
 
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_STUDENT_INFO,
          payload: data
        });
        successCallback(data);
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  