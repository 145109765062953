import React, {useEffect, useState} from "react";
import axios from 'axios';
import { ROOT_URL, getAuthHeaders, BLOB_ACCOUNT } from '../../../actions/constants';
import AnswerClosed from "./AnswerClosed";
import '../../../assets/stylesheets/StudentsInfo.css';
import Loading from '../../Loading';
import {Label } from 'react-bootstrap/lib'
import McAnswerClosed from "./McAnswerClosed";

export default function ExamGrades(props){
  const [answers, setAnswers] = useState([])
  const [urls, setUrls] = useState({})
  const [exam, setExam] = useState({})
	const [loading, setLoading] = useState(false)

  const {id} = props.match.params;

  const labels = {  
    to_do: <Label bsStyle='warning'>por hacer</Label>,
    in_progress: <Label bsStyle='info'>no entregado</Label>,
    done: <Label bsStyle='success'>entregado</Label>,
  }

  const handleChange = (e) => {

    const { target:accordionHeader } = e
    const accordionBody = accordionHeader.nextElementSibling;

    console.log(accordionHeader, accordionBody);
    
    accordionHeader.classList.toggle('accordion__header--active');
    accordionBody.classList.toggle('accordion__body--expand');
  }

  const onReloadVideo = (id) => {
    axios.get(`${ROOT_URL}/answers/${id}/multimedia_url?blob=${BLOB_ACCOUNT}&sip=${sessionStorage.getItem("ip_add")}`, getAuthHeaders())
    .then(({data})=> {
      setUrls(prevState => ({...prevState, [id]: data}))
      return null
    })
    .catch(exeption => {
      setUrls(prevState => ({...prevState, [id]: null}))
      return null
    })
  }

  useEffect(()=>{
    setLoading(true)
    const request = axios.get(`${ROOT_URL}/exams/closed?idExam=${id}`, getAuthHeaders());
    request.then(({data})=> { 
      setLoading(false)
      const examFiltered =  data.filter(obj => obj.id == id)
      const answersInPromise = []
      const urlsInPromise = []
      
      if (examFiltered.length > 0) {
        setExam(examFiltered[0])

        examFiltered[0].answers.map(respuesta => {
          console.log('respuesta: ', respuesta);
            answersInPromise.push(axios.get(
              `${ROOT_URL}/${respuesta.type === 'answers' ? 'answers' : 'mc_answers'}/${respuesta.id}`,
              getAuthHeaders()
            ).then(({data})=> {
              (respuesta.type === 'answers' && data.attachment) && onReloadVideo(respuesta.id)
              return data
            }))
        })
  
        Promise.all(answersInPromise).then(values => {
          setAnswers(values)
        }, reason => {
          console.log(reason)
        });

      }
    })
  }, [])

  return (
    <>
      <h3> Informe de notas para el examen: {exam.title}</h3>
      <hr />
      {!loading 
        ? ((exam) 
          ? (<>
            <div id="examGrades">
              <h2>Preguntas:</h2>
              <div className='accordion'>
                {answers.map((answer, i)=> {
                  
                  if (answer.examTestOptions) {
                    return (
                      <React.Fragment key={answer.id}>
                        <div id={answer.id} className={`accordion__header`} onClick={handleChange} data-toggle={'tooltip'} title={'Ver calificaciones'}>
                          <h4 style={{pointerEvents: 'none'}}>{answer.examTestOptions.title.toUpperCase()} {labels[answer.status]}</h4>
                        </div>
                        <div className='accordion__body' style={{marginBottom: '1rem'}} >
                          <AnswerClosed answer={answer} 
                            key={i}
                            history={props.history} 
                            url={urls[answer.id]?.url}
                            onReloadVideo={() => onReloadVideo(answer.id)} 
                          />
                        </div>
                      </React.Fragment>
                    )
                  }

                  return (
                    <React.Fragment key={answer.id}>
                      <div id={answer.id} className={`accordion__header`} onClick={handleChange} data-toggle={'tooltip'} title={'Ver calificaciones'}>
                        <h4>{answer.questionary.title.toUpperCase()} {labels[answer.status]}</h4>
                      </div>
                      <div className='accordion__body' style={{marginBottom: '1rem'}} >
                        <McAnswerClosed mcAnswer={answer} history={props.history} />
                      </div>
                    </React.Fragment>
                  )

                })}
              </div>
            </div>
            <div className="col-md-6 col-xs-6 ">
              <button className="btn btn-danger "
                  onClick={props.history?.goBack}>
                  Volver
              </button>
            </div>
          </>)
          : (
              <>
                <h1>No se encontró el registro</h1>
              </>
            ))
        : ( <Loading /> )
      }
    </>
  )
}
