import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de busquedas

// Busca el usuario
export const SEARCH_USER = 'SEARCH_USER';
// busca un test 
export const SEARCH_TEST= 'SEARCH_TEST';
// busca una matriz 
export const SEARCH_MATRIX= 'SEARCH_MATRIX';
// busca una matriz 
export const SEARCH_QUESTIONARY= 'SEARCH_QUESTIONARY';
// Busca un examen 
export const SEARCH_EXAM= 'SEARCH_EXAM';
// Busca un reporte
export const SEARCH_REPORT= 'SEARCH_REPORT';
// Busca un estudiante
export const SEARCH_STUDENT= 'SEARCH_STUDENT';
// Busca una respuesta de asignacion
export const SEARCH_ASSIGNATION_ANSWER= 'SEARCH_ASSIGNATION_ANSWER';
// Busca una respuesta de banco
export const SEARCH_ANSWERS_FROM_BANK= 'SEARCH_ANSWERS_FROM_BANK';
// Busca una respuesta de banco
export const SEARCH_SUPER_MATRIX_STUDENT= 'SEARCH_SUPER_MATRIX_STUDENT';


/**
 * Busca el usuario solicitado.
 *
 * @export searchUser
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function searchUser(email) {
    const request = axios.get(`${ROOT_URL}/searches/search_user?&email=${email}`,getAuthHeaders());
    
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: SEARCH_USER,
          payload: data
        });
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          console.log('Error fetching the user', error);
        }
      });
    }
  }

  /**
 * Busca el test solicitado.
 *
 * @export SearchTest
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function searchTest(title, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/searches/search_test?&title=${title}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_TEST,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

 /**
 * Busca la matriz solicitado.
 *
 * @export SearchMatrix
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
  export function searchMatrix(title, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/searches/search_matrix?&title=${title}`,getAuthHeaders());
    
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: SEARCH_MATRIX,
          payload: data
        });
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

   /**
 * Busca los cuestionarios solicitados
 *
 * @export SearchQuestionary
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
    export function searchQuestionary(title, errorCallback = (error) => {}) {
      const request = axios.get(`${ROOT_URL}/searches/search_questionary?&title=${title}`,getAuthHeaders());
      
      return (dispatch) => {
        request.then(({data}) => {
          dispatch({
            type: SEARCH_QUESTIONARY,
            payload: data
          });
        }).catch((error) => {
          if(!isUnauthorizedError(error, dispatch)) {
            errorCallback(error);
          }
        });
      }
    }
  
/**
 * Busca el examen solicitado.
 *
 * @export SearchExams
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function searchExams(attribute, query, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/searches/search_exam?attribute=${attribute}&query=${query}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Busca el examen solicitado.
 *
 * @export SearchReports
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function searchReports(attribute, query,type, successCallback = () => {}, errorCallback = (error) => {}, exam_test_option_id = ()=>{}) {
  const request = axios.get(`${ROOT_URL}/searches/search_report?attribute=${attribute}&query=${query}&type=${type}&exam_test_option_id=${exam_test_option_id}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Busca el estudiante solicitado.
 *
 * @export searchStudents
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function searchStudents(attribute, query,type, successCallback = () => {}, errorCallback = () => {}, exam_test_option_id = ()=>{}, loader = undefined) {
  const request = axios.get(`${ROOT_URL}/users/students?attribute=${attribute}&value=${query}&id=${exam_test_option_id}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      loader && loader(false);  
      dispatch({
        type: SEARCH_STUDENT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      loader && loader(false);
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Busca las respuestas de asignacion solicitadas.
 *
 * @export searchAssignationAnswer
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function searchAssignationAnswer(attribute, query, assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/searches/search_assignation_answer?attribute=${attribute}&query=${query}&assignation_id=${assignation_id}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_ASSIGNATION_ANSWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Busca el examen solicitado.
 *
 * @export searchAnswersFromBank
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function searchAnswersFromBank(attribute, query, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/searches/search_answers_from_bank?attribute=${attribute}&query=${query}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_ANSWERS_FROM_BANK,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Busca la matriz solicitado.
 *
 * @export SearchSuperMatrixStudent
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function searchSuperMatrixStudent(super_matrix_id, email, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/searches/search_super_matrix_student?&super_matrix_id=${super_matrix_id}&email=${email}`,getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_SUPER_MATRIX_STUDENT,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
