/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector , useDispatch} from 'react-redux';
import { getLabelsByMatrix, deleteLabel, getLabels } from "../../actions/labelActions";
import DataTable from "react-data-table-component";
import { PAGINATIONCOMPONENTOPTIONS } from "../../contants";
import swal from "sweetalert2";

const styles = {
    main: {
        display: 'flex',
        minHeight: '75vh',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
    },
    noMargin: {
        margin: 0
    }
}

const Labels = (props) => {

    const id = props.match.params.id || undefined
    const labels = useSelector(state => state.labels.labels)
    const dispatch = useDispatch()

    const renderHeaderStudents = [
        { selector: row => row.name, name: 'Etiqueta',  sortable: true },
        {
            selector: row => row.id,
            name: 'Acciones',
            width: "200px",
            // eslint-disable-next-line react/display-name
            cell: (row) => (
                <>
                    <button className="btn btn-info" data-toggle="tooltip" title="Eliminar de la ventana" onClick={()=> removeMember(row.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <button
                        onClick={() => props.history.push(`/labels/form/${row.id.$oid}`)}
                        className="btn btn-info">
                        Editar
                    </button>
                </>
            )
        }
    ]

    const removeMember = ({$oid:label_id}) => {
        console.log('removeMember: ', label_id	);
        swal({
            title: '¿Estás seguro?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                dispatch(deleteLabel(label_id, () => { id ? dispatch(getLabelsByMatrix(id)) : dispatch(getLabels()) }))
                swal({
                    type: 'success',
                    title: 'Integrante eliminado',
                    showConfirmButton: false,
                    timer: 1200
                });
            }
        });
    }

    useEffect(()=>{
        id ? dispatch(getLabelsByMatrix(id)) : dispatch(getLabels())
    },[])

    return (
        <main style={styles.main}>
            <section>
                <h1>Etiquetas</h1>
                {labels?.length > 0 ? (
                    <DataTable
                        paginationComponentOptions={PAGINATIONCOMPONENTOPTIONS}
                        paginationPerPage= {10}
                        paginationRowsPerPageOptions ={[10,15,20]}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="60vh"
                        data={labels}
                        columns={renderHeaderStudents}
                        customStyles={{table: {style: {zIndex: 0}}}}
                    />
                ) : (
                    <>
                        <h3>No hay etiquetas disponibles, ¡crea una!</h3>
                    </>
                )}
            </section>
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                }}
            >
                <button
                    style={{marginRight: 'auto'}}
                    onClick={() => props.history.push('/gradingMatrices')}
                    type="button"
                    className="btn btn-danger">
                    Volver
                </button>
                <button
                    onClick={() => props.history.push( id ? `/gradingMatrices/${id}/labels/form` : '/labels/form')}
                    className="btn btn-info">
                    Nueva etiqueta
                </button>
            </section>
        </main>
    )
}

export default Labels