import React, { Component } from 'react';
import { AZURE_STORAGE_CONNECTION_STRING, ROOT_URL, NO_FILE_EXTENSION, ACCEPTED_FORMATS, AZURE_STORAGE_FILE_CONTAINER } from '../../actions/constants';

/**
 * Componente para el manejo de un documento embebido.
 * Verifica que la extensión sea valida y en caso contrario muestra el documento como un enlace.
 *
 * @export EmbedFile
 * @class EmbedFile
 * @extends {Component}
 */
export default class EmbedFile extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            contentType: "",
            acceptEmbed: false,
            show: false
          };        
    }
    componentDidMount(){
        const answer = this.props.answer
        const fileUrl = this.getFileUrl(answer);
        this.isValidEmbedFiles(fileUrl);
        this.isInAzureStorage(fileUrl)
    }
    getFileUrl(answer){
        let fileUrl = answer.attachment;
        if (!fileUrl){
          fileUrl = answer.file.url || "";
        }
        let fileUrlElements = fileUrl.split(".");
        let extension = fileUrlElements[fileUrlElements.length - 1] || "";
        if (extension.includes(NO_FILE_EXTENSION)){
          fileUrl = "";
        }
        if (fileUrl!=""){
            this.setState({show:true})
        }
        return fileUrl;
    }

    isValidEmbedFiles(fileUrl){
        const format = fileUrl.split(".")[1] || "";
        const second_format = fileUrl.split(".")[2] || "";
        if (ACCEPTED_FORMATS.includes(format)|| ACCEPTED_FORMATS.includes(second_format)){
            this.setState({acceptEmbed:true})
        }
      }
    
      async getBlobFile(fileUrl) {
        const { BlobServiceClient } = require('@azure/storage-blob');
         const blobSasUrl = AZURE_STORAGE_CONNECTION_STRING
          const blobServiceClient = new BlobServiceClient(blobSasUrl);
          const containerName = AZURE_STORAGE_FILE_CONTAINER;
          const containerClient = blobServiceClient.getContainerClient(containerName);
          var blob =containerClient.getBlockBlobClient(fileUrl);
          let blobProperties = await blob.getProperties();
          this.setState({url: blob.url, contentType: blobProperties.contentType});   
      }

    isInAzureStorage(fileUrl){
        const id = fileUrl.split("-")[0] || "";
        if(id && id==this.props.answer.id){
            this.getBlobFile(fileUrl)
        }else{
            this.setState({url: `${ROOT_URL}${fileUrl}`});
        }
    }

    render() {
        const { answer } = this.props;
        
          return (
                <div className="text-center">
                    {!this.state.acceptEmbed && this.state.url && this.state.show &&
                        <p> Archivo adjunto de la respuesta disponible en
                            este <a  href={this.state.url} target="_blank" rel="noopener noreferrer"> enlace </a>
                        </p>
                    }
                    {this.state.acceptEmbed && this.state.url && this.state.show &&
                        <div>
                        <React.Fragment>
                            <label>Archivo adjunto de la respuesta: </label><br></br>
                            <embed  src={this.state.url} width="100%" height="500px" ></embed>
                            <br></br>
                        </React.Fragment>
                        <p>O lo puede descargar desde este <a  href={this.state.url} target="_blank" rel="noopener noreferrer"> enlace </a></p>
                        </div>
                    }
                </div>);
    }
}
