import _ from 'lodash';
import { GET_VIDEO, GET_VIDEOS, CREATE_VIDEO, UPDATE_PERMISSION, GET_PROCESSESVIDEOS, DELETE_VIDEO, GET_MULTIMEDIA_URL} from '../actions/videoActions';

/**
 * Manejo de la parte del estado correspondiente a usuarios.
 * Permite la actualización de usuarios, obtener un usuario por id, además de obtener los usuarios
 * por rol (estudiante, codificadores, revisores, administradores,).
 * Permite eliminar un usuario de la lista de usuarios disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los usuarios)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case CREATE_VIDEO:
      return { ...state };
    case GET_VIDEO:
      const video = action.payload.video.video;
      return { ...state, video };
    case GET_VIDEOS:
      const videos = action.payload.videos;
      const pagination = action.payload.links;
      if (action.payload.search) {
        const {search} = action.payload;
        return { ...state, videos, pagination, search };
      }
      state.search && delete state.search 
      return { ...state, videos, pagination };
    case GET_MULTIMEDIA_URL:
      const url= action.payload.url;
      return { ...state, url: url};
    case DELETE_VIDEO:  
      const videosWithoutOne = state.videos.filter((obj)=> obj.id !== action.payload);
      return {...state, videos: videosWithoutOne};
    case GET_PROCESSESVIDEOS:
      const processVideo = action.payload;
      return { ...state, processVideos: processVideo};
    case UPDATE_PERMISSION:
      const viewed = action.payload;
        // return { ...state, video: {} };
    default:
      return state;
    
  }
  
}
