import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las revisiones

// Actualización del listado de revisiones de codificación disponibles en el estado.
export const GET_ANSWER_REVIEWERS = 'GET_ANSWER_REVIEWERS';

// Obtiene una asignación de revisión dada y actualiza el estado para acceder a ella.
export const GET_ANSWER_REVIEWER = 'GET_ANSWER_REVIEWER';

// Obtiene una asignación de revisión dada y actualiza el estado para acceder a ella.
export const GET_ANSWER_REVIEWER_GRADE = 'GET_ANSWER_REVIEWER_GRADE';

/**
 * Obtener todas las revisiones disponibles (respuestas-revisores).
 *
 * @export getAnswerReviewers
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerReviewers(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_reviewers${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtener una asignación de revisión por id (respuesta-revisor).
 *
 * @export getAnswerReviewer
 * @param {*} id : El id de la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerReviewer(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_reviewers/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtener una asignación de revisión por id (respuesta-revisor).
 *
 * @export getAnswerReviewerGrade
 * @param {*} id : El id de la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerReviewerGrade(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_reviewers/${id}/grade`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWER_GRADE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtener una asignación de revisión por id (respuesta-revisor).
 *
 * @export getAnswerReviewerGrade
 * @param {*} id : El id de la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createAnswerReviewerGrade(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_reviewers/${id}/create_answer_reviewer_grade`,null, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Rechazar la codificación de una asignación realizada.
 * con el id de asignación de revisión dado.
 *
 * @export rejectAnswerGraders
 * @param {*} id : El id de la asignación de revisión.
 * @param {*} params : Información de ala codificación rechazada.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function rejectAnswerGraders(id, params, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_reviewers/${id}/reject_grades`, params, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Acepta la codificación de una asignación realizada
 * con el id de asignación de revisión dado.
 *
 * @export acceptAnswerGraders
 * @param {*} id : El id de la asignación de revisión.
 * @param {*} params : Información de ala codificación rechazada.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function acceptAnswerGraders(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_reviewers/${id}/accept_grades`, null, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_REVIEWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
