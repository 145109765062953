import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
/**
 * Componente de paginación. Recibe por props las opciones de paginación (last, next, prev, first)
 * y una función a la cual pasar las opciones.
 *
 * @export
 * @class IconTooltip
 * @extends {Component}
 */
export default class IconTooltip extends Component{
    constructor(props){
        super(props);
    }
   
    render(){
        const {icon, text, size} = this.props;
        return(
            <i data-tip={text} aria-hidden="true" className={`fa ${size? size :"fa-lg"} ${icon}`}>
                <ReactTooltip />
            </i>
        );
    }
}
