import React, {useState} from 'react';

const Searcher = (props) => {
  const { columns, action, apiUrl, extraParams = {} } = props
  const [form, setForm] = useState({
    value: '',
    option: ''
  });

  const handleChange = (e)=> {
    const {name, value} = e.target
    setForm((prevState) => {
      return ({
        ...prevState,
        [name]: value,
      })
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    action({ 
      ...extraParams,
      pagination: apiUrl, 
      search: {
        query: form.option,
        value: form.value,
      }
    }, ()=>{}, (error) => {console.log(error)})
  }

  const cleanValues = () => {
    setForm({
      value: '',
      option: ''  
    })
    action({...extraParams, pagination: apiUrl})
  }

   return (<>
    <h4>Buscar</h4>
    <div>
      <div className="row" >
        <form 
          onSubmit={handleSubmit}
        >
          <div className='col-sm-6 col-md-6'>
            <input 
              value={form.value} 
              className="form-control" 
              name="value" 
              type={columns.filter(obj => obj.value == form.option)[0]?.type || 'text'}
              onChange={handleChange}
              required
            />
          </div>
          <div className='col-sm-3 col-md-3'>
            <select 
              value={form.option} 
              className="form-control" 
              name="option" 
              onChange={handleChange}
              required
            >
              <option></option>
              {columns.map(obj => {
                return (<option key={obj.value} value={obj.value}>{obj.label}</option>)
              })}
            </select>
          </div>
          <button className='btn btn-primary pull-left'>Buscar </button>
        </form>
        <button className='btn btn-primary pull-left' onClick={cleanValues}>Limpiar </button>
      </div>
    </div>  
   </>)
}

export default Searcher