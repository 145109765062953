import React, { Component } from 'react';
import Loading from '../Loading';
import IconTooltip from './IconTooltip'

/**
 * Componente de paginación. Recibe por props las opciones de paginación (last, next, prev, first)
 * y una función a la cual pasar las opciones.
 *
 * @export
 * @class Pagination
 * @extends {Component}
 */
export default class Pagination extends Component{
    constructor(props){
        super(props);
        this.state = {
            updating: false
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({updating: false});
    }

    onPaginationClick(option){
        this.setState({
            updating: true
        });
        this.props.onPagination(option);
    }

    render(){
        const {paginationOptions} = this.props;
        if(this.state.updating){
            return <div align="center">
                    <Loading />
                   </div>;
        } else {
            return(
                <div align="left">
                    <ul className="pagination">
                    {paginationOptions.next &&
                    <li className="page-item">
                        <button className="btn btn-default color-blue" onClick={()=>{this.onPaginationClick(paginationOptions.next)}}>
                        <IconTooltip text="Reporte anterior" icon="fa-arrow-left" size="fa-lg"/>
                        </button>
                    </li>}
                    <li className="btn " >{this.props.description?this.props.description:""}</li>
                    {paginationOptions.prev &&
                    <li className="page-item">
                        <button className="btn btn-default color-blue" onClick={()=>{this.onPaginationClick(paginationOptions.prev)}}>
                        <IconTooltip text="Siguiente reporte" icon="fa-arrow-right" size="fa-lg"/>
                        </button>
                    </li>}
                    </ul>
              </div>
            );
        }
    }
}