import React from 'react';
import {ROOT_URL} from '../../actions/constants';

/**
 * Función para renderizar los adjuntos asociados a una pregunta.
 *
 * @export TestTextsList
 * @param {*} props : Los adjuntos de la pregunta (texts).
 * @returns El componente para mostrar la lista de adjuntos.
 */
export default function TestTextsList(props) {
  return (
    <div>
      <h4><strong>Contenidos adjuntos:</strong></h4>
      <ul>
        {props.texts.map((text) => {
          const textUrl = text.link ? text.link : `${ROOT_URL}${text.file.url}`;

          return (
            <li key={text.id}>
              <a href={textUrl} target="_blank" rel="noopener noreferrer">{text.title}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
