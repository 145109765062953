//import { SET_TENANT } from '../actions/tenantActions';

/**
 * Manejo de la parte del estado correspondiente a los clientes(tenants).
 * Setea el tenant actual según la url.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al tenant)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
        case 'SET_TENANT':
            const tenant = action.payload;
            return {...state, tenant}
        default:
            return state;
    }
}