import React from 'react';
import swal from 'sweetalert2';
// import { deleteUser } from '../../actions/usersActions';
import moment from 'moment/moment';
import IconTooltip from '../utils/IconTooltip';
import { Link } from 'react-router-dom';


/**
 * Componente para mostrar la información de un usuario.
 * Maneja la eliminación del usuario.
 *
 * @function Process
 */
const Process = (props) => {

  const onClickDelete = (id) => {
    swal({
      title: '¿Estás seguro?',
      text: 'El proceso de observacion será eliminado del sistema',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
    });
  }
  
  const {process, currentUser, isObserver = false } = props;
  const fechaMax = moment(process.deadline).format('DD/MM/YYYY HH:mm')


  const compareDates = (deadline) => {
    const deadlineFormated = moment(deadline).format('YYYY-MM-DD HH:mm')
    const today= moment().format('YYYY-MM-DD HH:mm')
    if (deadlineFormated < today){
      return true
    } else {
      return false
    }
  }


  return (
    <tr>
      <td style={{textAlign: 'center'}}>{process.id} </td>
      { (currentUser.isObserverAdmin && !isObserver) ? (
        <>
        <td>
          <Link to={`/${isObserver ? 'observers/': ''}processes/view/${process.id}`} >
            {process.title}
          </Link>
        </td>
        <td style={{textAlign: 'center'}}>{fechaMax}</td>
        <td>
          <span style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={`/processes/edit/${process.id}`} className="btn btn-primary">
              <IconTooltip text="Editar" icon="fa fa-pencil-square-o"/>
            </Link>
            <Link to={`/processes/permissions/${process.id}`} className="btn btn-primary">
              <IconTooltip text="Gestionar permisos" icon="fa-tag"/>
            </Link>
          </span>
        </td>
      </>):<>
        { compareDates(process.deadline)? (
          <td>{process.title}</td>
          ):
          <td>
            <Link to={`/${isObserver ? 'observers/': ''}processes/view/${process.id}`} >
              {process.title}
            </Link>
          </td>
        }
        <td style={{textAlign: 'center'}}>{process.deadline ? moment(process.deadline).format('DD/MM/YYYY HH:mm'): '--/--/----'}</td>
      </>
      }
    </tr>
  );
}

export default Process
