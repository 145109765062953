/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useSelector , useDispatch} from 'react-redux';
import { deleteApwindow, getApwindows } from "../../../actions/apwindowsActions";
import { getApwindowUsers, removeApwindowUser } from "../../../actions/apwindowUsersActions";
import { getExam } from "../../../actions/examsActions";
import Loading from "../../Loading";
import swal from "sweetalert2";
import { PAGINATIONCOMPONENTOPTIONS
 } from "../../../contants";


const styles = {
  main: {
    display: 'flex', 
    minHeight: '75vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
  },
  noMargin: {
    margin: 0
  }
}

// componente
const Apwindows= (props) => {
  //constantes
  const id = props.match.params.id;
  const apwindows= useSelector(state => state.apwindows.apwindows);
  const exam = useSelector(state => state?.exams);
  const members = useSelector(state => state?.apwindowUsers?.fromApwindow);
  const renderHeaderStudents = [
    { selector: row => row.id, name: 'ID', width: "80px", sortable: true},
    { selector: row => row.email, name: 'Email',  sortable: true},
    { 
      selector: row => row.email,
      name: 'Acciones',
      width: "80px",
      cell: (row) => (
        <button className="btn btn-info" data-toggle="tooltip" title="Eliminar de la ventana" onClick={()=> removeMember(row.id)}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      )
    }
  ]

  // hooks
  const [loading, setLoading] = useState(false)
  const [currentApwindow, setCurrentApwindow] = useState(null)
  const [currentStudent, setCurrentStudent] = useState(null)
  const [selectData, setSelectData] = useState({
    value: null,
    options: [] 
  })

  const dispatch = useDispatch()

  // eventos
  const changeWindow = (value) => {

    setSelectData({ ...selectData, value,})
    setCurrentApwindow( value ? apwindows.find(ventana => ventana.id === value.value) : value)
  }

  const removeMember = (member) => {
    swal({
      title: '¿Estás seguro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        dispatch(removeApwindowUser(member, () => { dispatch(getApwindowUsers(currentApwindow.id)) }))
        swal({
          type: 'success',
          title: 'Integrante eliminado',
          showConfirmButton: false,
          timer: 1200
        });
      }
    });
  }

  const removeApwindow = () => {
    swal({
      title: '¿Estás seguro?',
      html: 'Se eliminara la ventana.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        dispatch(
          deleteApwindow(currentApwindow.id, () => { 
            setLoading(true)
            dispatch(getApwindows(id, ()=> setLoading(false), ()=> setLoading(false)))
            setCurrentApwindow(null)
          })
        )
        swal({
          type: 'success',
          title: 'Ventana borrada',
          showConfirmButton: false,
          timer: 1200
        });
      }
    });
  }

  //efectos
  useEffect(() => {
    setLoading(true)
    dispatch(getExam(id,()=>{}, ()=> {}))
    dispatch(getApwindows(id, ()=> setLoading(false), ()=> setLoading(false)))
  },[])

  useEffect(() => {
    if (apwindows) {
      setSelectData({...selectData, options: apwindows.map((ventana, i) => ({label: ventana.title, value: ventana.id}))})
    }
  },[apwindows])

  useEffect(()=>{
    if (currentApwindow) {
      dispatch(getApwindowUsers(currentApwindow.id))
    } else {
      setCurrentStudent(undefined)
    }
  },[currentApwindow])

  useEffect(()=>{
    if (members) {
      setCurrentStudent(members.members)
    } else {
      setCurrentStudent(undefined)
    }
  },[members])

  // render
  return (<main style={styles.main}>
    <section>
      <h1>Ventanas de aplicación</h1>
      <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'end', justifyContent: 'space-between'}}>
        <div>
          <p style={styles.noMargin}>del examen:</p>
          <h2 style={styles.noMargin}>{exam[id] && exam[id].title}</h2>
        </div>
        <div>
          <p style={styles.noMargin}>
            <b>Comienza:</b> {exam[id] && moment(exam[id].initialDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
          </p>
          <p style={styles.noMargin}>            
            <b>Finaliza:</b> {exam[id] && moment(exam[id].finalDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
          </p>

        </div>
      </div>
      <br />
      {loading ? <Loading /> : (<>

        {apwindows?.length > 0 ? (
          <>
            <header>
              <label>Ventana:</label>
              <Select
                onChange = {val => changeWindow(val)}
                value={selectData.value}
                multi={false}
                options={selectData.options}
                isClearable
                placeholder='selecciona una ventana...'
              />
            </header>

            <div>
              {currentApwindow === null ? (<>
                <h3>Selecciona una ventana...</h3>
              </>):(<>
                <div >
                  <h2>{currentApwindow?.title}</h2>
                  <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between'}}>
                    <p>
                      <b>Comienza:</b> {moment(currentApwindow?.startDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
                      <br/>
                      <b>Finaliza:</b> {moment(currentApwindow?.deadline).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
                    </p>
                    <div>
                      <button
                        onClick={() => props.history.push(`/exams/${id}/apwindows/${currentApwindow?.id}/students`)}
                        className="btn btn-info" title="agregar estudiante">
                        Agregar estudiante
                      </button>
                      <button
                        onClick={() => props.history.push(`/exams/${id}/apwindows/edit/${currentApwindow?.id}`)}
                        className="btn btn-info" title="editar ventana">
                        Editar ventana
                      </button>
                      <button
                        onClick={removeApwindow}
                        className="btn btn-info" title="Eliminar ventana">
                        Eliminar ventana
                      </button>
                    </div>
                  </div>
                </div>
                <DataTable
                  paginationComponentOptions={PAGINATIONCOMPONENTOPTIONS}
                  paginationPerPage= {5}
                  paginationRowsPerPageOptions ={[5,10,15,20]}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="35vh"
                  data={currentStudent}
                  columns={renderHeaderStudents}
                  customStyles={{table: {style: {zIndex: 0}}}}
                />
              </>)}
            </div>
          </>
        ) : (
          <>
            <h3>no hay ventanas disponibles, ¡crea una!</h3>
          </> 
        )}
      </>)}

    </section>

    <section 
      style={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
      }}
    >
      <button
        style={{marginRight: 'auto'}}
        onClick={() => props.history.push('/exams')}
        type="button"
        className="btn btn-danger">
        Volver
      </button>
      <button
        onClick={() => props.history.push(`/exams/${id}/apwindows/attachment`)}
        className="btn btn-info">
        crear por archivo
      </button>
      <button
        onClick={() => props.history.push(`/exams/${id}/apwindows/new`)}
        className="btn btn-info">
        Nueva ventana de aplicación
      </button>
    </section>
  </main>)
}

export default Apwindows