import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_EXAM_QUESTIONARIES = 'GET_EXAM_QUESTIONARIES';
// Actualiza un cuestionario
export const GET_EXAM_QUESTIONARY = 'GET_EXAM_QUESTIONARY';


/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getExamQuestionaries
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamQuestionaries(exam_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_mc_questionaries/${pagination}&exam_id=${exam_id}`, getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_QUESTIONARIES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getExamQuestionary
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamQuestionary(exam_mc_questionary_id,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_mc_questionaries/${exam_mc_questionary_id}`, getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_QUESTIONARY,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}