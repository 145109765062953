import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las facultades.

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_FACULTIES = 'GET_FACULTIES';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_FACULTY = 'GET_FACULTY';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_FACULTY_EXAMS = 'GET_FACULTY_EXAMS';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_FACULTY_SEMESTERS = 'GET_FACULTY_SEMESTERS';

/**
 * Obtiene las preguntas disponible.
 *
 * @export getFaculties
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getFaculties(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/faculties${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_FACULTIES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  /**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getFacultySemesters
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getFacultySemesters(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/faculties/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_FACULTY_SEMESTERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getFacultyExams
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getFacultyExams(id, semester, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/faculties/${id}/semester_exams?semester=${semester}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_FACULTY_EXAMS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

