import _ from 'lodash';
import { GET_TEXTS, GET_TEXT, DELETE_TEXT } from '../actions/textsActions';

/**
 * Manejo de la parte del estado correspondiente a adjuntos.
 * Permite la actualización de adjuntos, obtener un adjunto por id.
 * Permite eliminar un adjunto de la lista de adjuntos disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los adjuntos)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_TEXTS:
      const texts = _.mapKeys(action.payload.texts, 'id');
      const pagination = action.payload.links;
      return {...state, texts: texts, pagination: pagination };
    case GET_TEXT:
      const text = action.payload;
      return { ...state, [text.id]: text };
    case DELETE_TEXT:
      return {...state, texts:_.omit(state.texts, action.payload)};
    default:
      return state;
  }
}
