import React, {useEffect, useState} from "react";
import { getTestQuantities } from "../../../actions/examTestsActions";
import { connect } from 'react-redux';

const TestQuantities = ({exam_test_option_id, getTestQuantities}) => {
  const [data, setData] = useState(undefined);
  useEffect(()=>{
    getTestQuantities(exam_test_option_id,(data)=> { setData(data) })
  },[])
  return (<>
    {data && (
      <div  className=" col-xs-12">
        <h3>Estado de las respuestas</h3>
        <table className="table table-responsive  table-striped">
          <thead>
            <tr>
              <th>Estado</th>
              <th>Numero de respuestas</th>
            </tr>
          </thead>
          <tbody>
              {data.quantities.map((quantity) => {
                  return (                   
                    <tr key= {quantity.status} > 
                      <td>{quantity.status}</td>
                      <td>{quantity.count}</td>
                    </tr>
                  )
              })}
              <tr> 
                <td>Total</td>
                <td>{data.total}</td>
              </tr>
          </tbody>
        </table>
      </div>
    )} 
  </>)
}

export default connect(()=> {}, { getTestQuantities })(TestQuantities);