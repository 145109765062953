import _ from 'lodash';
import { GET_GRADING_MATRICES, GET_GRADING_MATRIX, DELETE_GRADING_MATRIX,GET_GRADING_MATRIX_LEVELS } from '../actions/gradingMatricesActions';

/**
 * Manejo de la parte del estado correspondiente a matrices de codificación.
 * Permite la actualización de matrices de codificación, obtener una matriz de codificación por id.
 * Permite eliminar una matriz de codificación de la lista de matrices de codificación disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las matrices)
 * @returns El estado con la modificación/acción realizada.
 */

const initialState = {
  gradingMatrices: [],
  gradingMatrix: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GRADING_MATRICES:
      const gradingMatrices = _.mapKeys(action.payload.gradingMatrices, 'id');
      const pagination = action.payload.links;
      return {...state, gradingMatrices: gradingMatrices, pagination: pagination};
    case GET_GRADING_MATRIX:
      const gradingMatrix = action.payload;
      return { ...state, [gradingMatrix.id]: gradingMatrix };
    case GET_GRADING_MATRIX_LEVELS:
      const matrixLevels = action.payload;
      return { ...state, matrixLevels: matrixLevels };
    case DELETE_GRADING_MATRIX:
      return {...state, gradingMatrices: _.omit(state.gradingMatrices, action.payload)};
    default:
      return state;
  }
}
