import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las respuestas de opción multiple.

// Actualiza las respuestas de opción multiple pendientes del estado.
export const GET_PENDING_MC_ANSWERS =  'GET_PENDING_MC_ANSWERS';

// Obtiene la respuesta actual para el estado.
export const GET_MC_ANSWER = 'GET_MC_ANSWER';

// Remueve la respuesta actual del estado.
export const REMOVE_CURRENT_MC_ANSWER = 'REMOVE_CURRENT_MC_ANSWER';

// Actualiza las respuestas cerradas del estado.
export const GET_CLOSED_MC_ANSWERS = 'GET_CLOSED_MC_ANSWERS';



/**
 * Obtiene todas las respuestas en estado pendiente.
 *
 * @export getPendingMcAnswers
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getPendingMcAnswers(pagination) {
  const request = axios.get(`${ROOT_URL}/mc_answers/pending${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PENDING_MC_ANSWERS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching pending mc_answers', error);
      }
    });
  }
}

/**
 * Obtener la respuesta con el id dado.
 *
 * @export getMcAnswer
 * @param {*} id : El id de la respuesta
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getMcAnswer(id) {
  const request = axios.get(`${ROOT_URL}/mc_answers/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the mc_answer', error);
      }
    });
  }
}

/**
 * Dispatch de la acción para remover del state la información de la respuesta actual.
 *
 * @export removeCurrentMcAnswer
 * @returns Dispatch para remover la respuesta actual en el estado.
 */
export function removeCurrentMcAnswer() {
  return {
    type: REMOVE_CURRENT_MC_ANSWER
  };
}

/**
 * Inicializar la respuesta con el id dado en el estado iniciada.
 *
 * @export startMcAnswer
 * @param {*} id : El id de la respuesta.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function startMcAnswer(id) {
  const request = axios.patch(
    `${ROOT_URL}/mc_answers/${id}/start`,
    null,
    getAuthHeaders()
  );

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error starting the mc_answer', error);
      }
    });
  }
}

/**
 * Actualizar la respuesta dada por parámetro.
 *
 * @export updateMcAnswer
 * @param {*} answer: El objeto respuesta con información actualizada, debe tener el atributo 'id'.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateMcAnswer(mc_answer, structure) {
  let body = structure;

  if(structure === undefined) {
    body = {mc_answer: mc_answer};
  }

  const request = axios.patch(
    `${ROOT_URL}/mc_answers/${mc_answer.id}`,
    body,
    getAuthHeaders()
  );

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error updating the mc_answer', error);
      }
    });
  }
}

/**
 * Finalizar la respuesta con el id dado.
 *
 * @export finishMcAnswer
 * @param {*} mc_answer : Información de la respuesta a finalizar.
  * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function finishMcAnswer(mc_answer, structure, successCallback = () => {}, errorCallback = (error) => {}) {
  
  const request = axios.patch(`${ROOT_URL}/mc_answers/${mc_answer.id}/finish`, {structure: structure}, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWER,
        payload: data
      });

      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}



/**
 * guardado parcial de la respuesta con el id dado.
 *
 * @export partialSavedMcAnswer
 * @param {*} mc_answer : Información de la respuesta a finalizar.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function partialSavedMcAnswer(mc_answer, structure) {
  
  const request = axios.patch(`${ROOT_URL}/mc_answers/${mc_answer.id}/partial`, {structure: structure}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
         console.log(error);
      }
    });
  }
}

/**
 * Obtiene todas las respuesta en estado cerrado.
 *
 * @export getClosedMcAnswers
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getClosedMcAnswers(pagination) {
  const request = axios.get(`${ROOT_URL}/mc_answers/closed${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_CLOSED_MC_ANSWERS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching closed mc_answers', error);
      }
    });
  }
}
