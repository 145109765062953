import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Loading from '../Loading';
import ReportPagination from '../utils/ReportPagination';
import IconTooltip from '../utils/IconTooltip'


/**
 * Componente para mostrar la lista de matrices de codificación disponibles.
 * Igualmente permite crear matrices, borrarlas y acceder al detalle de las mismas.
 *
 * @class ReportList
 * @extends {Component}
 */
class ReportList extends Component {

  componentDidMount() {
    this.scrollingText();
    
  }
  addTooltip(text, value){
    return(<>{value +" "}<IconTooltip text={text} icon="fa-info-circle" size="fa-sm"/></>)
  }


  scrollingText(){
    var style = document.createElement('style');
    style.innerHTML = `
    .scrolling-text {
      padding : 4px;
      width : auto;
      height : 100px;
      overflow : auto;
    }`
    document.head.appendChild(style);
    }
  renderReportList() {  
      return _.map(this.props.reports.reportList, (report, index) => {
        return (
          <React.Fragment key={index}>
            {this.props.pagination &&
              <ReportPagination description={report.description} paginationOptions={this.props.pagination} onPagination={this.props.onPaginateReport}/>}
          </React.Fragment>
        );
      });
  }
  render() {
    const {reports , description, reportType} = this.props;
    if (!reports){
      return(
        <Loading />
      )
    }else{
    return (
      <div>
        <h3> Reporte de {this.addTooltip(description, reportType)}</h3>
        <p> A continuación se muestran los reportes generados a la fecha, si desea generar otro 
            reporte de click en generar. Este proceso puede tardar, vuelva a cargar (<i className="fa fa-refresh fa-md" aria-hidden="true"></i>)
             esta ventana despues de unos minutos para ver el nuevo reporte.
        </p>
        <div className="row">
            <div className="col-md-8 col-xs-8">
              {reports.reportList.length !== 0? this.renderReportList(): <tr><td>No hay reportes</td></tr>}
            </div>
          <div className="btn-group  pull-right">
            <button
                    onClick={e=> this.props.onReloadReport(e)}
                    type="button"
                    className="btn btn-default "> <IconTooltip text="Recargar reportes" icon="fa-refresh" size="fa-lg"/>
              </button>
              <button
                    onClick={e => this.props.onGenerateReportSubmit(e)}
                    type="button"
                    className="btn btn-primary">
                      <IconTooltip text="Generar un reporte nuevo" icon="fa-plus" size="fa-lg"/>
              </button>
            </div>

        </div>
      </div>
    )
  }}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ReportList);
