import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de los departamentos.

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_DEPARTMENT = 'GET_DEPARTMENT';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_DEPARTMENT_EXAMS = 'GET_DEPARTMENT_EXAMS';

// Actualiza los examenes dada del estado de la aplicación.
export const GET_DEPARTMENT_SEMESTERS = 'GET_DEPARTMENT_SEMESTERS';


/**
 * Obtiene las preguntas disponible.
 *
 * @export getDepartments
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDepartments(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/departments${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_DEPARTMENTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getDepartmentExams
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDepartmentExams(id,semester, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/departments/${id}/semester_exams?semester=${semester}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_DEPARTMENT_EXAMS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getDepartmentSemesters
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDepartmentSemesters(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/departments/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_DEPARTMENT_SEMESTERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

