import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import 'moment/locale/es';
import 'react-quill/dist/quill.snow.css';
import swal from 'sweetalert2';
import { ROOT_URL, getAuthHeaders, AZURE_STORAGE_CONNECTION_STRING, ACCEPTED_FORMATS, AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER } from '../../actions/constants'
import ReactQuill from 'react-quill';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import axios from 'axios';
import EmbedFile from './EmbedFile' ;
// import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";


const EditProcess = ({match,history}) => {
  const user = useSelector(state => state.currentUser)

  const processId = match.params.id;
  const [form, setForm] = useState({
    deadline: '',
    description: '',
    instructions_file: '',
    instructions_text: '',
    title: '',
    updated_at: '',
  });
  const [currentForm, setCurrentForm] = useState({})
  const [currentSelectsInfo, setCurrentSelectsInfo] = useState({})
  const [currentFile, setCurrentFile] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [selectsInfo, setSelectsInfo] = useState({
    observers: {selected: [], options: [], unavailable: []},
    observerAdmins: {selected: [], options: []}
  });

  const file = React.useRef(null);

  useEffect(()=>{
    getProcess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getProcess = async () => {
    try {
      const processRequest = await axios.get(`${ROOT_URL}/observation_processes/${processId}`, getAuthHeaders());
     
      const info = {
        observers: {selected: [], options: [], unavailable: []},
        observerAdmins: {selected: [], options: []}
      }
      if (processRequest.status === 200) {
        const data = processRequest.data.observationProcess.observationProcess;
        const observerAdminsRequest =  await axios.get(`${ROOT_URL}/observer_admins/byProcess?id=${processId}`, getAuthHeaders());
 
        if (observerAdminsRequest.status === 200 && observerAdminsRequest.data.length > 0) {
          info.observerAdmins.selected = observerAdminsRequest.data.map(obj => ({label: obj.email, value: obj.email}));
        }

        const observersRequest =  await axios.get(`${ROOT_URL}/observers/byProcess?id=${processId}`, getAuthHeaders());
       
        if (observersRequest.status === 200 && observersRequest.data.length > 0) {
          info.observers.selected = observersRequest.data.map(obj => ({label: obj.email, value: obj.email}));
          info.observers.unavailable = observersRequest.data.filter(obj => ! obj.available).map(obj => ({label: obj.email, value: obj.email}))
        }

        const observersOptionsRequest = await axios.get(`${ROOT_URL}/observers/getUsers`, getAuthHeaders());
        if (observersOptionsRequest.status === 200 && observersOptionsRequest.data.length > 0) {
          info.observers.options = observersOptionsRequest.data.map(obj => ({label: obj.email, value: obj.email}));
        }
        const observerAdminsOptionsRequest = await axios.get(`${ROOT_URL}/observer_admins/getUsers`, getAuthHeaders());
        if (observerAdminsOptionsRequest.status === 200 && observerAdminsOptionsRequest.data.length > 0) {
          info.observerAdmins.options = observerAdminsOptionsRequest.data.map(obj => ({label: obj.email, value: obj.email}));
        }

        setForm({
          ...form,
          deadline: data.deadline,
          description: data.description ,
          instructions_file: data.instructions_file,
          instructions_text: data.instructions_text,
          title: data.title,
          updated_at: data.updatedAt,      
        });

        setSelectsInfo(info);
       
        setCurrentForm({
          ...form,
          deadline: data.deadline,
          description: data.description ,
          instructions_file: data.instructions_file,
          instructions_text: data.instructions_text,
          title: data.title,
          updated_at: data.updatedAt,    
        })
        setCurrentSelectsInfo(info)
        setCurrentFile(data.instructions_file)
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);
      history.push('/');
      swal({
        type: 'error',
        title: error.response.data.error,
        showConfirmButton: false,
        timer: 2600
      })
      // throw new error(error.message)
    }
  }
    

  let box_style = {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  }

  const modules = {
    toolbar: [
      [{'size': ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'align': []}],
      ['clean']
    ]
  };
  
  const handleChange = (e) => {
    loaded && (e.target ? setForm({ ...form, [e.target.name]: e.target.value}): setForm({ ...form, ...e}));
    (loaded && disabled) && setDisabled(false);
  }

  const uploadAzureFiles = async (file, filename, containerName) => {
    try {
      const { BlobServiceClient } = require('@azure/storage-blob');
      const blobSasUrl = AZURE_STORAGE_CONNECTION_STRING
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient(containerName);

      const promises = [];
      const blockBlobClient = containerClient.getBlockBlobClient(filename);
      
      promises.push(blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024, 
        concurrency: 20, 
        blobHTTPHeaders :{blobContentType:file.type}
      }));
      swal({
        title: "Cargando",
        showConfirmButton: false,
        onBeforeOpen: () => {
          swal.showLoading()
        },
      });
      await Promise.all(promises);
      swal.close();
      return swal({
        type: 'success',
        title: 'Se subieron los archivos exitosamente',
        showConfirmButton: false,
        timer: 1600
      }).then(()=> true);
    } catch (error) {
      return swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error, intente nuevamente',
      } ).then(()=> (false));
    }
  }

  const onInstructionsAttach = async () => {
    const fileInput = file.current;
    const fileContainer = AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER;

    return swal({
      title: '¿Está seguro?',
      html: `Se cargará el archivo: ${form.instructions_file} y se borrara ${currentFile}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, enviar',
      cancelButtonText: 'Cancelar'
    }).then(({value}) => {
      if (value) {
        
        let fileUrlElements = form.instructions_file.split(".");
       
        const format = fileUrlElements[fileUrlElements.length - 1] || "";
       
        if (ACCEPTED_FORMATS.includes(format)){
            const filename = `process ${form.title} - ${form.instructions_file}`;
          
            return uploadAzureFiles(fileInput.files[0], filename, fileContainer)
        } else { 
          return swal(
            'Ups...',
            'Ocurrió un error el tipo de archivo no es soportado',
            'error'
          ).then(()=> {
            return false;
          });
        }          
      } else {
        swal.close();
        return swal({
          title: '¿desea aun asi guardar los demas cambios?',
          showCancelButton: true,
          confirmButtonText: 'Si, guardar',
          cancelButtonColor: '#d33',
          cancelButtonText: `No, cancelar`,
        }).then(({value}) => {
          if (value) {
            return true;
          } else {
            return false;
          }
        })
      }

      
    });
  }

  const changeSelect = (newValue, state = '') => {
   
    const prevState = selectsInfo;
    if (state === 'observers') {
      for (let obj of prevState.observers.unavailable){
        if (!newValue.some(observer => observer.value === obj.value)) {
          newValue.push(obj)
        }
      }
      setSelectsInfo({
        ...selectsInfo,
        observers: {
          ...selectsInfo.observers,
          selected: newValue,
        }
      })
      prevState.observers.selected !== newValue && ((loaded && disabled) && setDisabled(false));
    }

    if (state === 'observerAdmins') {
      
      let index = newValue.findIndex((obj)=>(obj.value === user.email && true));
      let finalValue = index < 0 ? [...newValue, {label: user.email, value: user.email}] : newValue
      setSelectsInfo({
        ...selectsInfo,
        observerAdmins: {
          ...selectsInfo.observerAdmins,
          selected: finalValue,
        }
      })

      !_.isEqual(prevState.observerAdmins.selected,finalValue) && ((loaded && disabled) && setDisabled(false));
    }
  }

  const handleSubmit = async (e) => {
 
    try {
      e.preventDefault();
      const saveFile = (form.instructions_file && (form.instructions_file !== currentFile)) ? await onInstructionsAttach() : true;

      if (saveFile) {
        if (!_.isEqual(form,currentForm)) {

          const request = await axios.put(`${ROOT_URL}/observation_processes/${processId}`, {
            form: {
              ...form,
              instructions_file: (form.instructions_file !== currentFile ? `process ${form.title} - ${form.instructions_file}`: form.instructions_file),
              updated_at: moment().format('YYYY-MM-DD HH:mm')
            }
          },
          getAuthHeaders());

          if (request.status === 201) {

            request.status = 201 && swal({
              type: 'success',
              title: 'proceso de observación registrado',
              showConfirmButton: false,
              timer: 1600
            });
          }
        }
        
        swal.close();
        
        const records = {}; 
        


        if (!_.isEqual(currentSelectsInfo.observers, selectsInfo.observers)) {
          const emails = selectsInfo.observers.selected.map(obj => (obj.value));
          const updateObservers = await axios.put(`${ROOT_URL}/observers/massive`, {
            id: processId,
            emails: emails
          }, getAuthHeaders())

          if (updateObservers.status === 200)
            records.observers = updateObservers.data

        }

        if (!_.isEqual(currentSelectsInfo.observerAdmins, selectsInfo.observerAdmins)) {
          const emails = selectsInfo.observerAdmins.selected.map(obj => (obj.value));
          const updateOAdmins = await axios.put(`${ROOT_URL}/observer_admins/massive`, {
            id: processId,
            emails: emails
          }, getAuthHeaders())

          if (updateOAdmins.status === 200)
            records.observerAdmins = updateOAdmins.data

        }

        swal({
          type: 'success',
          title: 'asociaciones terminadas',
          html:`
          <hr/>
          <div style="display: flex; flex-flow: column nowrap; align-items: center;">
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Observadores registrados:</h4><h4> ${records?.observers?.created ?? 0}</h4>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Observadores excluidos: </h4><h4>${records?.observers?.deleted ?? 0 }</h4>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Gestores registrados: </h4><h4>${records?.observerAdmins?.created ?? 0}</h4>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Gestores excluidos: </h4><h4>${records?.observerAdmins?.deleted ?? 0}</h4>
            </div>
          </div>
          `,
          showConfirmButton: false,
          timer: 3000
        }).then(()=>history.push('/processes'));

      }
    } catch (error) {
      console.log(error)
      swal({
        type: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error, intente nuevamente',
      } )
      // reportStatusInstructions(error.message);
    }
  }

  return (
    <>
    <h2>Editar proceso de observación #{processId}</h2>
    <h1>{form.title}</h1>
    <br />
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div style={box_style} className="row">
          <div className="col-md-8 col-xs-8">
            <label>Nombre del proceso* </label>
            <input
            type="text"
            name="title"
            id="processName"
            required
            value={form.title}
            className='form-control'
            onChange={handleChange}
            />
          </div>
          <div className="col-md-4 col-xs-4">
            <label>fecha cierre* </label>
            <Datetime
            inputProps={{
                required: true
            }}
            locale='es'
            value={moment(form.deadline)}
            onChange={(value) => handleChange({'deadline': moment(value).format('YYYY-MM-DD HH:mm')})}
            />
          </div>
        </div>
      </div>
      <div style={box_style} className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group">
            <label>Descripcion</label>
            <br />
            <div autoComplete="off" spellCheck="false">
            <ReactQuill 
                style= {{height: '127px'}}
                className="quillFlex"
                value={form.description}
                onChange={(value) => handleChange({'description': value})}
                modules={modules}/>
            </div>
          </div>
        </div>
      </div>
      <div style={box_style} className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group">
            <label>Instrucciones </label>
            <p>Puede escribir las instrucciones en el recuadro, adjuntar un archivo pdf con ellas o reemplazar el existente subiendo otro</p>
            <div id="quill-container" style= {{height: "127px"}} autoComplete="off" spellCheck="false">
              <ReactQuill
                style= {{height: "187px"}}
                className="quillFlex"
                onChange={(value) => handleChange({'instructions_text': value})}
                value={form.instructions_text}
                modules={modules}/>
            </div>
            <br/>
            <div>
              <p>archivo guardado: {currentFile || 'ninguno'}</p>
              <EmbedFile url={currentFile} preview={true} messageDownload='descargue el archivo a través de este '/>
              <input 
                type="file"
                name="instructions_file"
                id="instructions-input"
                ref={file}
                onChange={(e) => handleChange({[e.target.name]: ((e.target?.files[0].name) || '')})} />
            </div>
          </div>
        </div>
      </div>
      <div style={box_style} className="row">
        <div className="col-md-6 col-xs-6">
          <label>Gestores asociados</label>
          <MultiSelect
            onChange = {val => changeSelect(val, 'observerAdmins')}
            value={selectsInfo.observerAdmins.selected}
            multi={true}
            options={selectsInfo.observerAdmins.options}
          />
        </div>
        <div className="col-md-6 col-xs-6">
          <label>Observadores asociados</label>
          <MultiSelect
            onChange = {val => changeSelect(val, 'observers')}
            value={selectsInfo.observers.selected}
            multi={true}
            options={selectsInfo.observers.options}
          />
        </div>
      </div>
      <div>
        <button
          onClick={() => history.push('/processes')}
          type="button"
          className="btn btn-danger">
          Cancelar
        </button>
        <button
          type="submit"
          className="btn btn-primary pull-right"
          disabled={disabled}>
          Guardar
        </button>
      </div>
    </form>
    <br />
    <br />
    <br />
  </>
  )
}

export default EditProcess