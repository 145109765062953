import React from 'react';


/**
 * Función para el renderizado de una señal de carga.
 *
 * @export
 * @returns Un spinner de carga
 */
export default function () {
  return (
    <div className="col-md-12 text-center">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw"/>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
