import axios from 'axios';
import swal from 'sweetalert2';
import { getAuthHeaders, ROOT_URL } from './constants';

// Constantes para el manejo de inicio de sesión.

// Actualiza los datos del usuario del estado de la aplicación.
export const LOG_IN_USER = 'LOG_IN_USER';

// Remueve los datos del usuario del estado de la aplicación.
export const LOG_OUT_USER = 'LOG_OUT_USER';


export const TOGGLE_USER_ROLE =  'TOGGLE_USER_ROLE';
export const RESET_USER_ROLE =  'RESET_USER_ROLE';

export const USER_BECOME = 'USER_BECOME'
export const USER_UNBECOME = 'USER_UNBECOME'



export function toggleUserRole(role) {
  return (dispatch) => {
      dispatch({
          type: TOGGLE_USER_ROLE,
          payload: role
        });
  }
}

export function resetUserRole() {
  return (dispatch) => {
      dispatch({
          type: RESET_USER_ROLE
        });
  }
}


/**
* Guarda la información del usuario en el LocalStorage del navegador
*
* @param {*} user : Información del usuario a guardar. Incluye: token de autenticación,
* email y role.
*/
function getUserInfoFromLocalStorage() {
  const user = {}
  user.authenticationToken = sessionStorage.getItem('authenticationToken');
  user.email = sessionStorage.getItem('email');
  user.name  = sessionStorage.getItem('name');
  user.isStudent = sessionStorage.getItem('isStudent');
  user.isGrader = sessionStorage.getItem('isGrader');
  user.isReviewer = sessionStorage.getItem('isReviewer');
  user.isAdmin = sessionStorage.getItem('isAdmin');
  user.isExamAdmin = sessionStorage.getItem('isExamAdmin');
  user.isTeacher = sessionStorage.getItem('isTeacher');
  user.isObserver = sessionStorage.getItem('isObserver');
  user.isObserverAdmin = sessionStorage.getItem('isObserverAdmin');
  return user
}



/**
 * Autenticación del sistema manejando el token e información de inicio de sesión del usuario.
 * La información del mismo luego de autenticarse (token e email) se guarda en el LocalStorage del navegador.
 *
 * @export logInUser
 * @param {*} values : Información de inicio de sesión del usuario.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function logInUser(values, successCallback, errorCallback) {
  const request = axios.post(`${ROOT_URL}/sessions`, values);
  return (dispatch) => {
    request.then(({data}) => {

      // console.log(`logInUser DATA : ${JSON.stringify(data,null,4)}`)
      const user = getUserFromData(data);

      saveUserInfoInLocalStorage(user);

      dispatch({
        type: LOG_IN_USER,
        payload: user
      });

      successCallback();
    }).catch((error) => {
      errorCallback(error);
    });
  };
}

/**
 * Representación de la información de un usuario. Incluye: token de autenticación,
 * email, y role.
 *
 * @param {*} data : Información del usuario
 * @returns Información del usuarios con token de autenticación, email, y role.
 */
function getUserFromData(data) {
  const user = {
    authenticationToken: data.authenticationToken,
    email: data.email,
    name: data.name
  };

  user.isStudent       = data.userRoles.some((userRole) => userRole.role === 'student');
  user.isGrader        = data.userRoles.some((userRole) => userRole.role === 'grader');
  user.isReviewer      = data.userRoles.some((userRole) => userRole.role === 'reviewer');
  user.isAdmin         = data.userRoles.some((userRole) => userRole.role === 'admin');
  user.isExamAdmin     = data.userRoles.some((userRole) => userRole.role === 'exam_admin');
  user.isTeacher       = data.userRoles.some((userRole) => userRole.role === 'teacher');
  user.isObserver      = data.userRoles.some((userRole) => userRole.role === 'observer');
  user.isObserverAdmin = data.userRoles.some((userRole) => userRole.role === 'observer_admin');


  return user;
}

/**
 * Guarda la información del usuario en el LocalStorage del navegador
 *
 * @param {*} user : Información del usuario a guardar. Incluye: token de autenticación,
 * email y role.
 */

function saveUserInfoInLocalStorage(user) {
  getIPAddress()
  sessionStorage.setItem('authenticationToken', user.authenticationToken);
  sessionStorage.setItem('email', user.email);
  sessionStorage.setItem('name', user.name);
  if (user.isStudent)       {sessionStorage.setItem('isStudent', 'true');}
  if (user.isGrader)        {sessionStorage.setItem('isGrader', 'true');}
  if (user.isReviewer)      {sessionStorage.setItem('isReviewer', 'true');}
  if (user.isAdmin)         {sessionStorage.setItem('isAdmin', 'true');}
  if (user.isExamAdmin)     {sessionStorage.setItem('isExamAdmin', 'true');}
  if (user.isTeacher)       {sessionStorage.setItem('isTeacher', 'true');}
  if (user.isObserver)      {sessionStorage.setItem('isObserver', 'true');}
  if (user.isObserverAdmin) {sessionStorage.setItem('isObserverAdmin', 'true');}
}

/**
 * Cierre de sesión del usuario con el sistema. Remueve la información del usuario del LocalStorage
 * del navegador
 *
 * @export logOutUser
 * @returns Dispatch de cierre de sesión para actualizar el estado de la interfaz
 */
export function logOutUser() {
  const request = axios.delete(`${ROOT_URL}/sessions`, getAuthHeaders());
  request.then().catch((error) => {
    console.log('Error login out', error);
  });

  removeUserInfoFromLocalStorage();

  return { type: LOG_OUT_USER };
}


/**
 * Fuerza el cierre de la sesión. Remueve la información del usuario del LocalStorage
 * del navegador.
 *
 * @export forceLogOut
 * @param {*} dispatch : Callback de función para manejo de estado de la interfaz
 */
export function forceLogOut(dispatch) {
  removeUserInfoFromLocalStorage();

  dispatch({
    type: LOG_OUT_USER
  });
  console.log('window.history: ', window.history);
  
  window.history.pushState({},'','/');
  window.location.reload()
}

/**
 * Remueve la información del usuario del LocalStorage del navegador.
 * Se remueve: token de autenticación, email, información de rol del
 * estudiante.
 *
 */
function removeUserInfoFromLocalStorage() {
  sessionStorage.removeItem('authenticationToken');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('name');
  sessionStorage.removeItem('isStudent');
  sessionStorage.removeItem('isGrader');
  sessionStorage.removeItem('isReviewer');
  sessionStorage.removeItem('isAdmin');
  sessionStorage.removeItem('isExamAdmin');
  sessionStorage.removeItem('isTeacher');
  sessionStorage.removeItem('isObserverAdmin');
  sessionStorage.removeItem('isObserver');


}

/**
 * Manejo de expiración de sesión. Fuerza el cierre de sesión y actualiza
 * la página.
 *
 */
function confirmLogOut() {
  if (sessionStorage.getItem("authenticationToken")){
    var timer = setTimeout(logout, 300000)
    swal({
      title: "Atención",
      text: "Su sesión está por expirar, ¿Desea continuar en esta sesión?",
      type: "warning",
      confirmButtonText: "Si, continuar"
      }).then((isConfirm)=> {
          if (isConfirm) {
             swal({
                text: "Ha renovado su sesión",
                type: "success"
             });
             clearTimeout(timer);
            setTimeout(confirmLogOut, 10000000);
          }
      })
  }  
}
function logout(){
  swal(
  'Su sesión expiró'
  )
  logOutUser();
  this.location.reload();
}

// Timer para revisar si la sesión debería expirar.
setTimeout(confirmLogOut, 10000000);

export function sendLoginError(error){
  axios.post(`${ROOT_URL}/sessions/print_front_end_error`, {error: error})
    .catch((error) => {
      console.log(`logging error ${error}`)
    })
}

function getIPAddress() {
  const request = axios.get("https://jsonip.com?callback")
  request.then(({data}) => {
      sessionStorage.setItem("ip_add", data.ip)
  }).catch((error) => {
    console.log(`No se pudo traer la ip: ${error}`);
  });  
}


function saveImpersonatorLocalStorage() {
  const fields = ['authenticationToken','email','name','isStudent','isGrader','isReviewer','isExamAdmin','isTeacher','isObserver','isObserverAdmin']
  for (const field of fields) {
    sessionStorage.setItem(`impersonator_${field}`, sessionStorage.getItem(field));
  }
}

function loadImpersonatorLocalStorage() {
  const fields = ['authenticationToken','email','name','isStudent','isGrader','isReviewer','isExamAdmin','isTeacher','isObserver','isObserverAdmin']
  for (const field of fields) {
    sessionStorage.setItem(field, sessionStorage.getItem(`impersonator_${field}`));
    sessionStorage.removeItem(`impersonator_${field}`)
  }
  sessionStorage.removeItem('IMPERSONATING');
}

function impersonate(user) {
  saveImpersonatorLocalStorage()
  
  sessionStorage.setItem('authenticationToken', user.authenticationToken);
  sessionStorage.setItem('email', user.email);
  sessionStorage.setItem('name', user.name);
  sessionStorage.setItem('IMPERSONATING', true);

  const fields = ['isStudent','isGrader','isReviewer','isExamAdmin','isTeacher','isObserver','isObserverAdmin']
  for (const field of fields) {
    sessionStorage.setItem(field, user[field]);

  }
}



export function become(id, successCallback = () => {}, errorCallback = (error) => {console.log(error)}){
  
  const request = axios.post(`${ROOT_URL}/users/become`, {id: id}, getAuthHeaders())
  return (dispatch) => {
    request.then(({data}) => {
      const become_user = getUserFromData(data)
      // console.log(become_user)
      become_user.name = `*** ${become_user.name}`
      become_user.impersonating = true
      impersonate(become_user)
      dispatch({
        type: USER_BECOME,
        payload: become_user
      });
      successCallback(data);
    })
    .catch((error) => {
      errorCallback(error)
    })
  }
}

export function unbecome(){

  return (dispatch) => {
    loadImpersonatorLocalStorage()
    dispatch({
      type: USER_UNBECOME,
    });
  }
}
