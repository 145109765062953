import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { LOG_IN_URL_OUTLOOK } from '../actions/constants';
import { LOG_IN_URL_GMAIL } from '../actions/constants';
import { LOG_IN_URL_YAHOO } from '../actions/constants';
import { STRUCTURE_DOC, SUPPORT_TEACHER_DOC, SUPPORT_ADMIN_DOC, SUPPORT_GRADER_DOC, SUPPORT_STUDENT_DOC, SUPPORT_REVIEWER_DOC, OBSERVER_ADMIN_DOC, OBSERVER_DOC } from '../actions/constants';

/**
 * Componente Reacondicionado con React Hooks !! por w.garcia24 
 * 
 * Componente barra de navegacion de la aplicacion.
 */

import NavItem from './workaround/NavItem';

const ICONOS_DESARROLLO = process.env.NODE_ENV === 'development'

const Navbar = () => {
  // Use the useSelector hook to access the currentUser and currentTenant from the Redux store
  const currentUser   = useSelector(state => state.currentUser);
  const currentTenant = useSelector(state => state.tenant.tenant);

  const document_icon = 'fa fa-file-pdf-o text-danger fa-lg'
  const image_icon    = 'fa fa-file-image-o text-success fa-lg'

  const signin_icon   = 'fa fa-sign-in fa-lg'
  const signout_icon  = 'fa fa-sign-out fa-lg'
  const user_icon     = "fa fa-user-circle-o fa-lg"

  const done_icon     = "fa fa-check-circle-o text-success"
  const todo_icon     = "fa fa-cogs text-danger"
  const help_icon     = "fa fa-question-circle text-info fa-lg"

  const renderIcon = (icon) => {
    if (! ICONOS_DESARROLLO) return (null)
    return (<i className={icon} />)
  }

  const renderLoginLogout = () => {
    if (currentUser) {
      const manuales = [
        {title: 'Estructura del Front', action: STRUCTURE_DOC, auth: (currentUser && currentUser.isAdmin || null), icon : image_icon ,divider : true },
        {title: 'Administrador', action: SUPPORT_ADMIN_DOC, auth: (currentUser && currentUser.isAdmin || null), icon : document_icon},
        {title: 'Docente', action: SUPPORT_TEACHER_DOC, auth: (currentUser && (currentUser.isTeacher || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Codificador', action: SUPPORT_GRADER_DOC, auth: (currentUser && (currentUser.isGrader || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Estudiante', action: SUPPORT_STUDENT_DOC, auth: (currentUser && (currentUser.isStudent || currentUser.isAdmin )|| null), icon : document_icon},
        {title: 'Revisor', action: SUPPORT_REVIEWER_DOC, auth: (currentUser && (currentUser.isReviewer || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Gestor Procesos de Observación', action: OBSERVER_ADMIN_DOC, auth: (currentUser && currentUser.isObserverAdmin || null), icon : document_icon},
        {title: 'Observador', action: OBSERVER_DOC, auth: (currentUser && currentUser.isObserver || null), icon : document_icon}
      ]
      const help_title = <>{renderIcon(help_icon)} Manuales</>
      const user_title = <>{renderIcon(user_icon)} {currentUser.name? currentUser.name:'Bienvenid@' }</>
      // console.log(`currentUser ${JSON.stringify(currentUser,null,4)}`)
      return (
        <>
        <NavDropdown eventKey={29} title={user_title} id="basic-nav-dropdown">
          <MenuItem disabled>{currentUser.email}</MenuItem>
          <MenuItem divider></MenuItem>
          <NavDropdown eventKey={30} title={help_title} id="basic-nav-dropdown">
            {currentUser && manuales.map((manual, i) => (
                manual.auth ? <>
                  <MenuItem href={manual.action} key={(i+1)} target="_blank" eventKey={31+i}> 
                    {manual.icon ? <>{renderIcon(manual.icon)} </> : ''}
                    Manual de {manual.title}
                  </MenuItem>
                  {manual.divider ? <MenuItem divider></MenuItem> : ''}
                </> : ''
              )
            )}
          </NavDropdown>
          <MenuItem divider></MenuItem>
          <LinkContainer to="/users/logout" eventKey={1}>
            <NavItem> 
              {renderIcon(signout_icon)} Cerrar sesión
            </NavItem>
          </LinkContainer>
        </NavDropdown> 
        </>
      );
    } else {
      const title = <>{renderIcon(signin_icon)} Ingresar</>
      return (
        <NavDropdown eventKey={2} title={title} id="basic-nav-dropdown">
            <MenuItem href={LOG_IN_URL_OUTLOOK} eventKey={3}><i className="fa fa-windows text-primary" /> Outlook(Uniandes)</MenuItem>
            <MenuItem href={LOG_IN_URL_GMAIL}   eventKey={4}><i className="fa fa-google text-danger" /> Gmail</MenuItem>
            <MenuItem href={LOG_IN_URL_YAHOO}   eventKey={5}><i className="fa fa-yahoo text-danger" /> Yahoo</MenuItem>
      </NavDropdown>
      );
    }
  }

  const renderTeacherNavItems= () => {
    if (currentUser && currentUser.isTeacher) {
      const title = <>{renderIcon(todo_icon)} Mis cursos</>
      return (
        <NavDropdown eventKey={6} title={title} id="basic-nav-dropdown">
          <LinkContainer to="/coursesTeacher" eventKey={7}>
            <MenuItem>
              {renderIcon(todo_icon)} Ver mis cursos
            </MenuItem>
          </LinkContainer>
          <MenuItem href={SUPPORT_TEACHER_DOC}  target="_blank" eventKey={8}><i className={document_icon} /> Manual de usuario</MenuItem>
        </NavDropdown> 
      );
    } else {
      return (null);
    }
  }

  const renderStudentNavItems = () => {
    if (currentUser && currentUser.isStudent) {
      return (
        <LinkContainer to="/answers" eventKey={9}>
          <NavItem>
            {renderIcon(todo_icon)} Mis pruebas
          </NavItem>
        </LinkContainer>
      );
    } else {
      return (null);
    }
  }

  const renderGraderNavItems = () => {
    if (currentUser && currentUser.isGrader) {
      return (
        <LinkContainer to="/answerGraders" eventKey={10}>
          <NavItem>
            {renderIcon(todo_icon)} Centro de codificación
          </NavItem>
        </LinkContainer>
      );
    } else {
      return (null);
    }
  }

  const renderReviewerNavItems = () => {
    if (currentUser && currentUser.isReviewer) {
      return (
        <LinkContainer to="/answerReviewers" eventKey={11}>
          <NavItem>
            {renderIcon(todo_icon)} Centro de revisión
          </NavItem>
        </LinkContainer>
      );
    } else {
      return (false);
    }
  }

  const isAdmin = ()=>{
    if (currentUser.isAdmin || currentUser.isExamAdmin){
      return true
    }
    return false
  }
  
  const renderAdminNavItems = () => {
    if (currentUser && isAdmin())  {
      const title = <>{renderIcon(todo_icon)} Panel de aplicación</>
      return (
        <NavDropdown eventKey={12} title={title} id="basic-nav-dropdown">
            <LinkContainer to="/exams" eventKey={16}>
              <MenuItem>{renderIcon(todo_icon)}Examenes</MenuItem>
            </LinkContainer>
            <LinkContainer to="/tests" eventKey={15}>
              <MenuItem >{renderIcon(todo_icon)} Preguntas</MenuItem>
            </LinkContainer>
            <LinkContainer to="/texts" eventKey={14}>
              <MenuItem >{renderIcon(todo_icon)} Contenidos Adjuntos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/questionaries" eventKey={18}>
              <MenuItem>{renderIcon(todo_icon)} Cuestionarios</MenuItem>
            </LinkContainer>
            <MenuItem divider></MenuItem>
            {currentUser.isAdmin?
              <LinkContainer to="/superMatrices" eventKey={22}>
                <MenuItem>{renderIcon(todo_icon)} Matrices de reporte histórico</MenuItem>
              </LinkContainer>:<></>
            }
            <LinkContainer to="/gradingMatrices" eventKey={13}>
              <MenuItem >{renderIcon(todo_icon)} Matrices de codificación</MenuItem>
            </LinkContainer>
            <LinkContainer to="/answerBank" eventKey={21}>
              <MenuItem>{renderIcon(todo_icon)} Banco de respuestas</MenuItem>
            </LinkContainer>
            {currentTenant === 'ecentroevaluacion' && (<>
              <MenuItem divider></MenuItem>
              <LinkContainer to="/labels" eventKey={17}>
                <MenuItem >{renderIcon(todo_icon)} Etiquetas</MenuItem>
              </LinkContainer>
              <LinkContainer to="/themes" eventKey={19}>
                <MenuItem>{renderIcon(todo_icon)} Temas</MenuItem>
              </LinkContainer>
            </>)}
            <MenuItem divider></MenuItem>
            <LinkContainer to="/users" eventKey={17}>
              <MenuItem >{renderIcon(done_icon)} Usuarios</MenuItem>
            </LinkContainer>
            <LinkContainer to="/students" eventKey={19}>
              <MenuItem>{renderIcon(todo_icon)} Consultar estudiantes</MenuItem>
            </LinkContainer>
            <MenuItem divider></MenuItem>
            <LinkContainer to="/coursesAdmin" eventKey={20}>
              <MenuItem>{renderIcon(todo_icon)} Administración de cursos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/semesterYear" eventKey={23}>
              <MenuItem>{renderIcon(todo_icon)} Semestres</MenuItem> 
            </LinkContainer>
        </NavDropdown> 
      );
    } else {
      return (null);
    }
  }
  
  const renderReportNavItems = () => {
    if (currentUser && isAdmin()) {
      const title = <>{renderIcon(todo_icon)} Reportes agregados</>
      return (
        <NavDropdown eventKey={24}  title={title} id="basic-nav-dropdown">
          <LinkContainer to="/faculties" eventKey={25}>
            <MenuItem>Facultad</MenuItem>
          </LinkContainer>
          <LinkContainer to="/departments" eventKey={26}>
            <MenuItem>Departamento</MenuItem>
          </LinkContainer>
          <LinkContainer to="/courses" eventKey={27}>
            <MenuItem>Curso</MenuItem>
          </LinkContainer>
        </NavDropdown>
      );
    } else {
      return (null);
    }
  }

  const renderVideoModuleNavItems = () => {
    if (currentTenant === "ecentroevaluacion" && currentUser && (currentUser.isObserverAdmin || currentUser.isObserver)) {
      const title = <>{renderIcon(done_icon)} Gestión Procesos de Observación</>
      return ( 
        <>
          {currentUser.isObserverAdmin && (
          <NavDropdown eventKey={24}  title={title} id="basic-nav-dropdown">
            <LinkContainer to="/processes" eventKey={25}>
              <MenuItem>{renderIcon(done_icon)} Procesos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/usersVideos" eventKey={27}>
              <MenuItem>{renderIcon(done_icon)} Usuarios</MenuItem>
            </LinkContainer>
            <LinkContainer to="/videos" eventKey={26}>
              <MenuItem>{renderIcon(done_icon)} Videos</MenuItem>
            </LinkContainer>
          </NavDropdown>)}
          {currentUser.isObserver &&
            (<>
              <LinkContainer to="/observers/processes" eventKey={28}>
                <NavItem>
                  {renderIcon(done_icon)} Mis Procesos
                </NavItem>
              </LinkContainer>
            </>)
          } 
          
        </> 
      );
    } else {
      return (null);
    }
  }

  return (
    <BootstrapNavbar collapseOnSelect fluid>
      <BootstrapNavbar.Header>
        <BootstrapNavbar.Brand>
            <Link to="/">
              <img
                src={`/images/${currentTenant}/LogoAndes.svg`}
                alt="Universidad de los Andes"
                width="165px"
              />
            </Link>
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle />
      </BootstrapNavbar.Header>
      <BootstrapNavbar.Collapse>
        <Nav>
          {renderStudentNavItems()}
          {renderTeacherNavItems()}
          {renderGraderNavItems()}
          {renderReviewerNavItems()}
          {renderReportNavItems()}
          {renderAdminNavItems()}
          {renderVideoModuleNavItems()}
        </Nav>
        <Nav pullRight>
          {renderLoginLogout()}
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
}

export default Navbar
