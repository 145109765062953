import _ from 'lodash';
import { GET_STUDENT_INFO } from '../actions/studentsActions';

/**
 * Manejo de la parte del estado correspondiente a estudiantes.

 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */

 export default function (state = {}, action) {
    switch(action.type) {
      case GET_STUDENT_INFO:
        const studentInfo = action.payload;
        return { ...state, studentInfo};
      default:
        return state;
    }
  }