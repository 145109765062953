import { GET_LABEL_ANSWERS, GET_LABEL_ANSWER, DELETE_LABEL_ANSWER, SELECT_LABEL_ANSWER, UPDATE_TEXT_LABELS, REPORT_LABEL_ANSWERS, RESET } from '../actions/labelAnswerActions';

/**
 * Manejo de la parte del estado correspondiente a los labels para labeling.
 * Permite la actualización de labels, obtener un label por id.
 * Permite eliminar un label por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los labels)
 * @returns El estado con la modificación/acción realizada.
 */
const initialState = {
  labelAnswers: [],
  labelAnswer: null,
  textLabels: [],
  textLabelSelected: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LABEL_ANSWERS:
      return {...state,
        labelAnswers: action.payload 
      }
    case GET_LABEL_ANSWER:
      return {...state,
        labelAnswer: action.payload, 
        textLabels: action.payload?.textLabels || []
      }
    case DELETE_LABEL_ANSWER:
      return {...state,
        labelAnswer: undefined 
      }
    case SELECT_LABEL_ANSWER:
      return {...state,
        textLabelSelected: action.payload 
      }
    case UPDATE_TEXT_LABELS:
      return {...state,
        textLabels: action.payload 
      }
    case REPORT_LABEL_ANSWERS:
      return {
        ...state,
        report: action.payload
      }
    case RESET:
      return initialState
    default:
      return state;
  }
}