import React, { Component, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import McAnswerClosed from '../../../mcAnswers/McAnswerClosed';
import * as Survey from 'survey-react'
import { Link } from 'react-router-dom';
import usePatchRequest from "../../../../customHooks/usePatchRequest";
import "survey-react/survey.css";
import CountdownTimer from '../../../CountdownTimer';

/**
 * Componente base para mostrar una respuesta a un cuestionario. Según en estado renderiza un componente diferente
 * Posibles estados: Pendiente, Cerrada y en Progreso.
 *
 * @function McAnswer
 */

const McAnswer = (props) => {
  const mcAnswer = props.data
  const dispatch = useDispatch() 

  // PETICIONES HTTP
  const {
    data:responseFinishMcAnswer,
    sendRequest:finishMcAnswer
  } = usePatchRequest(
    `mc_answers/${mcAnswer?.id}/finish`
  );

  const {
    data:responsePartialSavedMcAnswer,
    sendRequest:partialSavedMcAnswer
  } = usePatchRequest(
    `mc_answers/${mcAnswer?.id}/partial`
  );

  useEffect(()=>{
    console.log('mcAnswer: ', mcAnswer);
    
  },[mcAnswer])


  const mcAnswerIsClosed = () => {
    const now = new Date();
    const testFinalDate = new Date(mcAnswer.questionary.finalDate);
    return mcAnswer.complete === true || testFinalDate < now
  }


  const cleanValues = (values) => {
    let json = JSON.stringify(values)
    return json
  }

  const onComplete = event => {
    let values= cleanValues(event.valuesHash)
    alert('finaliza')
    finishMcAnswer({structure: values})

  }

  const onPartialSend = event => {
    let values = cleanValues(event.valuesHash)
    if (event.valuesHash) {
      alert('actualiza')
      partialSavedMcAnswer({structure: values});
    }
  }

  const renderMcAnswerInformation = () => {
    var partialData=[];
    if (mcAnswer.structure!==null){
      partialData = mcAnswer.structure;
      
    }

    if (mcAnswerIsClosed()) {
      return <McAnswerClosed mcAnswer={mcAnswer} history={props.history} />;
    } else {
        console.log('mcAnswer.questionary.structure: ', mcAnswer.questionary.structure);
        
      return (
        <div>
        {mcAnswer.questionary.structure ? (<>
          <Survey.Survey 
            json={mcAnswer.questionary.structure}
            showCompletedPage={false} 
            onComplete={onComplete} 
            onPartialSend={onPartialSend}
            data={partialData}
          /> 
        </>): <p>No existe este cuestionario </p>}
        </div>);
    }
    
  }

  return (
    <div>
      {<CountdownTimer targetDate={mcAnswer.questionary.finalDate}/>}
      {renderMcAnswerInformation()}
    </div>
  );
}

export default McAnswer
