import _ from 'lodash';
import { GET_EXAM_QUESTIONARIES, GET_EXAM_QUESTIONARY} from '../actions/examQuestionariesActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas de examen.
 * Permite la actualización de preguntas de examen, obtener una pregunta de examen por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_EXAM_QUESTIONARIES:
      const examQuestionaries = _.mapKeys(action.payload.examMcQuestionaries, 'id');
      const pagination = action.payload.links;
      return { ...state, examQuestionaries: examQuestionaries, pagination: pagination};
    case GET_EXAM_QUESTIONARY:
        const examMcQuestionary = action.payload;
        return { ...state, examMcQuestionary: examMcQuestionary };
    default:
      return state;
  }
}