/**
 * Permite mapear un iterable a los valores de su key 'value'.
 *
 * @export optionsToValues
 * @param {*} options : Elemento iterable del que se quiere obtener la key.
 * @returns
 */
export function optionsToValues(options) {
  if (options){
  return options.map((option) => {
    return option.value;
  });}
}

export const TENANTS = {
  EVALUACION:'ecentroevaluacion',
  ESPANOL:'ecentroespanol'
}
/**
 * Formatea un arreglo como un objeto. Como key de los valores usa el indice del arreglo.
 *
 * @export arrayToObject
 * @param {*} array : El arreglo a formatear.
 * @returns
 */
export function arrayToObject(array) {
  const object = {};
  for (let i = 0; i < array.length; i++) {
    object[`${i}`] = array[i];
  }
  return object;
}

// lowdash replacement functions!!

export function isEmpty(value){
  return  value === undefined ||
          value === null ||
          (typeof value === "object" && Object.keys(value).length === 0) ||
          (typeof value === "string" && value.trim().length === 0)
}

export function map(array, iteratee) {
  // console.log(array)
  // console.log(typeof(array))

  let index = -1
  const length = array === null ? 0 : array.length
  const result = new Array(length)
  if (typeof(array) === 'object') {
    Object.keys(array).forEach((key,index) => {
      result[index] = iteratee(array[key])
    })
  } else {
    while (++index < length) {
      result[index] = iteratee(array[index], index, array)
    }
  }
  return result
}

export function defaultErrorCallback(error) {
  console.log(error)
}

export function defaultSuccessCallback(data) {
  console.log('success',data)
}

export function convertText(element, text = '', iterations = 0) {
  // console.log('inicio funcion');
  // console.log('funcion #: ', iterations);
  // console.log('element.children: ', element.children);
  // console.log('element.childNodes: ', element.childNodes);

  if (element.childNodes.length > 0) {
    // console.log('nodos: ', element.children)

    const nodes = [...element.childNodes]
    for (let i = 0; i < nodes.length; i++) {
      // console.log('iteracion #: ', i+1);

      const node = nodes[i]
      if ( node.childNodes.length > 0 && [...node.childNodes].some(child => child.tagName && child.tagName.match(/ol|ul|li|p|br/gi)) ) {
      // if ( node.childNodes.length > 1 || (node.childNodes.length === 1 && node.childNodes[0].tagName)) {

        iterations++
        text = convertText(node, text, iterations)

      } else {
        // console.log('NODO: ', node);
        // console.log('TAG: ', node.tagName);
        // console.log('CONTENIDO: ', node.textContent);


        if (node.tagName === 'BR') { 
          text += '\n'
        } 
        if (node.tagName === 'P') {
          text += `${node.textContent.replaceAll(/\s{2,}/gi, ' ').trim()}\n`
        } 
        if (node.tagName === 'BLOCKQUOTE') {
          text += `${node.textContent.replaceAll(/\s{2,}/gi, ' ').trim()}\n`
        } 
        if (node.tagName === 'SPAN') {
          text += `${node.textContent.replaceAll(/\s{2,}/gi, ' ').trim()}\n`
        }
        if (node.tagName === 'LI') {
          text += `${node.textContent.replaceAll(/\s{2,}/gi, ' ').trim()}\n`
        } 
        if (!node.tagName && node.textContent) {
          text += `${node.textContent.replaceAll(/\s{2,}/gi, ' ').trim()}`
        }
      }
    }      
  }
  // console.log('fin funcion');
  // console.log('texto: ', text);
  return text
}

export function buildInterfazText(texto, textLabelsList, selected = null, labels = []) {

  // crea el objeto con los colores
  const colors = labels.reduce((acumulator, value) => { return { ...acumulator, [value.name]: value.color } }, {})

  //convierte el texto en un array 
  const cadena = texto.split('')

  //le agrega los saltos de linea
  while (cadena.indexOf('\n') !== -1) {
    cadena[cadena.indexOf('\n')] = '<br>'
  }


  if (textLabelsList.length > 0 ) {

    //CONSTRUCCION DE LABELS
    textLabelsList.map((selecction, i ) => {

      // busca al seleccionado
      const isSelected = ( selecction?.start === selected?.start ) && ( selecction?.end === selected?.end )
      const classNames= `${selecction.labelId} highlightb${selecction.start}e${selecction.end}`

      // indice 0
      if (i===0) {
        cadena[selecction.start] = `<span class="${selecction.labelId} ${isSelected ? ' selected' : ''} ${classNames}" style="--highligh-color: ${colors[selecction.label]}${isSelected ? "a0" : "50"};" >${cadena[selecction.start]}`
        cadena[selecction.end] = `${cadena[selecction.end]}</span>`
        
        if (isSelected && selecction.reviewerLabel) {
        cadena[selecction.reviewerLabel.start] = `<u class="${selecction.labelId}" id="b${selecction.start}e${selecction.end}">${cadena[selecction.reviewerLabel.start]}`
        cadena[selecction.reviewerLabel.end] = `${cadena[selecction.reviewerLabel.end]}</u>`
        }
        return ''
      }

      cadena[selecction.start] = `<span class="${selecction.labelId} ${isSelected ? ' selected' : ''} ${classNames}" style="--highligh-color: ${colors[selecction.label]}${isSelected ? "a0" : "50"};" >${cadena[selecction.start]}`
       


      let fragments = cadena.slice(selecction.start, selecction.end)
      let traslapo = fragments.some( fragment => fragment.match(/<\/span>/))
      if (traslapo) {
        fragments.forEach((fragment, i) => {
          if (fragment.match(/<\/span>/)) {
            cadena[selecction.start+i] = `${cadena[selecction.start+i]}</span><span class="${selecction.labelId} ${isSelected ? ' selected' : ''} ${classNames}" style="--highligh-color: ${colors[selecction.label]}${isSelected ? "a0" : "50"};" >`
          }
        })
      }
      cadena[selecction.end] = `${cadena[selecction.end]}</span>`

      // seleccionado con seleccion de revisor
      if (isSelected && selecction.reviewerLabel) {
        fragments = cadena.slice(selecction.reviewerLabel.start, selecction.reviewerLabel.end)
        traslapo = fragments.some( fragment => fragment.match(/<\/span>/)) || fragments.some( fragment => fragment.match(/<span/))

        cadena[selecction.reviewerLabel.start] = `<span class="review_highligh ${selecction.reviewerLabel.labelId}" class="b${selecction.start}e${selecction.end}" style="--highligh-color: ${colors[selecction.reviewerLabel.label]}${isSelected ? "a0" : "50"};">${cadena[selecction.reviewerLabel.start]}`
        if (traslapo) {

          fragments.forEach((fragment, i) => {
            // si se topa un cierre
            if (fragment.match(/<\/span>/)) {
              cadena[selecction.reviewerLabel.start] = cadena[selecction.reviewerLabel.start].replace('<span class="', `<span class="selected ${classNames} `)
              cadena[selecction.reviewerLabel.start+i] = `${cadena[selecction.reviewerLabel.start+i]}</span><span class="review_highligh ${selecction.reviewerLabel.labelId} class="b${selecction.start}e${selecction.end}" style="--highligh-color: ${colors[selecction.reviewerLabel.label]}${isSelected ? "a0" : "50"};" >`
              cadena[selecction.reviewerLabel.end] = `${cadena[selecction.reviewerLabel.end]}</span>`
            }
            // si se topa una apertura
            if (fragment.match(/<span class="/)) {
              cadena[selecction.reviewerLabel.start+i] = cadena[selecction.reviewerLabel.start+i].replace('<span class="', '<span class="review_highligh ')
              cadena[selecction.reviewerLabel.end] = `${cadena[selecction.reviewerLabel.end]}</span></span><span class="${selecction.reviewerLabel.labelId} ${isSelected ? ' selected' : ''} ${classNames}" style="--highligh-color: ${colors[selecction.reviewerLabel.label]}${isSelected ? "a0" : "50"};" >`
            }
          })
        } else {
          cadena[selecction.reviewerLabel.end] = `${cadena[selecction.reviewerLabel.end]}</span>`
        }
      }
    })
  }
  return cadena
}