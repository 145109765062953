import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import CurrentUserReducer from './CurrentUserReducer';
import PendingExamsReducer from './PendingExamsReducer';
import ClosedExamsReducer from './ClosedExamsReducer';
import AnswerReducer from './AnswerReducer';
import McAnswerReducer from './McAnswerReducer';
import AnswerGradersReducer from './AnswerGradersReducer';
import AnswerReviewersReducer from './AnswerReviewersReducer';
import AssignationsReducer from './AssignationsReducer';
import TestsReducer from './TestsReducer';
import ExamsReducer from './ExamsReducer';
import DepartmentsReducer from './DepartmentsReducer';
import FacultiesReducer from './FacultiesReducer';
import CoursesReducer from './CoursesReducer';
import StudentsReducer from './StudentsReducer';
import ExamTestsReducer from './ExamTestsReducer'
import GradingMatricesReducer from './GradingMatricesReducer';
import ReportsReducer from './ReportsReducer'
import TextsReducer from './TextsReducer';
import UsersReducer from './UsersReducer';
import UsersGradingMatricesReducer from './UsersGradingMatricesReducer';
import QuestionariesReducer from './QuestionariesReducer';
import ExamQuestionariesReducer from './ExamQuestionariesReducer';
import TenantReducer from './TenantReducer';
import SearchesReducer from './SearchesReducer';
import AnswerBankReducer from './AnswerBankReducer';
import SuperMatricesReducer from './SuperMatricesReducer';
import ProcessesReducer from './ProcessesReducer';
import VideosReducer from './VideosReducer';
import ApwindowsReducer from './ApwindowsReducer';
import ApwindowUsersReducer from './ApwindowUsersReducer';
import LabelReducer from './LabelReducer';
import LabelAnswerReducer from './LabelAnswerReducer';
import ThemeReducer from './ThemeReducer';
import GradingCritetionOptionReducer from './GradingCritetionOptionReducer';
import GradingCriteriaReducer from './GradingCriteriaReducer';
// Permite unificar la parte del estado de la que cada reducer se encarga
const rootReducer = combineReducers({
  answer: AnswerReducer,
  answerBank: AnswerBankReducer,
  answerGraders: AnswerGradersReducer,
  answerReviewers: AnswerReviewersReducer,
  apwindows: ApwindowsReducer,
  apwindowUsers: ApwindowUsersReducer,
  assignations: AssignationsReducer,
  closedExams: ClosedExamsReducer,
  courses: CoursesReducer,
  currentUser: CurrentUserReducer,
  departments: DepartmentsReducer,
  exams: ExamsReducer,
  examQuestionaries: ExamQuestionariesReducer,
  examTests: ExamTestsReducer,
  faculties: FacultiesReducer,
  form: formReducer,
  gradingMatrices: GradingMatricesReducer,
  gradingCriterias: GradingCriteriaReducer,
  gradingCriterionOptions: GradingCritetionOptionReducer,
  labels: LabelReducer,
  labelAnswers: LabelAnswerReducer, 
  mcAnswer: McAnswerReducer,
  pendingExams: PendingExamsReducer,
  processes: ProcessesReducer,
  questionaries: QuestionariesReducer,
  reports: ReportsReducer,
  search: SearchesReducer,
  students: StudentsReducer,
  superMatrices: SuperMatricesReducer,
  tenant: TenantReducer,
  themes: ThemeReducer,
  tests: TestsReducer,
  texts: TextsReducer,
  users: UsersReducer,
  usersGradingMatrices: UsersGradingMatricesReducer,
  videos: VideosReducer
});

export default rootReducer;
