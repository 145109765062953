import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import 'moment/locale/es';
import 'react-quill/dist/quill.snow.css';
import swal from 'sweetalert2';
import { ROOT_URL, getAuthHeaders, AZURE_STORAGE_CONNECTION_STRING, AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER, ACCEPTED_FORMATS } from '../../actions/constants'
import ReactQuill from 'react-quill';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import axios from 'axios';
// import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";


function CreateProccess(props){
  const user = useSelector(state => state.currentUser)
  const file = React.useRef(null);
  // const {user} = props;
  const [disabled, setDisabled] = useState(true);

  const [form, setForm] = useState({
    title: '',
    description: '',
    instructions_text: '',
    instructions_file: '',
    deadline: ''
  });

  const [observers, setObservers] = useState({selected: [], options: []});
  const [observerAdmins, setObserverAdmins] = useState({selected: [{label: user.email, value: user.email}], options: []});
  
  let box_style = {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  }

  const modules = {
    toolbar: [
      [{'size': ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'align': []}],
      ['clean']
    ]
  };
  
  const handleChange = (e) => {
    e.target ? setForm({ ...form, [e.target.name]: e.target.value}): setForm({ ...form, ...e});
    disabled && setDisabled(false);
  }

  const handleSubmit = async (e) => {
    try {
      let name_file;
      e.preventDefault();
      const saveFile =form.instructions_file ? await onInstructionsAttach(): true;
      if (saveFile) {
        if (form.instructions_file){
          name_file = `process ${form.title} - ${form.instructions_file}`
        } else {
          name_file = ""
        }
        const request = await axios.post(`${ROOT_URL}/observation_processes`, {
          form: {
            ...form,
            instructions_file: `${name_file}`,
            created_at: moment().format('YYYY-MM-DD HH:mm')
          }
        },
        getAuthHeaders());

        if (request.status === 201) {

          const requestData = request.data?.observationProcess?.observationProcess;
          swal({
            type: 'success',
            title: 'proceso de observación registrado',
            showConfirmButton: false,
            timer: 1600
          }).then(async () => {
            swal.close();
            if (observers.selected.length > 0) {
              const promises = [];
              observers.selected.map(obj => (
                promises.push(axios.post(`${ROOT_URL}/observers`, {
                  id: requestData.id,
                  email: obj.value
                }, getAuthHeaders()))
              ))
              await Promise.all(promises);
            }
  
            if (observerAdmins.selected.length > 0) {
              const promises = [];
              observerAdmins.selected.map(obj => (
                promises.push(axios.post(`${ROOT_URL}/observer_admins`, {
                  id: requestData.id,
                  email: obj.value
                }, getAuthHeaders()))
              ))
              await Promise.all(promises);
            }
            return props.history.push('/processes')
          })
        }
      }
    } catch (error) {
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error, intente nuevamente',
      } )
    }
  }


  const onInstructionsAttach = async () => {
    const fileInput = file.current;
    const fileContainer = AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER;

    return swal({
      title: '¿Está seguro?',
      html: `Se cargará el archivo: ${form.instructions_file}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, enviar',
      cancelButtonText: 'Cancelar'
    }).then(({value}) => {
      if (value) {
        let fileUrlElements = form.instructions_file.split(".");
        const format = fileUrlElements[fileUrlElements.length - 1] || "";
        if (ACCEPTED_FORMATS.includes(format)){
          const filename = `process ${form.title} - ${form.instructions_file}`;
          return uploadAzureFiles(fileInput.files[0], filename, fileContainer)
        }
        return swal(
          'Ups...',
          'Ocurrió un error el tipo de archivo no es soportado',
          'error'
        ).then(()=> {
          return false;
        });
      } else {
        swal.close();
        return swal({
          title: '¿desea aun asi guardar los demas cambios?',
          showCancelButton: true,
          confirmButtonText: 'Si, guardar',
          cancelButtonColor: '#d33',
          cancelButtonText: `No, cancelar`,
        }).then(({value}) => {
          if (value)
            return true;

          return false;
        })
      }
    });
  }

  const changeSelect = (newValue, state = '') => {
    if (state === 'observers') {
      setObservers({
        ...observers,
        selected: newValue,
      })
    }

    if (state === 'observerAdmins') {
      let index = newValue.findIndex((obj)=>(obj.value === user.email && true));
      setObserverAdmins({
        ...observerAdmins,
        selected: index < 0 ? [...newValue, {label: user.email, value: user.email}] : newValue,
      })
    }
  }

  const uploadAzureFiles = async (file, filename, containerName) => {
    try {
      const { BlobServiceClient } = require('@azure/storage-blob');
      const blobSasUrl = AZURE_STORAGE_CONNECTION_STRING
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient(containerName);

      const promises = [];
      const blockBlobClient = containerClient.getBlockBlobClient(filename);

      promises.push(blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024, 
        concurrency: 20, 
        blobHTTPHeaders :{blobContentType:file.type}
      }));
      swal({
        title: "Cargando",
        showConfirmButton: false,
        onBeforeOpen: () => {
          swal.showLoading()
        },
      });
      await Promise.all(promises);
      swal.close();
      return swal({
        type: 'success',
        title: 'Se subieron los archivos exitosamente',
        showConfirmButton: false,
        timer: 1600
      }).then(()=> true);
    } catch (error) {
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error, intente nuevamente',
      } ).then(()=> (false));
    }
  }

  useEffect(()=>{
    const getOptions =async()=>{
      try {
        const observersRequest = await axios.get(`${ROOT_URL}/observers/getUsers`, getAuthHeaders());
        if (observersRequest.status === 200) {
          setObservers({
            ...observers,
            options: observersRequest.data.map(obj => ({label: obj.email, value: obj.email}))      
          })
        }
        const observerAdminsRequest = await axios.get(`${ROOT_URL}/observer_admins/getUsers`, getAuthHeaders());
        if (observerAdminsRequest.status === 200) {
          setObserverAdmins({
            ...observerAdmins,
            options: observerAdminsRequest.data.map(obj => ({label: obj.email, value: obj.email}))      
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    getOptions();
  },[]);

	return (
		<>
			<h1>Crear proceso de observación</h1>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div style={box_style} className="row">
            <div className="col-md-8 col-xs-8">
              <label>Nombre del proceso* </label>
              <input
                type="text"
                name="title"
                id="processName"
                required
                value={form.title}
                className='form-control'
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-xs-4">
              <label>fecha cierre* </label>
              <Datetime
                inputProps={{
                  required: true
                }}
                locale='es'
                value={moment(form.deadline)}
                onChange={(value) => handleChange({'deadline': moment(value).format('YYYY-MM-DD HH:mm')})}
              />
            </div>
          </div>
        </div>
        <div style={box_style} className="row">
          <div className="col-md-12 col-xs-12">
            <div className="form-group">
              <label>Descripcion</label>
              <br />
              <div autoComplete="off" spellCheck="false">
                <ReactQuill 
                    style= {{height: '127px'}}
                    className="quillFlex"
                    value={form.description}
                    onChange={(newValue) => handleChange({'description': newValue})}
                    modules={modules}/>
              </div>
            </div>
          </div>
        </div>
        <div style={box_style} className="row">
          <div className="col-md-12 col-xs-12">
            <div className="form-group">
              <label>Instrucciones </label>
              <p>Puede escribir las instrucciones en el recuadro y/o adjuntar un archivo pdf</p>
              <div id="quill-container" style= {{height: "127px"}} autoComplete="off" spellCheck="false">
                <ReactQuill 
                    style= {{height: "187px"}}
                    className="quillFlex"
                    onChange={(value) => handleChange({'instructions_text': value})}
                    value={form.instructions_text}
                    modules={modules}/>
              </div>
              <br/>
              <div style={{display:"flex"}}>
                <input 
                    type="file"
                    name="instructions_file"
                    id="instructions-input"
                    ref={file}
                    onChange={(e) => handleChange({[e.target.name]: e.target.files[0].name})}
                  />
              </div>
            </div>
          </div>
        </div>
        <div style={box_style} className="row">
          <div className="col-md-6 col-xs-6">
            <label>Gestores asociados</label>
            <MultiSelect
              onChange = {val => changeSelect(val, 'observerAdmins')}
              value={observerAdmins.selected}
              multi={true}
              options={observerAdmins.options}
              labelledBy={"Select"}
            />
          </div>
          <div className="col-md-6 col-xs-6">
            <label>Observadores asociados</label>
            <MultiSelect
              onChange = {val => changeSelect(val, 'observers')}
              value={observers.selected}
              multi={true}
              options={observers.options}
              labelledBy={"Select"}
              showArrow	={true}
            />
          </div>
        </div>
        <div>
          <button
            onClick={() => props.history.push('/processes')}
            type="button"
            className="btn btn-danger">
              Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary pull-right"
            disabled={disabled}
          >
            Guardar
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
		</>
	)
}

export default CreateProccess
