import _ from 'lodash';
import { GET_TESTS, GET_TEST, DELETE_TEST, UPDATE_TEST_GRADER, TOOGLE_UPDATE_GRADER} from '../actions/testsActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {toogleGrader:false}, action) {
  switch(action.type) {
    case GET_TESTS:
      const tests = _.mapKeys(action.payload.tests, 'id');
      const pagination = action.payload.links;
      return { ...state, tests: tests, pagination: pagination};
    case GET_TEST:
      const test = action.payload;
      return { ...state, [test.id]: test };
    case DELETE_TEST:
      return {...state, tests:_.omit(state.tests, action.payload)};
    case UPDATE_TEST_GRADER:
      const testUpdate = action.payload;
      return { ...state, [test]: testUpdate };
    case TOOGLE_UPDATE_GRADER:
      return {
        ...state,
        toogleGrader: !state.toogleGrader
      };
    default:
      return state;
  }
}
