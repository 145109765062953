import _ from 'lodash';
import { GET_PROCESSES, SEARCH_PROCESSES, GET_VIDEOPROCESSES } from '../actions/processesActions';

export default function (state = {}, action) {
    switch (action.type) {
      case GET_PROCESSES:
        const processes = _.mapKeys(action.payload.observationProcesses, 'id');
        const pagination = action.payload.links;
        if (action.payload.search) {
          const {search} = action.payload;
          return { ...state, processes, pagination, search };
        }
        state.search && delete state.search
        return { ...state, processes, pagination };
      case SEARCH_PROCESSES:
        const newProcesses = _.mapKeys(action.payload.observationProcesses, 'id');
        const newPagination = action.payload.links;
        return { ...state, processes: newProcesses, pagination: newPagination, text: action.payload.text };  
      case GET_VIDEOPROCESSES:
        return { ...state, selected: action.payload.map(process => ({label: process.title, value: process.id})) };
      default:
        return state;
    }
  }