import React, {useEffect, useState, useRef } from "react";
import moment from 'moment';
import ReactQuill from 'react-quill';
import swal from 'sweetalert2';
import AnswerMultimediaPlayer from "../../../embed/AnswerMultimediaPlayer";
import VideoRecorder from 'react-video-recorder'
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'
import { AZURE_STORAGE_MULTIMEDIA_CONTAINER, AZURE_STORAGE_FILE_CONTAINER, ACCEPTED_MULTIMEDIA_FORMATS, AZURE_STORAGE_CONNECTION_STRING, BLOB_ACCOUNT } from "../../../../actions/constants";
import EmbedFile from "../../../embed/EmbedFile";
import useGetRequest from "../../../../customHooks/useGetRequest";
import usePatchRequest from "../../../../customHooks/usePatchRequest";
import usePostRequest from "../../../../customHooks/usePostRequest" 
import usePutRequest from "../../../../customHooks/usePutRequest " 
import Acordion from "../../../utils/Acordion";
import { getExam } from "../../../../actions/examsActions";

//formatos
const VIDEO_MIMETYPE = "video/webm;codec='vp8,opus'"
const AUDIO_MIMETYPE = "audio/mpeg"
const VIDEO_H = 640 // 1280  // 1920
const VIDEO_v = 480 // 720   // 1080

/**
 * Componente para enviar una respuesta o guardar una versión de la misma sin enviarla a codificación
 *
 * @class Answer
 */
export default function Answer({data:answer, examInfo, setExamInfo}) {
  var wordCount = (answer?.answer == '<p><br></p>' || answer?.answer == null) ? 0 : (answer?.answer.replace(/\s/g, "").length > 0 ? answer?.answer.trim().split(/\s+/g).length : 0);

  // Obtener la información de respuesta usando un hook personalizado para solicitudes GET
  const {data:linkInfo, isLoading, error, refetch} = useGetRequest(
    `answers/${answer.id}/multimedia_url?blob=${BLOB_ACCOUNT}&sip=${sessionStorage.getItem("ip_add")}`
  );

  const {
    data:responseData,
    sendRequest:updateAnswer
  } = usePatchRequest(
    `answers/${answer?.id}`
  );

  const {  sendRequest:sendBlobUploadResult  } = usePostRequest(
    `answers/${answer?.id}/get_blob_upload_result`
  );

  const { data:responseFinishAnswer, sendRequest:finishAnswer } = usePutRequest(
    `answers/${answer?.id}/finish`
  );

  const fileInputRef = useRef(null)

  const [state, setState] = useState({
    answer: answer.answer || '',
    progress:"",
    loading:false,
    saveEnabled: false,
    wordCountEnabled: wordCount < answer.examTestOptions.maxWordCount,
    sendEnabled: (answer.attachment || answer.file.url || !wordCount==0) ? true : false,
    showInstructions: false,
    wordCount: wordCount,
    noenabledFormat: examInfo.examTestOptions.exam.noTextFormat,
    haveFiles: false,
    haveMultimedia: false, 
    file: '',
    fileName:'',
    recordingVideo:false,
    recordingAudio:false,
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    }
  })

/*
  const modules = {
    toolbar: [
      [{'size': ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'align': []}],
      ['clean']
    ]
  };
*/
  // Definir los módulos de Quill según el estado `noenabledFormat`
  const modules = state.noenabledFormat ? { toolbar: null } : {
    toolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['clean']
    ]
  };

  console.log(examInfo.examTestOptions.exam.noTextFormat)

  const isMultimediaFile = (file) => {
    let isMmultimedia = false;
    try {
      var format = file.split(".")
    }
    catch (error) {
      console.log("No es formato valido")
    }
    if (ACCEPTED_MULTIMEDIA_FORMATS.includes(format[format.length -1])){
        isMmultimedia = true;
    }
    return isMmultimedia;
  }

  const onAnswerSubmit = (event) => {
    event.preventDefault();
    const file = fileForm();
    swal({
      title: '¿Estás seguro?',
      text: 'Tu respuesta será enviada tal y como está',
      type: 'warning',  
      showCancelButton: true,
      confirmButtonText: 'Si, enviar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
          answer.answer = state.answer;
          finishAnswer(file || {answer});  
      }
    });
  }

  const fileForm = () => {
    
    if (state.haveFiles || state.haveMultimedia) {
      const formData= new FormData();
      formData.append("answer[answer]", state.answer)
      formData.append("answer[attachment]", state.fileName)
      return formData;
    } else {
      return undefined;
    }
  }

  const onAnswerUpdate = () => {
    const file = fileForm();
    if (file || answer.answer != state.answer){
      answer.answer = state.answer;
      updateAnswer(file || {answer});  
    }
  }

  const onFileChange = (e) => {
    e.preventDefault();
    const fileInput = e.target;
    const multimediaContainer = AZURE_STORAGE_MULTIMEDIA_CONTAINER;
    const fileContainer = AZURE_STORAGE_FILE_CONTAINER;
    if (fileInput && fileInput.files[0]) {
      if (fileInput.files[0].type ===""||fileInput.files[0].type===undefined){
        swal(
          'Ups...',
          'Ocurrió un error el tipo de archivo no es soportado',
          'error'
        );
        fileInput.value=null;
      } else {
        let array=fileInput.files[0].name.split(".");
        const filename = answer.id + "-U-" + answer.userId + "."+array[array.length-1];
        if (isMultimediaFile(fileInput.files[0].name)){
          uploadAzureFiles(fileInput.files[0], filename, multimediaContainer)
        } else {
          uploadAzureFiles(fileInput.files[0], filename,fileContainer)
        }
        setState((prevState)=> {return ({
          ...prevState,
          saveEnabled: true,
          fileName: filename,
        })});
        
      }
    } else {
      setState((prevState)=> {return ({
        ...prevState,
        file: '',
      })});
    }
  }

  const onAnswerChange = (value, delta, source, editor) => {
    const hasAnyChar = editor.getText().replace(/\s/g, "").length > 0;
    setState((prevState)=> {return ({
      ...prevState,
      answer: hasAnyChar ? value : value,
    })});
    countWords(editor.getText());
  }

  const countWords = (text) => {
    const wordCount = text.replace(/\s/g, "").length > 0 ? text.trim().split(/\s+/g).length : 0;
    const words = wordCount < answer.examTestOptions.maxWordCount && wordCount > answer.examTestOptions.minWordCount;
    setState((prevState)=> {return ({
      ...prevState,
      wordCount: wordCount,
      sendEnabled: (!words && !answer?.attachment && !answer?.file.url ) ? false : true
    })});
  }

  const confirmUploadVideo =  (videoBlob) => {
    const container = AZURE_STORAGE_MULTIMEDIA_CONTAINER
    swal({
      title: '¿Estás seguro?',
      text: 'Quieres subir este video ',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, subir mi video',
      cancelButtonText: 'No '
    }).then((result) => {
      if (result.value) {
        var filename = answer.id + "-video-record.mp4" 
        uploadAzureFiles(videoBlob, filename , container)
        setState((prevState)=> {return ({
          ...prevState,
          admitFile: false,
          fileName: filename,
          recordingVideo: false,
        })});
      }
    })
  }

  const handleAudioStop =  (data) => {
    console.log(data);

    setState((prevState)=> {return ({
      ...prevState,
      audioDetails: data,
    })});
  }

  const confirmUploadAudio =  (audioFile) => {
    const container = AZURE_STORAGE_MULTIMEDIA_CONTAINER
    swal({
      title: '¿Estás seguro?',
      text: 'Quieres subir este archivo de audio ',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, subir mi archivo',
      cancelButtonText: 'No '
    }).then((result) => {
      if (result.value) {
        var filename = this.props.id + "-audio-record.mp3" 
        uploadAzureFiles(audioFile, filename , container)
        setState((prevState)=> {return ({
          ...prevState,
          admitFile: false,
          fileName: filename,
          recordingAudio: false,
        })});
      }
    });
  }

  const handleRest = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    setState((prevState)=> {return ({
      ...prevState,
      audioDetails: reset,
    })});
  }

  const reportStatus = message => {
    const status = document.getElementById("status");
    status.innerHTML += `${message}<br/>`;
    status.scrollTop = status.scrollHeight;
  }

  const uploadAzureFiles = async (file, filename, containerName) => {
    const { BlobServiceClient } = require('@azure/storage-blob');
    const blobSasUrl = AZURE_STORAGE_CONNECTION_STRING
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    try {
      let mimetype = 'application/octetstream'
      if (filename.includes('.mp3')) {mimetype= AUDIO_MIMETYPE}
      if (filename.includes('.mp4')) {mimetype = VIDEO_MIMETYPE}
      if (filename.includes('.webm')) {mimetype = VIDEO_MIMETYPE}
      const promises = [];
      const blockBlobClient = containerClient.getBlockBlobClient(filename);
      
      swal({
        title: "Cargando",
        showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading()
        },
      }); 

      setState((prevState)=> {return ({
        ...prevState,
        loading: true, 
        sendEnabled:false,
        saveEnabled:false,
      })});

      promises.push(blockBlobClient.uploadBrowserData(file, {
        blobHTTPHeaders: { blobContentType:mimetype },             // <-----------
        blockSize: 4 * 1024 * 1024, 
        concurrency: 20, 
        onProgress: (ev) => {
          setState((prevState)=> {return ({
            ...prevState,
            progress: (ev.loadedBytes/file.size).toFixed(2)*100,
          })});
      }, blobHTTPHeaders :{blobContentType:file.type} }));
      const promes_result = await Promise.all(promises);

      sendBlobUploadResult({result: "url" + " " + promes_result[0]._response.request.url + " status " + promes_result[0]._response.status})
      setState((prevState)=> {return ({
        ...prevState,
        sendEnabled: true,
        file: file,
        loading: false,
        progress: "Se cargó el archivo correctamente, puedes enviar tu respuesta",
      })});
      swal.close();
      swal({
        type: 'success',
        title: `Se subió el archivo exitosamente`,
        showConfirmButton: false,
        timer: 1000
      })

      // const file_info = fileForm();
      let file_info;
      if (state.haveFiles || state.haveMultimedia) {
        file_info = new FormData();
        file_info.append("answer[answer]", state.answer)
        file_info.append("answer[attachment]", filename)
      } else {
        file_info = undefined;
      }
      answer.answer = state.answer;
      updateAnswer(file_info || {answer});
    } catch (error) {
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error, intente nuevamente',
      } )
      reportStatus(error.message);
    }
  }

  useEffect(() => {
    let timer = setInterval(() => {
      // Solo guarda el contenido si es diferente al anterior
      if (state.answer !== answer.answer) {
        onAnswerUpdate();
      }
    }, 60000); // Guarda cada minuto
    return () => {  
      return clearInterval(timer)
    };
  }, [state.answer]);

  useEffect(() => {
    let mounted = true;

    if (responseData && responseData != null) {
       setExamInfo({...responseData})
      mounted && refetch()
    }

    if (responseFinishAnswer) {
      setExamInfo({...responseFinishAnswer})
      swal({
        type: 'success',
        title: 'Tu respuesta fue enviada',
        showConfirmButton: false,
        timer: 1200
      });
    }

  }, [responseData, responseFinishAnswer]);
 
  useEffect(() => {
    let x = document.getElementsByClassName("ql-editor");
    for (let i = 0; i < x.length; i++) {
      x[i].onpaste = (e) => e.preventDefault();
      x[i].oncopy = (e) => e.preventDefault();
      x[i].oncut=(e) => e.preventDefault(); 
      x[i].ondrag=(e) => e.preventDefault(); 
      x[i].ondrop=(e) => e.preventDefault();
  }
  }, []);

  return (
    <div id="text-editor-container">
      {answer.attachment && (
        <Acordion title="archivo actual">
          {answer.attachment && isMultimediaFile(answer.attachment)
            ? <AnswerMultimediaPlayer id={answer.id} 
              filename={answer.attachment} 
              url={linkInfo?.url}
              forAnswers={true}
              onReloadVideo={refetch}
              />
            :<EmbedFile answer={answer}/>
          }
        </Acordion>
      )}

      <div>
        <div>
          <small className={state.wordCountEnabled ? ("") : ("text-danger")} >
            Contador de palabras: {state.wordCount}/(min:{answer.examTestOptions.minWordCount}-max:{answer.examTestOptions.maxWordCount})
          </small>
          <br></br>
          <small>Guardado {moment(answer.updatedAt).fromNow()} </small> 
          <form onSubmit={onAnswerSubmit}>
            <div className="btn-group ">
              <button
                onClick={onAnswerUpdate}
                type="button"
                className="btn btn-primary"
              >
                <i data-tip="Guardar" className="fa fa-floppy-o fa-lg"/>
              </button>
              {answer.admitFile &&
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prevState)=> {return ({
                      ...prevState,
                      haveFiles: !state.haveFiles,
                    })});
                  }}
                  className="btn btn-primary"
                >
                    {state.haveFiles? (
                      <i className="fa fa-caret-up fa-lg"/>
                    ) : (
                      <i data-tip="Adjuntar archivo" aria-hidden="true"className="fa fa-file-text-o fa-lg"/>
                    )}
                </button>
              }
              {answer.admitMultimedia &&
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prevState)=> {return ({
                      ...prevState,
                      haveMultimedia: !state.haveMultimedia,
                    })});
                  }}
                  className="btn btn-primary"
                >
                  {state.haveMultimedia? (
                    <i className="fa fa-caret-up fa-lg"/>
                  ) : (
                    <i data-tip="Grabar archivo" aria-hidden="true"className="fa fa-film fa-lg"/>
                  )}
                </button>
              }
              <button type="submit" disabled={!state.sendEnabled} className="btn btn-primary pull-right">
                Enviar
              </button>
            </div>
            <div>
              {state.haveFiles && 
                <div className="row">
                  <div className="col-md-6">  
                    <label>Adjuntar archivo</label>
                    <br/>
                    <input
                      type="file"
                      name="file"
                      ref={fileInputRef}
                      id="file-input"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              }
              { state.haveMultimedia &&
                <div className="row">
                  <div className="col-md-6">  
                    <label>Grabar archivo</label>
                    <br></br>
                    <button 
                      onClick={(e) => {
                        e.preventDefault();
                        setState((prevState)=> {return ({
                          ...prevState,
                          recordingVideo: !state.recordingVideo,
                        })});
                      }}
                      disabled={state.recordingAudio}
                      className="btn btn-primary"
                    >
                      {state.recordingVideo? (
                          <i className="fa fa fa-times fa-lg"/>
                        ) : (
                          <i data-tip="Grabar un video" aria-hidden="true" className="fa fa-video-camera fa-lg"/>
                      )}
                    </button>
                      {/* <button 
                        onClick={(e) => {
                          e.preventDefault();
                          setState((prevState)=> {return ({
                            ...prevState,
                            recordingAudio: !state.recordingAudio,
                          })});
                        }}
                        disabled={state.recordingVideo}
                        className="btn btn-primary"
                      >
                        {state.recordingAudio? (
                            <i className="fa fa fa-times fa-lg"/>
                          ) : (
                            <i data-tip="Grabar un video" aria-hidden="true" className="fa fa-microphone fa-lg"/>
                        )}
                      </button> */}
                  </div>  
                </div>
              }
            </div>
            <div>
              <p id="status"  />
              <div className="col-md-12">
                {state.loading? <div><h3>Progreso: {state.progress} %</h3></div>:<div> <h3>{state.progress} </h3></div>}
              </div>
            </div>
            {state.recordingAudio || state.recordingVideo? 
              <div></div>:
              <div id="quill-container"  autoComplete="off" spellCheck="false">
                <ReactQuill
                    className="editor"
                    value={state.answer? state.answer:" "}
                    onChange={onAnswerChange}
                    modules={modules}
                />                                      
              </div>
            }
          </form>
        </div>
      </div>
      <div  className="row col-md-12">
        {state.recordingVideo? 
          <div className="multimedia-recorder">
            <VideoRecorder 
              onRecordingComplete={confirmUploadVideo} 
              timeLimit={600000}
              mimetype={VIDEO_MIMETYPE}
              constraints={{
                audio: true,
                video: {
                  height: { exact: VIDEO_v, ideal: VIDEO_v,max:VIDEO_v },
                  width: { exact: VIDEO_H, ideal: VIDEO_H,max:VIDEO_H },
                  // aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                  resizeMode: "crop-and-scale"
                }
              }}      
            />
          </div>:
          <div></div>
        }
        {state.recordingAudio?
          <div className="multimedia-recorder"><Recorder
          record={true}
          title={"Nueva grabación"}
          audioURL={state.audioDetails.url || ''}
          showUIAudio
          handleAudioStop={data => handleAudioStop(data)}
          handleAudioUpload={data => confirmUploadAudio(data)}
          handleRest={handleRest} 
          /></div>
          :
          <div></div>
        }
      </div>
      <br></br>
    </div>
  );
}
