import React, { useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap"
import { APWINDOWS_USERS_TEMPL_DOC, APWINDOWS_TEMPL_DOC } from "../../../actions/constants"
import swal from "sweetalert2"
import usePatchRequest from "../../../customHooks/usePatchRequest"
import Loading from "../../Loading"

const AddByAttachment = (props) => {
  const id = props.match.params.id

	const styles = {
		main: {
			display: 'flex', 
			minHeight: '70vh',
			flexFlow: 'column nowrap',
			justifyContent: 'space-between',
		},
		box_style: {
			borderRadius: "15px",
			borderColor: "#E5E4E4",
			borderStyle: "solid",
			borderWidth: "thin",
			padding: "10px 0px",
			marginBottom: "9px"
		},
		noMargin: {
			margin: 0
		}
	}

	const fileCrearte = useRef(null)
	const fileAsociate = useRef(null)
  const [currentTab, setCurrentTab] = useState(1)

  const { sendRequest:addByAttachment, loading:addByAttachmentLoading, data:addByAttachmentData, error: addByAttachmentError } = usePatchRequest(`apwindows/${id}/edit_by_attachment`)

	const { sendRequest:createByAttachment, loading:createAttachmentLoading, data:createAttachmentData, error: createAttachmentError } = usePatchRequest(`apwindows/${id}/create_by_attachment`)

	const renderError = (error) => {
    if (!error.response) return (<li className="has-error">{error.message}</li>)
    let {data} = error?.response
    const isArray = Array.isArray(data)
    if (typeof data !== 'object'  && !isArray) return (<li className="has-error">{error.message}</li>)
    if (isArray) data = data[0]
		console.log('data: ', data);
    return (<ul className="text-danger">
      {data.exception ? (
        <li key={`${data.error}_${data.status}`}>{data.exception}</li>
      ) : Object.keys(data).map((field, i) => (<React.Fragment key={`${field}_${i}`}>
        {data[field]?.map((reason, i) => (
          <li key={`${reason}_${i}`}>{reason}</li>
        ))}
      </React.Fragment>))}
    </ul>)
  }

	const successUpdate = () => {
    const title =  `ventanas actualizadas`
    swal({
      type: 'success',
      title,
      showConfirmButton: false,
      timer: 1000
    }).then(()=> props.history.push(`/exams/${id}/apwindows`))
  }

	// eventos
  const addStudents = (e) => {
    e.preventDefault()
    if (currentTab === 1) {
			const file = fileCrearte.current.files[0]
      const valuesCleaned = file.name.split('.').pop() === 'xlsx';
      const data = new FormData()
      data.append('windows_list', file)
      if (valuesCleaned) {
        console.log('file: ', data);
        createByAttachment(data)
      } else {
        swal(
          'Un momento',
          'El archivo debe ser con formato .xlsx (Excel 2007 o posterior)',
          'warning'
        );
        return null;
      }
    }
    if (currentTab === 2) {
      const file = fileAsociate.current.files[0]
      const valuesCleaned = file.name.split('.').pop() === 'xlsx';
      const data = new FormData()
      data.append('students_list', file)
      if (valuesCleaned) {
        console.log('file: ', data);
        addByAttachment(data)
      } else {
        swal(
          'Un momento',
          'El archivo debe ser con formato .xlsx (Excel 2007 o posterior)',
          'warning'
        );
        return null;
      }

    }
  }

	// renders
  if (addByAttachmentLoading || createAttachmentLoading) return (<Loading />)
  if (addByAttachmentData || createAttachmentData) return <>{successUpdate()}</>

	return (<main style={styles.main}>
		<form onSubmit={addStudents}>
			<Tabs activeKey={currentTab} onSelect={(key)=> setCurrentTab(key)} defaultActiveKey={currentTab} id="students">
				<Tab eventKey={1} title="Subir ventanas" style={{ padding: '20px' }}>
					<h2>Crear ventanas</h2>
					<p>A continuación, podrá crear ventanas de aplicación por medio de un archivo Excel(.xlsx) en la opción “Nueva lista de ventanas” se crearán ventanas de aplicación con el nombre, fecha de inicio, hora de inicio, la fecha de cierre y hora de cierre (columna nombre, columna fecha inicio, columna hora inicio, columna fecha fin, columna hora fin).
					</p>
					<p>Ejemplo:<br></br></p>
					<table className="table">
						<thead >
							<td><strong>nombre</strong></td>
							<td><strong>fecha inicio</strong></td>
							<td><strong>hora inicio</strong></td>
							<td><strong>fecha fin</strong></td>
							<td><strong>hora fin</strong></td>
						</thead>
						<tbody>
							<tr>
								<td>ventana 1</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
							</tr>
							<tr>
								<td>ventana 2</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
							</tr>
							<tr>
								<td>ventana 3</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
								<td>DD/MM/YYYY</td>
								<td>HH:mm</td>
							</tr>
						</tbody>
					</table>
					<a href={APWINDOWS_TEMPL_DOC} target="_blank" rel="noopener noreferrer">Descargar plantilla</a>
					<h3>Nueva lista de ventanas</h3>
					<div>
						<h4>Ventanas</h4>
							<label>Archivo de ventanas</label>
							<br/>
					</div>
					<div className="row">
						<div className="col-md-3 col-xs-6">
							<input ref={fileCrearte} type="file" name="students_list" id="students-list-input" required={currentTab === 1} />
						</div>
					</div>
				</Tab>
				<Tab eventKey={2} title="Asociar estudiantes" style={{ padding: '20px' }}>
					<h2>Asociar estudiantes</h2>
					<p> A continuación, podrá asociar estudiantes a ventanas de aplicación en la opción “nueva lista de estudiantes” se asociarán los correos con ventanas de aplicación de una lista en Excel (columna email y columna apwindow).
					</p>
					<p>Ejemplo:<br></br></p>
					<table className="table">
						<thead >
							<td><strong>email</strong></td>
							<td><strong>apwindow</strong></td>
						</thead>
						<tbody>
							<tr>
								<td>estudiante@correo.edu.co</td>
								<td>ventana de aplicación</td>
							</tr>
							<tr>
								<td>estudiante@correo.edu.co</td>
								<td>ventana de aplicación</td>
							</tr>
							<tr>
								<td>estudiante@correo.edu.co</td>
								<td>ventana de aplicación</td>
							</tr>
						</tbody>
					</table>
					<a href={APWINDOWS_USERS_TEMPL_DOC} target="_blank" rel="noopener noreferrer">Descargar plantilla</a>
					<h3>Nueva lista de estudiantes</h3>
					<div>
						<h4>Estudiantes</h4>
							<label>Archivo de estudiantes</label>
							<br/>
					</div>
					<div className="row">
						<div className="col-md-3 col-xs-6">
							<input ref={fileAsociate} type="file" name="students_list" id="students-list-input" required={currentTab === 2} />
						</div>
					</div>
				</Tab>
			</Tabs>
			<div style={{margin: '2rem'}}>
				{(createAttachmentError || addByAttachmentError) && renderError(createAttachmentError || addByAttachmentError)}
			</div>
			<section 
				style={{
					display: 'flex',
					alignItems: 'center',
					flexFlow: 'row nowrap',
					justifyContent: 'space-between',
				}}
			>
				<button
					onClick={() => props.history.push(`/exams/${id}/apwindows`)}
					type="button"
					className="btn btn-danger">
					Volver
				</button>
				<button
					type="submit"
					className="btn btn-info">
					{currentTab === 1 ? 'Crear Ventanas' : 'Asociar Estudiantes'}
				</button>
			</section>
		</form>
	</main>)
}

export default AddByAttachment