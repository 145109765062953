import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';


// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_DISCREPANCY_CRIT_COD_REPORTS = 'GET_DISCREPANCY_CRIT_COD_REPORTS';

// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_DISCREPANCY_COD_REPORTS = 'GET_DISCREPANCY_COD_REPORTS';

// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_DISCREPANCY_CRIT_REPORTS = 'GET_DISCREPANCY_CRIT_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_CODIFICATION_PROGRESS_REPORTS = 'GET_CODIFICATION_PROGRESS_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_CODIFICATION_STATE_REPORTS = 'GET_CODIFICATION_STATE_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_CODIFICATION_COMMENT_REPORTS = 'GET_CODIFICATION_COMMENT_REPORTS';

// Actualiza los datos de comentarios (codificación) del banco de respuestas
export const GET_CODIFICATION_COMMENT_BANK_REPORTS = 'GET_CODIFICATION_COMMENT_BANK_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_ANSWERS_PROGRESS_REPORTS = 'GET_ANSWERS_PROGRESS_REPORTS';

// Actualiza los datos de avance de las respuestas de cuestionario
export const GET_MC_ANSWERS_PROGRESS_REPORTS = 'GET_MC_ANSWERS_PROGRESS_REPORTS';

// Actualiza los datos de avance de las respuestas de cuestionario
export const GET_MC_ANSWERS_STUDENT_SUCCESS_REPORTS = 'GET_MC_ANSWERS_STUDENT_SUCCESS_REPORTS';
export const GET_MC_ANSWERS_DISTRIBUTION_REPORTS = 'GET_MC_ANSWERS_DISTRIBUTION_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_EXAM_PROGRESS_REPORTS = 'GET_EXAM_PROGRESS_REPORTS';

// Actualiza los datos de avance de  revisión de la pregunta actual en el estado de la aplicación
export const GET_REVIEWERS_PROGRESS_REPORTS = 'GET_REVIEWERS_PROGRESS_REPORTS';

// Actualiza los datos de resumen del avance de  revisión de la pregunta actual en el estado de la aplicación
export const GET_REVIEW_PROGRESS_SUMMARY_REPORTS = 'GET_REVIEW_PROGRESS_SUMMARY_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_COURSE_REPORTS = 'GET_COURSE_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_ANSWER_BANK_REPORTS = 'GET_ANSWER_BANK_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_AGREGATE_REPORTS = 'GET_AGREGATE_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_DYNAMIC_REPORT = 'GET_DYNAMIC_REPORT';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_STUDENT_HISTORIC_REPORTS = 'GET_STUDENT_HISTORIC_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_COURSE_HISTORIC_REPORTS = 'GET_COURSE_HISTORIC_REPORTS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_ACEPTED_REPORT = 'GET_ACEPTED_REPORT';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_REPORTS = 'GET_EXAM_TEST_REPORTS';


export const GET_ANSWERS_REPORTS = 'GET_ANSWERS_REPORTS';

/**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export getDiscrepancyCodReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDiscrepancyCodReports(exam_test_option_id,assignation_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_discrepancy_cod${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_DISCREPANCY_COD_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene los reportes de discrepancia por codificador y criterio de un examen y una asignacion.
 *
 * @export getDiscrepancyCritCodReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDiscrepancyCritCodReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_discrepancy_crit_cod${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_DISCREPANCY_CRIT_COD_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
 
  /**
 * Obtiene los reportes de discrepancia por criterio de un examen y una asignacion.
 *
 * @export getDiscrepancyCritReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDiscrepancyCritReports(exam_test_option_id,assignation_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_discrepancy_crit${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_DISCREPANCY_CRIT_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  
/**
 * Obtiene los reportes de progreso de codificación de un examen y una asignación.
 *
 * @export getCodificactionProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCodificationProgressReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_codification_progress${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_CODIFICATION_PROGRESS_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  /**
 * Obtiene los reportes de progreso de codificación de un examen y una asignación.
 *
 * @export getReviewersProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getReviewersProgressReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_reviewers_progress${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_REVIEWERS_PROGRESS_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


  /**
 * Obtiene los reportes de progreso de codificación de un examen y una asignación.
 *
 * @export getReviewProgressSummaryReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getReviewProgressSummaryReports(exam_test_option_id,assignation_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_review_progress_summary${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_REVIEW_PROGRESS_SUMMARY_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


  /**
 * Obtiene los reportes de estado de codificación de un examen y una asignación.
 *
 * @export getCodificactionStateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCodificationStateReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_codification_state${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_CODIFICATION_STATE_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

/**
 * Obtiene los reportes de estado de codificación de un examen y una asignación.
 *
 * @export getCodificactionCommentReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCodificationCommentReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_codification_comment${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_CODIFICATION_COMMENT_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
* @export getCodificactionCommentBankReports
* @param {*} exam_test_option_id : El id del examen.
* @param {*} assignation_id: El id de la asignacion.
* @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
* @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
*                                           La función debe recibir por parámetro el error generado.
* @returns Función de manejo de error o éxito de la petición.
*/
export function getCodificactionCommentBankReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
 const request = axios.get(`${ROOT_URL}/reports/get_codification_comment_bank${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

 return (dispatch) => {
   request.then(({data}) => {
     dispatch({
       type: GET_CODIFICATION_COMMENT_BANK_REPORTS,
       payload: data
     });
     successCallback();
   }).catch((error) => {
     if (!isUnauthorizedError(error, dispatch)) {
       errorCallback(error);
     }
   });
 }
}
/**
 * Obtiene los reportes de estado de codificación de un examen y una asignación.
 *
 * @export getAnswerBankReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getAnswerBankReports(exam_test_option_id,assignation_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_answer_bank_reports${pagination}&exam_test_option_id=${exam_test_option_id}&assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_BANK_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  /**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getAnswersProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswersProgressReports(exam_test_option_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_answers_progress${pagination}&exam_test_option_id=${exam_test_option_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ANSWERS_PROGRESS_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getMcAnswersProgressReports
 * @param {*} exam_mc_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getMcAnswersProgressReports(exam_mc_questionary_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_mc_answers_progress${pagination}&exam_mc_questionary_id=${exam_mc_questionary_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWERS_PROGRESS_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene las distribución de respuestas por preguntas .
 *
 * @export getMcAnswersDistributionReports
 * @param {*} exam_mc_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getMcAnswersDistributionReports(exam_mc_questionary_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_mc_answers_distribution${pagination}&exam_mc_questionary_id=${exam_mc_questionary_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWERS_DISTRIBUTION_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


  /**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getExamProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamProgressReports(exam_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_exam_progress${pagination}&exam_id=${exam_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_PROGRESS_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
  /**
 * Obtiene los reportes de curso de un examen y curso
 *
 * @export getCourseReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCourseReports(exam_test_option_id,course_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/reports/get_course_reports${pagination}&exam_test_option_id=${exam_test_option_id}&course_id=${course_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_COURSE_REPORTS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
 
  /**
 * Obtiene los reportes de curso de un examen y curso
 *
 * @export getAgregateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAgregateReports(report_type, exam_test_option_id, agregate_id,  pagination,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_agregate_reports${pagination}&report_type=${report_type}&exam_test_option_id=${exam_test_option_id}&agregate_id=${agregate_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_AGREGATE_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene los reportes de curso de un examen y curso
 *
 * @export getAgregateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getStudentHistoricReports(super_matrix_id, user_id,  pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_student_historic_reports${pagination}&super_matrix_id=${super_matrix_id}&user_id=${user_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_STUDENT_HISTORIC_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene los reportes de curso de un examen y curso
 *
 * @export getAgregateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getCourseHistoricReports(super_matrix_id, course_id,  pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_course_historic_reports${pagination}&super_matrix_id=${super_matrix_id}&course_id=${course_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_HISTORIC_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de curso de un examen y curso
 *
 * @export getCoursePeriodHistoricReports
 * @param {*} super_matrix_id : Id de la matriz.
 * @param {*} course_id : Id del curso.
 * @param {*} period : Valor del periodo.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getCoursePeriodHistoricReports(super_matrix_id, course_id, period_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_course_period_historic_reports${pagination}&super_matrix_id=${super_matrix_id}&course_id=${course_id}&period_id=${period_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_HISTORIC_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

   /**
 * Obtiene los datos para hacer un reporte dinamico
 *
 * @export getDynamicReport
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} filters : Los filtros del estado del reporte dinamico
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getDynamicReport(report_type, exam_test_option_id, filters,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/dynamic_reports/get_dynamic_report?filters=${filters}&report_type=${report_type}&exam_test_option_id=${exam_test_option_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_DYNAMIC_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
} 
  /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateDiscrepancyCodReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateDiscrepancyCodReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_discrepancy_cod`,{exam_test_option_id: exam_test_option_id,
                                                                              assignation_id: assignation_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateDiscrepancyCritCodReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateDiscrepancyCritCodReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_discrepancy_crit_cod`,{exam_test_option_id: exam_test_option_id,
                                                                              assignation_id: assignation_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

    /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateDiscrepancyCritCodReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateDiscrepancyCritReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_discrepancy_crit`,{exam_test_option_id: exam_test_option_id,
                                                                              assignation_id: assignation_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateCodificationProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateCodificationProgressReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_codification_progress`,{exam_test_option_id: exam_test_option_id,
                                                                              assignation_id: assignation_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
   /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateReviewesProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateReviewersProgressReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_reviewers_progress`,{exam_test_option_id: exam_test_option_id,
                                                                            assignation_id: assignation_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateReviewProgressSummaryReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateReviewProgressSummaryReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_review_progress_summary`,{exam_test_option_id: exam_test_option_id,
                                                                            assignation_id: assignation_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
   /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateCodificationStateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateCodificationStateReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_codification_state`,{exam_test_option_id: exam_test_option_id,
                                                                              assignation_id: assignation_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

/**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateCodificationCommentReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateCodificationCommentReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_codification_comment`,{exam_test_option_id: exam_test_option_id,
                                                                            assignation_id: assignation_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateCodificationCommentReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateCodificationCommentBankReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_answer_bank_codification_comment`,{exam_test_option_id: exam_test_option_id,
                                                                            assignation_id: assignation_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateAnswerBankReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function generateAnswerBankReports(exam_test_option_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_answer_bank_reports`,{exam_test_option_id: exam_test_option_id,
                                                                            assignation_id: assignation_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


    /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateAnswersProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateAnswersProgressReports(exam_test_option_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_answers_progress`, {exam_test_option_id: exam_test_option_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

     /**
 * Obtiene los reportes de avance de repuestas de cuestionario
 *
 * @export generateMcAnswersProgressReports
 * @param {*} exam_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateMcAnswersProgressReports(exam_mc_questionary_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_mc_answers_progress`, {exam_mc_questionary_id: exam_mc_questionary_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
    /**
 * Obtiene los reportes de distribución de repuestas de cuestionario
 *
 * @export generateMcAnswersProgressReports
 * @param {*} exam_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
     export function generateMcAnswersDistributionReports(exam_mc_questionary_id, successCallback = () => {}, errorCallback = (error) => {}) {
      const request = axios.patch(`${ROOT_URL}/reports/generate_mc_answers_distribution`, {exam_mc_questionary_id: exam_mc_questionary_id}, getAuthHeaders());
    
      return (dispatch) => {
        request.then(({data}) => {
          dispatch({
            type: GET_ACEPTED_REPORT,
            payload: data
          });
          successCallback();
        }).catch((error) => {
          if (!isUnauthorizedError(error, dispatch)) {
            errorCallback(error);
          }
        });
      }
    }
  
    /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateExamProgressReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} assignation_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateExamProgressReports(exam_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_exam_progress`, {exam_id: exam_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  
    /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateCourseReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} course_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateCourseReports(exam_test_option_id,course_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.patch(`${ROOT_URL}/reports/generate_course_reports`, {exam_test_option_id: exam_test_option_id,
                                                                              course_id: course_id}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_ACEPTED_REPORT,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

    /**
 * Obtiene los reportes de discrepancia por codificador de un examen y una asignacion.
 *
 * @export generateAgregateReports
 * @param {*} exam_test_option_id : El id del examen.
 * @param {*} course_id: El id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateAgregateReports(report_type,exam_test_option_id,agregate_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_agregate_reports`, {report_type: report_type, exam_test_option_id: exam_test_option_id,
                                                                                agregate_id: agregate_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


     /**
 * genera los reportes de estudiantes con porcentaje de respuestas correctas para un cuestionario
 *
 * @export generateMcAnswersStudentSuccessReports
 * @param {*} exam_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
      export function generateMcAnswersStudentSuccessReports(exam_mc_questionary_id, successCallback = () => {}, errorCallback = (error) => {}) {
        const request = axios.patch(`${ROOT_URL}/reports/generate_mc_answers_student_success`, {exam_mc_questionary_id: exam_mc_questionary_id}, getAuthHeaders());
      
        return (dispatch) => {
          request.then(({data}) => {
            dispatch({
              type: GET_ACEPTED_REPORT,
              payload: data
            });
            successCallback();
          }).catch((error) => {
            if (!isUnauthorizedError(error, dispatch)) {
              errorCallback(error);
            }
          });
        }
      }

      /**
 * genera los reportes de estudiantes con porcentaje de respuestas correctas para un cuestionario
 *
 * @export generateStudentHistoricReports
 * @param {*} exam_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
       export function generateStudentHistoricReports(super_matrix_id, user_id,  successCallback = () => {}, errorCallback = (error) => {}) {
        const request = axios.patch(`${ROOT_URL}/reports/generate_student_historic_reports`, {super_matrix_id: super_matrix_id, user_id: user_id}, getAuthHeaders());
      
        return (dispatch) => {
          request.then(({data}) => {
            dispatch({
              type: GET_ACEPTED_REPORT,
              payload: data
            });
            successCallback();
          }).catch((error) => {
            if (!isUnauthorizedError(error, dispatch)) {
              errorCallback(error);
            }
          });
        }
      }
  
       /**
 * genera los reportes de estudiantes con porcentaje de respuestas correctas para un cuestionario
 *
 * @export generateCourseHistoricReports
 * @param {*} exam_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
        export function generateCourseHistoricReports(super_matrix_id, course_id,  successCallback = () => {}, errorCallback = (error) => {}) {
          const request = axios.patch(`${ROOT_URL}/reports/generate_course_historic_reports`, {super_matrix_id: super_matrix_id, course_id: course_id}, getAuthHeaders());
        
          return (dispatch) => {
            request.then(({data}) => {
              dispatch({
                type: GET_ACEPTED_REPORT,
                payload: data
              });
              successCallback();
            }).catch((error) => {
              if (!isUnauthorizedError(error, dispatch)) {
                errorCallback(error);
              }
            });
          }
        }


  /**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getMcAnswersStudentSuccessReports
 * @param {*} exam_mc_questionary_id : El id del cuestionario de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getMcAnswersStudentSuccessReports(exam_mc_questionary_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_mc_answers_student_success${pagination}&exam_mc_questionary_id=${exam_mc_questionary_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MC_ANSWERS_STUDENT_SUCCESS_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
* genera los reportes de estudiantes con porcentaje de respuestas correctas para un cuestionario
*
* @export generateCoursePeriodHistoricReports
* @param {*} super_matrix_id : Id de la matriz histórica
* @param {*} course_id : código del curso sección periodo.
* @param {*} semester : semestre del curso sección periodo
* @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
* @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
* 
* @returns Función de manejo de error o éxito de la petición.
*/
export function generateCoursePeriodHistoricReports(super_matrix_id, course_id, semester,successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_course_period_historic_reports`, {super_matrix_id: super_matrix_id, course_id: course_id, period_id: semester}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
* genera los reportes de un test de examen con porcentaje de respuestas correctas para un cuestionario
*
* @export generateExamReports
* @param {*} super_matrix_id : Id de la matriz histórica
* @param {*} course_id : código del curso sección periodo.
* @param {*} semester : semestre del curso sección periodo
* @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
* @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
* 
* @returns Función de manejo de error o éxito de la petición.
*/
export function generateExamTestReports(exam_test_option_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_examTest_reports`, {exam_test_option_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de una pregunta de un examen
 * @export getExamTestReports
 * @param {*} exam_test_option_id : El id del de la pregunta asociada con el examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestReports(exam_test_option_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_exam_test_reports${pagination}&exam_test_option_id=${exam_test_option_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Genera los reportes de respuestas de un examen
 * @export generateAnswersReport
 * @param {*} data : objeto en el que van los parametros de busqueda del reporte.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function generateAnswersReport(data, successCallback = () => {}, errorCallback = () => {}) {
  const request = axios.patch(`${ROOT_URL}/reports/generate_answers_reports`, {data}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ACEPTED_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los reportes de respuestas de un examen
 * @export getAnswersReport
 * @param {*} exam : examen del reporte
 * @param {*} exam_test_option_id : preguntas del reporte
 * @param {*} pagination : paginacion
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswersReport(exam, exam_test_option_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/reports/get_answers_reports${pagination}&exam=${exam}&exam_test_option_id=[${exam_test_option_id.join(', ')}]`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWERS_REPORTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}