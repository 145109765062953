import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';


// Constantes para el manejo de las matrices de codificación.

// Actualiza las matrices en el estado de la aplicación.
export const GET_SUPER_MATRICES = 'GET_SUPER_MATRICES';

// Actualiza la matriz actual en la aplicación.
export const GET_SUPER_MATRIX = 'GET_SUPER_MATRIX';

// Eliminación de la matriz dada del estado de la aplicación.
export const DELETE_SUPER_MATRIX = 'DELETE_SUPER_MATRIX';

// Consulta de estudiantes de una super matriz
export const GET_SUPER_MATRIX_USERS = 'GET_SUPER_MATRIX_USERS'

// Consulta las matrices que tienen asociada como matriz de reporte histórico a esta que consulta 
export const GET_SUPER_MATRIX_ASSOCIATED_MATRICES = 'GET_SUPER_MATRIX_ASSOCIATED_MATRICES'

//setea la matriz plantilla a utilizar en los reportes de matriz histórico
export const SET_MATRIX_TEMPLATE = 'SET_MATRIX_TEMPLATE'

/**
 * Obtiene las matrices de codificación disponibles.
 *
 * @export getGradingMatrices
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=(data) => {}] : Función a llamar en caso de petición exitosa.
 *                                             La función debe recibir por parámetro los datos de la respuesta exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getSuperMatrices(pagination, successCallback = (data) => {}, errorCallback = (error) => {}) {
    if (pagination===undefined){
      pagination =""
    }
    const request = axios.get(`${ROOT_URL}/super_matrices${pagination}`, getAuthHeaders());
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_SUPER_MATRICES,
          payload: data
        });
        successCallback(data);
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

  
/**
 * Obtiene la matriz con el id dado por parámetro.
 *
 * @export getSuperMatrix
 * @param {*} id : El id de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getSuperMatrix(id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/super_matrices/${id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_SUPER_MATRIX,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  

/**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export updateSuperMatrix
 * @param {*} super_matrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateSuperMatrix(id, super_matrix, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/super_matrices/${id}`, {super_matrix: super_matrix}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SUPER_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export updateSuperMatrix
 * @param {*} super_matrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateRegistersSuperMatrix(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/super_matrices/${id}/update_registers`, {},getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SUPER_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
  
/**
 * Elimina la matriz de codificación con el id dado por parámetro.
 *
 * @export deleteSuperMatrix
 * @param {*} id : EL id de la matriz
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteSuperMatrix(id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.delete(`${ROOT_URL}/super_matrices/${id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(() => {
        dispatch({
          type: DELETE_SUPER_MATRIX,
          payload: id
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }


  /**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export createSuperMatrix
 * @param {*} gradingMatrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createSuperMatrix(superMatrix, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/super_matrices`, {super_matrix: superMatrix}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SUPER_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene los usuarios de la aplicación.
 *
 * @export getSuperMatrixStudents
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getSuperMatrixStudents(id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/super_matrices/${id}/get_students${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SUPER_MATRIX_USERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene las matrices que tienen asociada como matriz de reporte histórico a esta que consulta
 *
 * @export getAssociatedMatrices
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAssociatedMatrices(id, successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/super_matrices/${id}/get_associated_matrices`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SUPER_MATRIX_ASSOCIATED_MATRICES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Setea la matriz que utilizará la matriz histórica como plantilla
 *
 * @export updateMatrixTemplate
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateMatrixTemplate(id, template_values, successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.post(`${ROOT_URL}/super_matrices/${id}/set_matrix_template`, {template_values: template_values}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SET_MATRIX_TEMPLATE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}