import { GET_ANSWER, REMOVE_CURRENT_ANSWER, GET_MULTIMEDIA_URL } from '../actions/answersActions';

/**
 * Manejo de la parte del estado correspondiente a la respuesta actual.
 * Permite la actualización de la respuesta actual y eliminar la misma.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a la respuesta actual)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = null, action) {
  switch(action.type) {
    case GET_MULTIMEDIA_URL:
      const url= action.payload.url;
      return { ...state, url: url };
    case GET_ANSWER:
      return action.payload;
    case REMOVE_CURRENT_ANSWER:
      return null;
    default:
      return state;
  }
}
