import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';


export const GET_LABELS = 'GET_LABELS';

export const GET_LABEL = 'GET_LABEL';

export const EDIT_LABEL = 'EDIT_LABEL';

export const DELETE_LABEL = 'DELETE_LABEL';

export const CREATE_LABEL = 'CREATE_LABEL';

export const RESET = 'RESET';

/**
 * Obtiene todas las ventanas de aplicación de un examen en la aplicación.
 *
 * @export GET_LABELS
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getLabelsByMatrix(idMatrix, successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/labels/${idMatrix}/byMatrix`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_LABELS,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function getLabels(successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/labels`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: GET_LABELS,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}
export function getLabel(id, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.get(`${ROOT_URL}/labels/${id}`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: GET_LABEL,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function createLabel(data, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.post(`${ROOT_URL}/labels`, data, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: CREATE_LABEL,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function editLabel(id, data, successCallback = () => {}, errorCallback = (error) => {}) {
	const request = axios.put(`${ROOT_URL}/labels/${id}`, data, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: EDIT_LABEL,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function replaceLabel(id, data, successCallback = () => {}, errorCallback = (error) => {}) {
	const request = axios.put(`${ROOT_URL}/labels/${id}/replace`, data, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: EDIT_LABEL,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function deleteLabel(id, successCallback = () => {}, errorCallback = (error) => {}) {
	const request = axios.delete(`${ROOT_URL}/labels/${id}`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: DELETE_LABEL,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function resetState() {
	return (dispatch) => {
		dispatch({
			type: RESET,
			payload: undefined
		});
	}
}