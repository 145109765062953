import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import moment from 'moment/moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ROOT_URL } from '../../actions/constants';
import { getStudentHistoricReports, generateStudentHistoricReports} from '../../actions/reportsActions';
import ReportList from './ReportList';
import { FORBIDDEN_STATUS_CODE,REPORT_PAGINATION } from '../../actions/constants';
import _ from 'lodash';
import {Chart} from 'chart.js'
import html2pdf from 'html2pdf.js';
import IconTooltip from '../utils/IconTooltip';


/**
 * Componente para mostrar y descargar el reporte de progreso de respuestas de un examen
 *
 * @class ReportMockups
 * @extends {Component}
 */
class StudentHistoricReport extends Component {
  constructor(props) {
    super(props);
    this.downloadXls = this.downloadXls.bind(this);
    this.onReloadReport = this.onReloadReport.bind(this);
    this.onPaginateReport = this.onPaginateReport.bind(this);
    this.onGenerateReportSubmit = this.onGenerateReportSubmit.bind(this);
    this.onChangeDefaultReport = this.onChangeDefaultReport.bind(this);
    this.state = {
      optionsLoading: false,
      currentReport:0,
      colors: ['red',
              'blue',
              'green',
              'purple',
              'yellow']
      
    }
  }
  componentDidMount() {
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }
    this.props.getStudentHistoricReports(this.props.match.params.m_id, this.props.match.params.u_id, REPORT_PAGINATION, successCallback, errorCallback);
  }

  onPaginateReport(paginate){
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }
    this.props.getStudentHistoricReports(this.props.match.params.m_id, this.props.match.params.u_id, paginate, successCallback,errorCallback);
  }
  onReloadReport(){
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }
    this.props.getStudentHistoricReports(this.props.match.params.m_id, this.props.match.params.u_id, REPORT_PAGINATION, successCallback,errorCallback);
  }
  
  componentDidUpdate(prevProps, prevState){
    if(this.props.reports !==null && this.props.reports !== prevProps.reports &&
      this.props.reports.reportList[this.state.currentReport]!==undefined ){
      this.criteria_to_graph(this.props.reports.reportList[this.state.currentReport].reportInfo)
    }
    if(this.state.currentReport!== prevState.currentReport ){
      this.criteria_to_graph(this.props.reports.reportList[this.state.currentReport].reportInfo)
    }
  }

  
  onChangeDefaultReport(newCurrentReport){
    this.setState({
        currentReport: newCurrentReport
    });
  }
  onGenerateReportSubmit(event) {
    event.preventDefault();
    swal({
      title: '¿Estás seguro?',
      text: 'Se generará un nuevo reporte a la fecha',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if(result.value) {
        const successCallback = () => {
          swal({
            type: 'success',
            title: 'El reporte se está generando',
            showConfirmButton: false,
            timer: 1200
          });
        };

        const errorCallback = (error) => {
          console.log('Error overwriting the grade', error.request);
          if(error.request.status === FORBIDDEN_STATUS_CODE) {
            swal(
              'Ups...',
              `Ocurrió un error al tratar de generar el reporte`,
              'error'
            );
          } else {
            swal(
              'Ups...',
              'Ocurrió un error al tratar de generar el reporte',
              'error'
            );
          }
        };
        this.props.generateStudentHistoricReports(this.props.match.params.m_id, this.props.match.params.u_id, successCallback, errorCallback);
      }
    });
  }
  excelReportLink() {
    const { assignation } = this.props;
    const params = `user_email=${sessionStorage.getItem('email')}&user_token=${sessionStorage.getItem('authenticationToken')}`;

    return `${ROOT_URL}/tests/${assignation.id}/report.xlsx?${params}`;
  }

  downloadXls(elementId, filename){
    const table_div = document.getElementById(elementId);

    const reporte = XLSX.utils.table_to_book(table_div, {sheet:"Reporte"});
    const archivo_reporte = XLSX.write(reporte, {bookType:'xlsx', bookSST:true, type: 'binary'});
    var buffer = new ArrayBuffer(archivo_reporte.length);
    var view = new Uint8Array(buffer);
    for (var i=0; i<archivo_reporte.length; i++) view[i] = archivo_reporte.charCodeAt(i) & 0xFF;

    saveAs(new Blob([buffer],{type:"application/octet-stream"}), filename);
  }   
  renderHistoricReport(student){
    return (
      <div  id="report-histo-container">
      <div className= "col-md-12">
            <h3>Resultado histórico del estudiante: {student.email} </h3>
              <table id="tblHistoricStudent" className="table table-striped">
                <thead >
                  <tr >
                    
                    <th >Criterio</th>
                    <th >Descripción</th>
                    {student.periods.map((period) => {
                      return (

                        <th className="col-xs-1">{period}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                {student.criterias.map((criteria) => {
                    return (
                      <tr key={criteria.id} >
                        <td >{criteria.title}</td>
                        <td  >{criteria.description}</td>
                        {criteria.results? criteria.results.map((result) => {
                          return (<><td  >{result.label}</td> </>);}):""}
                      </tr>
                    );
                })}
                </tbody>
              </table>
          </div>
            <div className="col-md-12 col-xs-12">
              <button
                onClick={() => {
                  let fecha = new Date();
                  fecha = moment(fecha).format('D [de] MMMM [de] YYYY');
                  this.downloadXls('tblHistoricStudent',
                                  `Reporte historico de estudiante ${student.email}_${fecha}.xlsx`)
                }}
                type="button"
                className="btn btn-default pull-right"
              ><IconTooltip text="Descargar" icon="fa-download"/>
              </button>
            </div>
            {this.renderCriteriaReport(student) }
      </div>
    );
  }
  renderCriteriaReport(student){
    if (student.periods.length>1){
    return (
      <div>
        <div>
        <div id={"result-histo-report"} className="table-responsive  col-xs-12">
        <h3>Distribución de resultados para por criterio </h3>
         <div className="graphic col-xs-12">
            <br></br>
            <canvas id={"historic-graph"}  height="auto" width="auto">
            </canvas>
            
          </div>  
        </div>
        <div className="html2pdf__page-break"></div>
        </div>
      </div>
      
    );}
  }
  criteria_to_graph(student){
    this.render_graph(this.options_to_graph(student));
  }
  options_to_graph(student){
    var datasets= [];
    var labels = [];
    var max = 6
    var i =0
    var k =0
   student.periods.forEach(period=>{
      labels[i]= period
      i=i+1;
   })
   
    student.criterias.forEach(criterion=>{
    var data = [];
    var j=0
      criterion.results.forEach(element =>{
      data[j]= element.value
      j=j+1;
      })
      var dataset= {label: criterion.title,
                    data: data,
                    borderColor: this.state.colors[k],
                    backgroundColor: 'rgba(255, 255, 255, 0)'}
      datasets.push(dataset);
      k=k+1;
    })
    var options = { title: student.email,
                    labels: labels,
                    datasets: datasets,
                    max: max}
    return options
  }

render_graph(options){
  document.getElementById("report-histo-container").hidden= false
if (options.datasets.length===0){
  document.getElementById("report-histo-container").hidden= true
}else{
    if(document.getElementById("historic-graph")==null){
      return 
    }
    var ctx = document.getElementById("historic-graph").getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
          labels: options.labels,
          datasets: options.datasets
      },
      options: {
        elements:{
          line: {tension:0}
        },
        responsive: true,
        legend: {
          display: true
          },
          scales: {
              yAxes: [{
                ticks: {
                  stepSize: 1,
                  min: 0,
                  max: options.max                  
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Desempeño'
                },
              }
            ]
          }
      }
    });
  }
}
           
  render() {
    const { reports, pagination} = this.props;
      return (
        <div>
            <ReportList reports={reports ? reports: 
                                {reportList: []}}
                        reportType= 'historico de estudiante'
                        pagination = {pagination}
                        onReloadReport = {this.onReloadReport}
                        onPaginateReport={this.onPaginateReport}
                        onGenerateReportSubmit ={this.onGenerateReportSubmit}
                        onChangeDefaultReport ={this.onChangeDefaultReport}
                        description="En este reporte puede ver el resultado del curso con respecto al total de estudiantes que presentaron el examen">
            </ReportList>
            <div id="rep-container">
              {reports && reports.reportList.length!==0 && 
                this.renderHistoricReport(this.props.reports.reportList[`${this.state.currentReport}`].reportInfo) 
              }
            </div>  
            <button
                onClick={this.props.history.goBack}
                type="button"
                className="btn btn-danger">
                  Volver
              </button>
        </div>
      );
    } 
}
function mapStateToProps(state) {
  return { reports: state.reports.studentHistoric, pagination: state.reports.studentHistoricPagination};
}
export default connect(mapStateToProps, { getStudentHistoricReports, generateStudentHistoricReports })(StudentHistoricReport)

