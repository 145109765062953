import _ from 'lodash';
import { GET_ANSWER_BANK, GET_ANSWER_FROM_BANK, DELETE_ANSWER_FROM_BANK,GET_ANSWERS_GRADER_FROM_BANK,AGREGATE_ANSWERS_GRADER_TO_BANK,GET_ANSWER_BANK_MESSAGE  } from '../actions/answerBankActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {toogleGrader:false}, action) {
  switch(action.type) {
    case GET_ANSWER_BANK:
      const answerBank = action.payload.answerBank;
      const pagination = action.payload.links;
      return { ...state, answerBank: answerBank, pagination: pagination};
    case GET_ANSWER_FROM_BANK:
      const answerFromBank = action.payload;
      return { ...state, answerFromBank: answerFromBank };
    case GET_ANSWERS_GRADER_FROM_BANK:
      const answersGraderFromBank = action.payload.answersGrader;
      const grader_id=action.payload.id;
      return { ...state, [grader_id]: answersGraderFromBank};
    case AGREGATE_ANSWERS_GRADER_TO_BANK:
      return { ...state  };
    case DELETE_ANSWER_FROM_BANK:
      return {...state, answerBank:_.omit(state.answerBank, action.payload)};
    case GET_ANSWER_BANK_MESSAGE:
      const message= action.payload.message;
      return { ...state, message: message };
    default:
      return state;
  }
}
