import _ from 'lodash';
import { 
  GET_ASSIGNATIONS, 
  GET_ASSIGNATION, 
  GET_ASSIGNATION_REPORT,
  GET_STATUS_REPORT,
  ANULL_GRADER_GRADES,
  GET_GRADER_ANULLED_AVIABLE_REASIGN,
  GET_GRADER_TODO_AVIABLE_REASIGN,
  GET_GRADERS,
  GET_ASSIGNATION_NOT_DONE_AVIABLE_REASIGN,
  GET_ASSIGNATION_ANULLED_AVIABLE_REASIGN,
  REASSIGN_GRADERS_GRADES,
  GET_PARTIAL_COD_FROM_PARTICIPANTS,
  GET_ASSIGNATION_ANSWERS,
  GET_ASSIGNATION_ANSWER,
  GET_ANSWER_GRADERS,
  GET_PACKET_STRUCTURE,
  DELETE_ASSIGNATION
} from '../actions/assignationsTestActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
      case GET_ASSIGNATIONS:
        const unique_assignation = action.payload.uniqueAssignation;
        const assignations = _.mapKeys(action.payload.assignations, 'id');
        const pagination = action.payload.links;
        return { ...state, unique_assignation:unique_assignation, assignations: assignations, pagination: pagination};
      case GET_ASSIGNATION:
        const assignation = action.payload;
        return { ...state, assignation: assignation};
      case GET_ASSIGNATION_REPORT:
        const report= action.payload;
        return { ...state, report: report  };
      case  GET_STATUS_REPORT:
        const statusReport = action.payload;
        return { ...state, statusReport: statusReport};
      case ANULL_GRADER_GRADES:
        return {...state}
      case GET_GRADER_ANULLED_AVIABLE_REASIGN:
        const able_anulled = action.payload;
        return {...state, able_anulled:able_anulled}
      case GET_GRADER_TODO_AVIABLE_REASIGN:
        const able_to_do = action.payload;
        return {...state, able_to_do:able_to_do}
      case GET_GRADERS:
        const graders = action.payload;
        return {...state, graders:graders}
      case GET_PARTIAL_COD_FROM_PARTICIPANTS:
         const partial_cod_from_participants = action.payload;
         return {...state, partial_cod_from_participants: partial_cod_from_participants}
      case GET_ASSIGNATION_NOT_DONE_AVIABLE_REASIGN:
        const assig_able_to_do = action.payload;
        return {...state, assig_able_to_do: assig_able_to_do}
      case GET_ASSIGNATION_ANULLED_AVIABLE_REASIGN:
        const assig_able_anulled = action.payload;
        return {...state, assig_able_anulled: assig_able_anulled}
      case REASSIGN_GRADERS_GRADES:
        const {cant_to_do, cant_anulled} = action.payload;
        return {...state, cant_to_do: cant_to_do, cant_anulled: cant_anulled}
      case GET_ASSIGNATION_ANSWERS:
        const assignation_answers = action.payload.assignationAnswers;
        const pagination_answer = action.payload.links;
        return {...state, assignation_answers: assignation_answers, pagination_answer:pagination_answer}
      case GET_ASSIGNATION_ANSWER:
          const assignation_answer = action.payload;
          return {...state, assignation_answer: assignation_answer}
      case GET_ANSWER_GRADERS:
          const answer_graders = action.payload;
          return {...state, answer_graders: answer_graders}
      case GET_PACKET_STRUCTURE:
          const packet_structure = action.payload;
          return {...state, packet_structure: packet_structure}
      case DELETE_ASSIGNATION:
          return { ...state, assignation: {}};
      default:
        return state;
    }
  }
 
