import React, {useEffect} from "react"
import Loading from '../../Loading';
import Select from 'react-select';
import Report from './Report'
export default function ExamReport(props) {

  const insertColors = () => {
    var style = document.createElement('style');
    style.innerHTML = `
    .color_class_1 {
    background-color:#ff5050;
    border : 1px solid #ddd
    }
    .color_class_2 {
      background-color: #ff9933;
      border : 1px solid #ddd
    }
    .color_class_3 {
      background-color: #ffff66;
      border : 1px solid #ddd
    }
    .color_class_4 {
      background-color: #99ff66;
      border : 1px solid #ddd
    }
    .color_class_5 {
     background-color: #00cc66;
     border : 1px solid #ddd
      }

      .table > thead > tr > th {
         vertical-align: top;
      }
      .height{
        height: 80px;
        background-color: white;
        text-align: center;
        vertical-align: inherit !important;
        
      }
      .complete{
        width: 100px;
        height: 80px;
        padding-right: 350px !important;
        background-color: white;
        
      }
      .blocked{
        width: 100px;
        height: 80px;
        background-color: white;
        border-bottom: none !important;
        position: absolute;
      }
      .blockedx2{
        width: 250px;
        height: 80px;
        background-color: white;
        vertical-align: inherit !important;
        left: 100px;
        position: absolute;
        border-bottom: none !important;
      }
      .blockedx3{
        width: 150px;
        height: 80px;
        background-color: white;
        left: 350px;
        position: absolute;
        border-bottom: none !important;
      }
      .blockedx4{
        width: 150px;
        height: 80px;
        background-color: white;
        left: 500px;
        position: absolute;
        border-bottom: none !important;
        padding-right: 50px !important;
      }
      
    `;
    document.head.appendChild(style);
  
  }

  useEffect(()=> {
    insertColors();
  }, []);
  
  const exam_test_option_id  = props.match.params.id;
  return (
    <div>
        <Report 
          exam_test_option_id={exam_test_option_id}
        />
        <div className="row">
          <div className="col-md-6 col-xs-6">
            <button
              className="btn btn-danger"
              onClick={props.history.goBack}>
              Volver
            </button>
          </div>
        </div>
    </div>
  );
}