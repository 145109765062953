import _ from 'lodash';
import { GET_USERS, GET_USER, GET_STUDENTS, GET_GRADERS,
  GET_REVIEWERS, GET_ADMINS, DELETE_USER, ADD_USER_ROLES, 
  GET_USERSVIDEOS, SEARCH_USERSVIDEOS, GET_MESSAGE_NEWUSER
  } from '../actions/usersActions';

/**
 * Manejo de la parte del estado correspondiente a usuarios.
 * Permite la actualización de usuarios, obtener un usuario por id, además de obtener los usuarios
 * por rol (estudiante, codificadores, revisores, administradores,).
 * Permite eliminar un usuario de la lista de usuarios disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los usuarios)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch (action.type) {
    case GET_USERS:
      const users = _.mapKeys(action.payload.users, 'id');
      const pagination = action.payload.links;
      return { ...state, users: users, pagination: pagination };
    case GET_USER:
      const user = action.payload.user;
      return { ...state, user: user, [user.id]: user };
    case GET_STUDENTS:
      const students = _.mapKeys(action.payload, 'id');
      return { ...state, students: students };
    case GET_GRADERS:
      const graders = _.mapKeys(action.payload, 'id');
      return { ...state, graders: graders };
    case GET_REVIEWERS:
      const reviewers = _.mapKeys(action.payload, 'id');
      return { ...state, reviewers: reviewers };
    case GET_ADMINS:
      const admins = _.mapKeys(action.payload, 'id');
      return { ...state, admins: admins };
    case DELETE_USER:
      const usersWithoutOne = _.omit(state.users, action.payload);
      state.users = usersWithoutOne;
      return _.omit(state, action.payload);
    case ADD_USER_ROLES:
      return {...state};
    case GET_USERSVIDEOS:
      const usersVideos = action.payload.users;
      const paginationUsersVideos = action.payload.links;
      if (action.payload.search) {
        const {search} = action.payload;
        return { ...state, usersVideos: usersVideos, pagination: paginationUsersVideos, search };
      }
      state.search && delete state.search 
      return { ...state, usersVideos: usersVideos, pagination: paginationUsersVideos };
    case SEARCH_USERSVIDEOS:
      const newUsersVideos = _.mapKeys(action.payload.users, 'id');
      const newPagination = action.payload.links;
      return { ...state, users: newUsersVideos, pagination: newPagination, text: action.payload.text };
    case GET_MESSAGE_NEWUSER:
      const message= action.payload.message
      const message2= action.payload
      return { ...state, message: message, message2: message2  };  
    default:
      return state;
  }
}
