/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import Styles from '../../assets/stylesheets/layout.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_DATA_TABLE_ORDER, FORBIDDEN_STATUS_CODE, REPORT_PAGINATION } from '../../actions/constants';
import { getExamsList } from '../../actions/examsActions'
import { getTests } from '../../actions/testsActions';
import { generateAnswersReport, getAnswersReport } from '../../actions/reportsActions';
import Select from 'react-select';
import ReportList from './ReportList';
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import IconTooltip from '../utils/IconTooltip';

export default function AnswersReport(props) {

  const dispatch = useDispatch()
  const exams = useSelector(state => state.exams.examList)
  const tests = useSelector(state => state.tests.tests)
  const report = useSelector(state => state.reports.answersReport)
  const pagination = useSelector(state => state.reports.answersReportPagination)

  const [options, setOptions] = useState({
    exams: [],
    tests: []
  })
  const [ form, setForm ] = useState({
    exam: null,
    tests: []
  })

  const handleChange = (e) => {
    if (e instanceof Array) {
      setForm({
        ...form,
        tests: e
      })
    } else {
      setForm({
        exam: e,
        tests: []
       })
    }
    
  }

  const generateReport = (event) => {
    event.preventDefault();
    swal({
      title: '¿Estás seguro?',
      text: 'Se generará un nuevo reporte a la fecha',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        const successCallback = () => {
          swal({
            type: 'success',
            title: 'El reporte se está generando',
            showConfirmButton: false,
            timer: 1200
          });
        };

        const errorCallback = (error) => {
          console.log('Error overwriting the grade', error.request);
          if (error.request.status === FORBIDDEN_STATUS_CODE) {
            swal(
              'Ups...',
              `Ocurrió un error al tratar de generar el reporte`,
              'error'
            );
          } else {
            swal(
              'Ups...',
              'Ocurrió un error al tratar de generar el reporte',
              'error'
            );
          }
        };
        const data = {
          exam: form.exam.value,
          exam_test_option_id : form.tests.map(test => test.value).sort((test_1,test_2) => test_1 - test_2)
        }
        dispatch(generateAnswersReport(data, successCallback, errorCallback))
      }
    });
    
   
  }

  const reloadReport = () => {
    
    if ($.fn.dataTable.isDataTable('#tblSolutiongProgress')){
      $('#tblSolutiongProgress').DataTable().destroy()
    }

    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
     
      $('#tblSolutiongProgress').DataTable(DEFAULT_DATA_TABLE_ORDER)
    }

    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
    }
    dispatch(getAnswersReport(form.exam.value, form.tests.map(test => test.value).sort((test_1,test_2) => test_1 - test_2), REPORT_PAGINATION, successCallback, errorCallback))
  }

  const onPaginateReport = (paginate) => {
    
    if ($.fn.dataTable.isDataTable('#tblSolutiongProgress')){
      $('#tblSolutiongProgress').DataTable().destroy()
    }

    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
     
      $('#tblSolutiongProgress').DataTable(DEFAULT_DATA_TABLE_ORDER)
    }

    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
    }
    dispatch(getAnswersReport(form.exam.value, form.tests.map(test => test.value).sort((test_1,test_2) => test_1 - test_2), paginate, successCallback, errorCallback))
  }

  const downloadXls = (elementId, filename) => {
    const table_div = document.getElementById(elementId);

    const reporte = XLSX.utils.table_to_book(table_div, {sheet:"Reporte"});
    const archivo_reporte = XLSX.write(reporte, {bookType:'xlsx', bookSST:true, type: 'binary'});
    var buffer = new ArrayBuffer(archivo_reporte.length);
    var view = new Uint8Array(buffer);
    for (var i=0; i<archivo_reporte.length; i++) view[i] = archivo_reporte.charCodeAt(i) & 0xFF;

    saveAs(new Blob([buffer],{type:"application/octet-stream"}), filename);
  }

  const renderSolutionProgressReport = () => {
    const data = report.reportList[0]?.reportInfo
    
    if (report) {
      return (
        <div  className="table-responsive  col-xs-12">
            {data.answers && data.answers.length > 0?
                <>
                <ul className="list-group scrolling-list">
                  <table id="tblSolutiongProgress" className="table table-bordered dt-responsive table-striped">
                    <thead>
                      <tr>
                        <th>Estudiante</th>
                        <th>Pregunta</th>
                        <th>Puntaje</th>
                        <th>¿Codificado?</th>
                        <th>Estado de la respuesta</th>
                        <th>Fecha de inicio </th>
                        <th>Fecha de fin</th>
                        <th>Minutos totales</th>
                      </tr>
                    </thead>
                    <tbody>
                    {data.answers.map((row, index) => {
                      return (
                          <tr key={index}>
                            <td>
                              <Link to={`/answers/${row.id}`} >
                              {row.student.email}
                              </Link>
                            </td>
                            <td>{row.testTitle}</td>
                            <td>{row.score ? Number.parseFloat(row.score).toFixed(2) : 'Sin Puntaje'}</td>
                            <td>{row.graded? 'SI': 'NO'}</td>
                            <td>{row.status}</td>
                            <td>
                              {moment(row.startDate).format('D/MM/YYYY - HH:mm')}
                            </td>
                            <td>
                              {row.finishDate?  moment(row.finishDate).format('D/MM/YYYY - HH:mm'): "-"}
                            </td>
                            <td>{ row.totalMinutes ? Number.parseFloat(row.totalMinutes).toFixed(2) : 'Cerrado por el sistema'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <p><strong>Total de respuestas: </strong>{data.totalAnswers}</p>
                </ul>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <button
                        onClick={() => {
                          let fecha = new Date();
                          fecha = moment(fecha).format('D [de] MMMM [de] YYYY');
                          downloadXls("tblSolutiongProgress",
                          `reporte_tiempos_de_respuestas_${fecha}.xlsx`)
                        }}
                        type="button"
                        className="btn btn-default pull-right"
                      ><IconTooltip text="Descargar" icon="fa-download"/>
                      </button>
                    </div>
                </div>
                </>
            :
            <div className="row text-center">
                <h5>No hay datos disponibles</h5>
            </div>}
        </div>
      );
 
    }
  }
  
  
  useEffect(()=>{
    if (tests) {
      setOptions({
        ...options,
        tests: Object.values(tests).map(({examTestOptionId:value, title:label })=>({ value, label }))
      })
    }
  },[tests])

  useEffect(()=>{
    form.exam?.value && dispatch(getTests(`?exam=${form.exam.value}`))
  },[form.exam])

  useEffect(()=>{
    
    if (exams) {
     
      const examsSorted = Object.values(exams).sort((a, b) => b.id - a.id) 
      setOptions({
        ...options,
        exams: examsSorted.map(({id:value, title:label })=>({ value, label }))
      })
    }
  },[exams])

  useEffect(()=>{
    dispatch(getExamsList())
  },[])

  return (
    <div className={Styles.main}>
      <section>
        <div className='row'>
          <h1>Reporte de respuestas abiertas</h1>
          <p> Para poder generar un reporte del tiempo de las respuestas de un examen seleccione un examen seguido de las preguntas que desea generar en el reporte.
          </p>
          <form id='generateReport' >
            <div className='col-sm-6'>
              <label htmlFor="participants">Examen</label>
              <Select
                onChange={handleChange}
                value={form.exam || {}}
                placeholder="Codificaciones"
                multi={false}
                options={options.exams} 
                required={true}
                inputId= "participants"
                id="participants"
                name="participants"
                com
              />

            </div>
            {form.exam  && (
              <div className='col-sm-6'>
              <label htmlFor="participants">Preguntas</label>
              <Select
                onChange={handleChange}
                value={form.tests || {}}
                placeholder="Codificaciones"
                multi={true}
                options={options.tests} 
                required={true}
                inputId= "participants"
                id="participants"
                name="participants"
                com
              />
              </div>

            )}
            </form>
          </div>
          {(form.exam && form.tests.length > 0) && (
            <ReportList reports={report ? report: {reportList: []}}
              reportType= 'tiempos de las respuestas'
              pagination = {pagination}
              onReloadReport = {reloadReport}
              onPaginateReport={onPaginateReport}
              onGenerateReportSubmit ={generateReport}
              onChangeDefaultReport ={reloadReport}
              description="En este reporte puede ver los tiempos de las respuestas de las preguntas de un examen seleccionado">
            </ReportList>
          ) }
          { report && report.reportList.length!==0 && 
            renderSolutionProgressReport()
          }
      </section>
      <section 
				style={{
					display: 'flex',
					alignItems: 'center',
					flexFlow: 'row nowrap',
					justifyContent: 'space-between',
				}}
			>
				<button
					onClick={() => props.history.push(`/`)}
					type="button"
					className="btn btn-danger">
					Volver
				</button>
			</section>

    </div>
  )
}
