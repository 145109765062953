import React, {useEffect} from "react";
import useGetRequest from "../../../../customHooks/useGetRequest";
import usePatchRequest from "../../../../customHooks/usePatchRequest";
import Answer from "./Answer";

export default function AnswerToTest({testId}) {

  // Obtener la información de respuesta usando un hook personalizado para solicitudes GET
  const {data:answerInfo, setData:setAnswerInfo,  isLoading, error, refetch} = useGetRequest(
    `answers/${testId}/byExamTestOption`
  );

  const {
    data:responseData,
    sendRequest:beginAnswer
  } = usePatchRequest(
    `answers/${answerInfo?.id}/start`
  );
  
  const handleBeginAnswer = () => {
    beginAnswer(null)
  };

  useEffect(() => {
    if (responseData) {
      refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  // Si la carga de datos está en progreso, mostrar un mensaje de "Cargando..."
  if (isLoading) {
    return <div>Cargando...</div>;
  }

  // Si se produce un error al cargar los datos, mostrar un mensaje de error
  if (error) {
    return <div>Hubo un error al cargar la respuesta</div>;
  }

  // Si los datos se han cargado correctamente, mostrar la información de respuesta
  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      {/* Mostrar un mensaje diferente según el estado de la respuesta */}
      {answerInfo.status === "to_do" && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="">
            <h4>La respuesta no ha sido iniciada</h4>
          </div>
          <button onClick={handleBeginAnswer} className="btn btn-success">Iniciar respuesta</button>
        </div>
      )}
      {answerInfo.status === "in_progress" && (
        <div>
          <Answer data={answerInfo} examInfo={answerInfo} setExamInfo={setAnswerInfo} />
        </div>
      )}
      {answerInfo.status === "done" && <h4>Respuesta Terminada</h4>}
    </div>
  );
}