import { useState } from "react";
import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from '../actions/constants';

const usePatchRequest = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (dataToUpdate) => {
    try {
      setLoading(true);
      const response = await axios.patch(`${ROOT_URL}/${url}`, dataToUpdate, getAuthHeaders());
      setData(response.data);
      setError(null);
    } catch (error) {
      setData(null);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, sendRequest };
};

export default usePatchRequest;