import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las matrices de codificación.

// Actualiza las matrices en el estado de la aplicación.
export const GET_GRADING_MATRICES = 'GET_GRADING_MATRICES';

// Actualiza la matriz actual en la aplicación.
export const GET_GRADING_MATRIX = 'GET_GRADING_MATRIX';

// Eliminación de la matriz dada del estado de la aplicación.
export const DELETE_GRADING_MATRIX = 'DELETE_GRADING_MATRIX';

// Eliminación de la matriz dada del estado de la aplicación.
export const GET_GRADING_MATRIX_LEVELS = 'GET_GRADING_MATRIX_LEVELS';


/**
 * Obtiene las matrices de codificación disponibles.
 *
 * @export getGradingMatrices
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=(data) => {}] : Función a llamar en caso de petición exitosa.
 *                                             La función debe recibir por parámetro los datos de la respuesta exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getGradingMatrices(pagination, successCallback = (data) => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination =""
  }
  const request = axios.get(`${ROOT_URL}/grading_matrices${pagination}`, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRICES,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la matriz con el id dado por parámetro.
 *
 * @export getGradingMatrix
 * @param {*} id : El id de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getGradingMatrix(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/grading_matrices/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export createGradingMatrix
 * @param {*} gradingMatrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createGradingMatrix(gradingMatrix, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/grading_matrices`, {grading_matrix: gradingMatrix}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export updateGradingMatrix
 * @param {*} gradingMatrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateGradingMatrix(id, gradingMatrix, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}`, {grading_matrix: gradingMatrix}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina la matriz de codificación con el id dado por parámetro.
 *
 * @export deleteGradingMatrix
 * @param {*} id : EL id de la matriz
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteGradingMatrix(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/grading_matrices/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(() => {
      dispatch({
        type: DELETE_GRADING_MATRIX,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la información de los codificadores y revisores asociados a la matriz de codificación
 * con el id dado por parámetro con la información de ids de codificadores y revisores dada.
 *
 * @export
 * @param {*} id : El id de la matriz.
 * @param {*} participantsInfo : Objeto con la información de ids de codificadores y revisores.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editParticipantsGradingMatrix(id, participantsInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}/edit_participants`, participantsInfo, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Actualiza la información de los parámetros asociados a la matriz de codificación
 * con el id dado por parámetro con la información de los parámetros.
 *
 * @export
 * @param {*} id : El id de la matriz.
 * @param {*} params : Objeto con la información de los parámetros de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editParams(id, params,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}/edit_params`, params, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea una matriz con los datos dados por parámetro.
 *
 * @export createGradingMatrixLevels
 * @param {*} gradingMatrix : Objeto con la información de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function createGradingMatrixLevels(id, gradingMatrixInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}/edit_levels`, gradingMatrixInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la matriz con el id dado por parámetro.
 *
 * @export getGradingMatrixLevelsByExamTestOption
 * @param {*} id : El id de la matriz.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getGradingMatrixLevelsByExamTestOption(id) {
  const request = axios.get(`${ROOT_URL}/grading_matrices/levels_by_eto?exam_test_option_id=${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX_LEVELS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log(error);
      }
    });
  }
}

/**
 * Actualiza la respuesta de banco con el id dado por parámetro.
 *
 * @export editGradersLevels
 * @param {*} id : El id del test dado por parámetro
 * @param {*} test : El objeto con los datos de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function editGradersLevels(id, values,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}/edit_graders_levels`, {graders_levels: values}, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la respuesta de banco con el id dado por parámetro.
 *
 * @export associateGradingMatrixToSuperMatrix
 * @param {*} id : El id del test dado por parámetro
 * @param {*} test : El objeto con los datos de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function associateGradingMatrixToSuperMatrix(id, superMatrixId, values,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/grading_matrices/${id}/associate_super_matrix`, {superMatrix: superMatrixId, criteria: values}, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_MATRIX,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}