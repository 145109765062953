import React, { useState, useEffect } from 'react';
import { AZURE_STORAGE_CONNECTION_STRING, AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER, NO_FILE_EXTENSION, ACCEPTED_FORMATS } from '../../actions/constants';

/**
 * Componente para el manejo de un documento embebido.
 * Verifica que la extensión sea valida y en caso contrario muestra el documento como un enlace.
 *
 * props: {
 *  title: titulo a mostrar
 *  url: url del documento
 *  storage: storage donde se encuentra el archivo ene l blob de azure 
 * }
 * 
 * 
 * 
 */
const EmbedFile = (props) => {
  
  const { url, storage, preview = true, messageDownload } = props;
  
  const [state, setState] = useState({
    url: "",
    contentType: "",
    acceptEmbed: false,
    show: false
  });
 

  const renderFile = async() => {
    try {
      let acceptEmbed = false;
      let fileUrlElements = url.split(".");
      let extension = fileUrlElements[fileUrlElements.length - 1] || "";
      let show  = (!extension.includes(NO_FILE_EXTENSION)) ? true : false;
      const format = fileUrlElements[1] || "";
      const second_format = format.split(".")[2] || "";
      if (ACCEPTED_FORMATS.includes(format)|| ACCEPTED_FORMATS.includes(second_format)){
        acceptEmbed = true;
      }

      const { BlobServiceClient } = require('@azure/storage-blob');
      const blobSasUrl = AZURE_STORAGE_CONNECTION_STRING
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerName = storage || AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER;
      const containerClient = blobServiceClient.getContainerClient(containerName);
      var blob =containerClient.getBlockBlobClient(url);
      let blobProperties = await blob.getProperties();
      setState({ ...state, url: blob.url, contentType: blobProperties.contentType, show, acceptEmbed});  
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(()=>{
    url && renderFile()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[url]);

  return (
    <div className="text-center">
      {!state.acceptEmbed && state.url && state.show &&
        <p> Archivo adjunto de la respuesta disponible en
          este <a href={state.url} target="_blank" rel="noopener noreferrer"> enlace </a>
        </p>
      }
      {state.acceptEmbed && state.url && state.show &&
        <>
          {preview && (
            <>
              <label>{props.title || 'Archivo adjunto:'} </label><br></br>
              <embed  src={state.url} width="100%" ></embed>
              <br/>
            </>
          )}
          <p>{messageDownload || 'O lo puede descargar desde este'} <a  href={state.url} target="_blank" rel="noopener noreferrer"> enlace </a></p>
        </>
      }
    </div>
  );
}

export default EmbedFile;