import _ from 'lodash';
import {  SEARCH_USER,  SEARCH_TEST,SEARCH_MATRIX, SEARCH_QUESTIONARY ,SEARCH_EXAM, SEARCH_REPORT, SEARCH_ASSIGNATION_ANSWER, SEARCH_ANSWERS_FROM_BANK, SEARCH_SUPER_MATRIX_STUDENT, SEARCH_STUDENT} from '../actions/searchesActions';

/**
 * Manejo de la parte del estado correspondiente a busquedas
 * Permite la actualización de usuarios, obtener un usuario por id, además de obtener los usuarios
 * por rol (estudiante, codificadores, revisores, administradores,).
 * Permite eliminar un usuario de la lista de usuarios disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los usuarios)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
        case SEARCH_USER:
            const userFound = action.payload;
            return { ...state, userFound: userFound };
        case SEARCH_TEST:
            const testFound = action.payload.tests;
            return { ...state, testFound: testFound };
        case SEARCH_MATRIX:
            const gradingMatricesFound = action.payload.gradingMatrices;
            return { ...state, gradingMatricesFound: gradingMatricesFound };
        case SEARCH_QUESTIONARY:
            const questionariesFound = action.payload.mcQuestionaries;
            return { ...state, questionariesFound: questionariesFound };
        case SEARCH_EXAM:
            const examFound = action.payload.exams;
            return { ...state, examFound: examFound };
        case SEARCH_REPORT:
            const reportFound = action.payload.reports;
            return { ...state, reportFound: reportFound };
        case SEARCH_STUDENT:
            const studentsFound = action.payload;
            return { ...state, studentsFound: studentsFound };
        case SEARCH_ASSIGNATION_ANSWER:
            const assignationAnswerFound = action.payload.assignationAnswers;
            return { ...state, assignationAnswerFound: assignationAnswerFound };
        case SEARCH_ANSWERS_FROM_BANK:
            const answersFromBankFound = action.payload.answersFromBank;
            return { ...state, answersFromBankFound: answersFromBankFound };
        case SEARCH_SUPER_MATRIX_STUDENT:
                const superMatrixUserFound = action.payload.student;
                return { ...state, superMatrixUserFound: superMatrixUserFound };
        default:
            return state;
    }
}