import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';


export const GET_THEMES = 'GET_THEMES';

export const GET_THEME = 'GET_THEME';

export const EDIT_THEME = 'EDIT_THEME';

export const DELETE_THEME = 'DELETE_THEME';

export const CREATE_THEME = 'CREATE_THEME';

/**
 * Obtiene todas las ventanas de aplicación de un examen en la aplicación.
 *
 * @export GET_THEMES
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getThemesByMatrix(idMatrix, successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/themes/${idMatrix}/byMatrix`, getAuthHeaders());
	return (dispatch) => {

		request.then(({data}) => {
			dispatch({
				type: GET_THEMES,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function getThemes(successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/themes`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: GET_THEMES,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}
export function getTheme(id, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.get(`${ROOT_URL}/themes/${id}`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: GET_THEME,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function createTheme(data, successCallback = () => {}, errorCallback = () => {}) {
	const request = axios.post(`${ROOT_URL}/themes`, data, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: CREATE_THEME,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function editTheme(id, data, successCallback = () => {}, errorCallback = (error) => {}) {
	const request = axios.put(`${ROOT_URL}/themes/${id}`, data, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: EDIT_THEME,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}

export function deleteTheme(id, successCallback = () => {}, errorCallback = (error) => {}) {
	const request = axios.delete(`${ROOT_URL}/themes/${id}`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: DELETE_THEME,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if (!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		});
	}
}
  