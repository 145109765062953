import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError, BLOB_ACCOUNT, AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER } from './constants';

// Actualiza en el estado global.
export const GET_VIDEO = 'GET_VIDEO';
export const GET_VIDEOS = 'GET_VIDEOS';
export const CREATE_VIDEO = 'CREATE_VIDEO';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const GET_PROCESSESVIDEOS = 'GET_PROCESSESVIDEOS';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const EDIT_VIDEO = 'EDIT_VIDEO';
export const GET_MULTIMEDIA_URL = 'GET_MULTIMEDIA_URL';
export const ASOCIATE_PROCESSES_TO_VIDEO = 'ASOCIATE_PROCESSES_TO_VIDEO';


/**
 * Crea un video en el sistema con los datos dados en el parámetro video.
 *
 * @export createVideo
 * @param {*} video : Información del nuevo video a crear
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function createVideo(video, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.post(`${ROOT_URL}/videos`, {video: video}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: CREATE_VIDEO,
          payload: data
        });
        successCallback(data.video.video);
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

/**
 * Obtener el video con el id dado.
 *
 * @export getVideo
 * @param {*} id : El id de la respuesta
 * @param {*} email : El email del observador para hacer la petición a solo los que puede ver
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getVideo(id, email = undefined, successCallback = ()=> {}, errorCallback=(error)=>{}) {
  let request;
  if (email) {
    request = axios.get(`${ROOT_URL}/videos/toObserver?id=${id}&email=${email}`, getAuthHeaders());
  } else {
    request = axios.get(`${ROOT_URL}/videos/${id}`, getAuthHeaders());
  }

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_VIDEO,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the video', error);
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtener la lista de videos asociados a los procesos del gestor que los consulta.
 *
 * @export getVideos
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getVideos({pagination = '', search = ''}, successCallback = ()=> {}, errorCallback=(error)=>{}) {
  const searchQuery = search ? `&search%5Bquery%5D=${search.query}&search%5Bvalue%5D=${search.value}` : ''
  const request = axios.get(`${ROOT_URL}/videos${pagination}${searchQuery}`, getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_VIDEOS,
        payload: search ? {...data, search} : data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the video', error);
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualizar si un video se marca como visto para restringir su acceso
 *
 * @export updatePermission
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updatePermission(id, data, successCallback = ()=>{}, errorCallback = ()=>{}) {
    const request = axios.put(`${ROOT_URL}/permissions/${id}/byObserver`, data, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: UPDATE_PERMISSION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the video', error);
        errorCallback();
      }
    });
  }
}

  /**
 * Envía el resultado de la carga de un archivo multimedia al blob.
 *
 * @export addAttachmentVideo
 * @param {*} id : El id de la respuesta
 * @param {*} log_result : Resultado de la carga del archivo multimedia al blob
 * @returns Función de manejo de error o éxito de la petición.
 */
export function addAttachmentLog(id, upload_result) {
  axios.post(`${ROOT_URL}/videos/${id}/get_blob_upload_result`, upload_result, getAuthHeaders())
  .catch((error) => {
    console.log('log_result')
  })
}


/**
 * Obtiene los videos asociados al proceso consultado
 *
 * @export getprocessVideos
 * @param {*} process_id : El id del proceso que se desea consultar sus videos
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getprocessVideos(pagination, {processId='', isObserver=false}, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/videos${pagination}&[process_id]=${processId}&[isObserver]=${isObserver}`, getAuthHeaders());
  // const request = axios.get(`${ROOT_URL}/videos?id=${processId}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PROCESSESVIDEOS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Eliminar un video según el id dado por parámetro.
 *
 * @export deleteVideo
 * @param {*} id : EL id del video
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function deleteVideo(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/videos/${id}/delete_video?account=${BLOB_ACCOUNT}&container=${AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER}`, getAuthHeaders());
  
  return (dispatch) => {
    request.then(() => {
      dispatch({
        type: DELETE_VIDEO,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  /**
 * editar un video
 *
 * @export updateVideo
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateVideo(id, video, successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.patch( `${ROOT_URL}/videos/${id}`, { video: video }, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: EDIT_VIDEO,
          payload: data,
        });
        successCallback(data.video.video);
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          console.log(error);
        }
        errorCallback(error);

      });
  };
}

/**
 * Obtener el enlace multimedia del video con el id dado.
 *
 * @export getMultimediaUrl
 * @param {*} id : El id del video
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getMultimediaUrl(id, ip, callBack= ()=>{}) {
  const request = axios.get(`${ROOT_URL}/videos/${id}/multimedia_url?account=${BLOB_ACCOUNT}&sip=${ip}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MULTIMEDIA_URL,
        payload: data
      });
      callBack(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the answer', error);
      }
    });
  }
}

  /**
 * asociar procesos al video
 *
 * @export asociate
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
  export function asociate(id, processSelecteds) {
    const request = axios.post(`${ROOT_URL}/videos/asociate`,{ id, data: processSelecteds } ,getAuthHeaders());
  
    return (dispatch) => {
      request
        .then(({ data }) => {
          dispatch({
            type: ASOCIATE_PROCESSES_TO_VIDEO,
            payload: data,
          });
        })
        .catch((error) => {
          if (!isUnauthorizedError(error, dispatch)) {
            console.log(error);
          }
        });
    };
  }
  
