import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de textos (documentos adjuntos) de las preguntas

// Actualiza los textos adjuntos disponibles en el estado
export const GET_TEXTS = 'GET_TEXTS';

// Actualiza la información del texto adjunto actual en el estado
export const GET_TEXT = 'GET_TEXT';

// Remueve un texto adjunto dado del estado
export const DELETE_TEXT = 'DELETE_TEXT';

/**
 * Obtiene los textos adjuntos disponibles.
 *
 * @export getTexts
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTexts(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination =""
  }
  const request = axios.get(`${ROOT_URL}/texts${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEXTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea un nuevo texto adjunto.
 *
 * @export createText
 * @param {*} textInfo : Información del texto adjunto a crear
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createText(textInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/texts`, textInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEXT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina un texto adjunto con el id dado por parámetro
 *
 * @export deleteText
 * @param {*} id : EL id del texto adjunto.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteText(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/texts/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: DELETE_TEXT,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
