import React, { useState } from "react";
// import { Labeler } from "react-text-annotator";
import axios from "axios";
import { ROOT_URL, getAuthHeaders } from '../../actions/constants';
// import { TextAnnotator } from 'react-text-annotate'

const TAG_COLORS = {
  ORG: '#84d2ff',
  PERSON: 'rgb(0, 255, 162)'
}

const TAGS = [
  {
    color: "red",
    endToken: 135,
    id: "id4",
    kind: "label",
    name: "Análisis de contexto",
    number: 4,
    startToken: 4
  },

]

const General = () => {

  const [select, setSelect] = useState({ value: '', options: [] })
  const [state, setState] = useState({ value: [] })
  const [id, setId] = useState('')
  const [answer, setAnswer] = useState('')
  const [colors, setColors] = useState({})
  const [annotations, setAnnotations] = useState({annotations: []})
  


  const handleSubmit = async (e) => {
    e.preventDefault()
    const {data} = await axios.get(`${ROOT_URL}/answers/${id}`, getAuthHeaders())
    const text = document.createElement("div")
    text.innerHTML = data.answer
    console.log('text:', text);
    setAnswer(text.textContent)
    const  {data: matrixData } = await axios.get(`${ROOT_URL}/grading_matrices/${data?.examTestOptions?.gradingMatrix?.id}`, getAuthHeaders())
    setSelect({ ...select, options: matrixData.gradingCriteria.map(option => option.description) })
    setColors(matrixData.gradingCriteria.map(option => ({ [option.description]: '#84d2ff'})))
  }

  const handleSelection = (start, end, ...params) => {
    const current = annotations.annotations
    console.log('annotations', annotations);
    console.log('current', current);

    current.push({
      id: `id${current[current.length - 1]?.number +1 || 1}`,
      color: current[current.length - 1]?.number % 2 === 0 ? 'red' : '#84d2ff',
      endToken: end,
      startToken: start,
      name: select.value,
      kind: 'label',
      number: current[current.length - 1]?.number +1 || 1
    })
    
    setAnnotations({annotations: current})
  }

  const handleEvent = (...params) => {
    console.log('params: ', params);
  }

  return (<>
    <form onSubmit={handleSubmit}>
      <label>
        <input type="number" value={id} onChange={(e)=>setId(e.target.value)}/>
      </label>
      <button>consultar</button>
    </form>
    <br />
    <br />
    <select
      onChange={e => setSelect({ ...select, value: e.target.value })}
      value={select.value}
    >
      <option value=""></option>
      {select.options.map((option, i) => (
        <option key={i} value={option}>{option}</option>
      ))}
    </select>
    <br />
    <br />
    <br/>
    <section style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <h1>prueba</h1>
      {/* {select.value && (
        // <Labeler text={answer} annotations={annotations.annotations} onSelectionChange={handleSelection} onAnnotationClick={handleEvent}/>
      )} */}
    </section>
   
  </>)
}

export default General

{/* <section style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
<TextAnnotator
  style={{
    lineHeight: 1.5,
  }}
  content={answer}
  value={state.value}
  onChange={value => setState({ value })}
  getSpan={span => ({
    ...span,
    tag: select.tag,
    color: TAG_COLORS[select.tag],
  })}
/>
</section> */}