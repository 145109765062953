import { GET_APWINDOWS, GET_APWINDOW, DELETE_APWINDOW } from '../actions/apwindowsActions';

/**
 * Manejo de la parte del estado correspondiente a examenes.
 * Permite la actualización de examenes, obtener un examen por id.
 * Permite eliminar un examen por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
const initialState = {
  apwindows: [],
  apwindow: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APWINDOWS:
      return {...state,
        apwindows: action.payload 
      };
    case GET_APWINDOW:
      return {...state,
        apwindow: action.payload 
      };
    case DELETE_APWINDOW:
      return {...state,
        apwindow: undefined 
      };
    default:
      return state;
  }
}
