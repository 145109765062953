import React, {useState, useEffect} from "react";
import { ROOT_URL, getAuthHeaders} from '../../../actions/constants'
import axios from 'axios';
import Loading from '../../Loading';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Select from "react-select";
import moment from 'moment';
import { Tab, Tabs } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css';

const Permissions = props => {
  const processId = props.match.params.id;
  const [data, setData] = useState({
    data: []
  })
  const [select, setSelect] = useState({value: '', options: []});
  const [users, setUsers] = useState({observers: []})
  const [loading, setLoading] = useState(false)
  const MySwal = withReactContent(Swal);
  const [options, setOptions] = useState({observers: [], videos: []});
  const [form, setForm] = useState({
    observers: [],
    videos: [],
    deadline: ''
  });

  const getInfo = async() => {
    setLoading(true);
    const opt = {observers: [], videos: []};
    const usersRequest = await axios.get(`${ROOT_URL}/observers/byProcess?id=${processId}`, getAuthHeaders());
    if (usersRequest.status === 200) {
      setUsers({observers: usersRequest.data})
      opt.observers = usersRequest.data.map(user => ({label: user.email, value: user.id}))
    }

    const request = await axios.get(`${ROOT_URL}/videos/byProcess?id=${processId}`, getAuthHeaders());
    if (request.status === 200) {
      setLoading(false);
      setData({
        data: request.data,
      })
      setSelect({...select, options: request.data.map((video, i) => ({label: video.title, value: i}))})
      opt.videos = request.data.map(video => ({label: video.title, value: video.opv_id}))
    }

    setOptions(opt);
  }

  useEffect(()=>{
    getInfo();
  },[]);

  const changeSelect = (val) => {
    setSelect({...select, selected: val});
  }
  const handleChange = (val, key) => {
    setForm({...form, [key]: val});
  }
  const handlePermissions = async (e) => {
    e.preventDefault();
    let empty = false;
    Object.keys(form).map(key => {
      if (form[key] === '' || !form[key] || form[key].length < 1 ) return empty = true;
      return;
    })
    if (!empty) {
      const dataForm = {
        ...form,
        videos: form.videos.map(video => video.value),
        observers: form.observers.map(observer => observer.value)
      }
      const request = await axios.post(`${ROOT_URL}/permissions/massive`, dataForm, getAuthHeaders());
      if (request.status === 200) {
        Swal({
          type: 'success',
          title: 'consulta exitosa',
          html:`
          <hr/>
          <div style="display: flex; flex-flow: column nowrap; align-items: center;">
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Permisos agregados:</h4><h4> ${request?.data?.createds ?? 0}</h4>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90%;">
              <h4>Permisos modificados: </h4><h4>${request?.data?.updates ?? 0 }</h4>
            </div>
          </div>
          `,
          showConfirmButton: false,
          timer: 3000
        }).then(()=>props.history.push('/processes'));
      }
    }
  }
  const handleCheck = async(e, position, row) => {
    const {target} = e;
    if (target.name === 'allowed' || (target.name === 'viewed' && target.checked === false )) {
      const update = await axios.put(`${ROOT_URL}/permissions/${row.id}`, {[target.name]: target.checked}, getAuthHeaders());
      if (update.status === 200)
        data.data[select.selected.value].permissions.splice(position,1,update.data)
        setData({data: data.data})
    }
  }
  const renderUser = (index)=>{
    if (data.data[select.selected.value].permissions.some(obj => obj.user_id === users.observers[index].id)) {
      const position = data.data[select.selected.value].permissions.findIndex(permission => (permission.user_id === users.observers[index].id));
      const row = data.data[select.selected.value].permissions[position];
      return (
        <tr key ={index} style={{background: '#fff'}}>
          <td>{index+1}</td>
          <td>{users.observers[index].name}</td>
          <td>{users.observers[index].email}</td>
          <td>{moment(row.deadline).format('DD/MM/YYYY HH:mm')}</td>
          <td>
            {row.viewed ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ):<p>No</p>}
            {/* <input type="checkbox" class="custom-control-input" name="viewed" checked = {row.viewed} onChange={e => handleCheck(e, position, row)}/> */}
          </td>
          <td><input type="checkbox" className="custom-control-input" name="allowed" checked = {row.allowed} onChange={e => handleCheck(e, position, row)}/></td>
          <td>
            <button className="btn btn-info" data-toggle="tooltip" title="Editar deadline" onClick={()=>agregate({position , index})}>
              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      )
    }
    return (
      <tr>
      <td>{index+1}</td>
      <td>{users.observers[index].name}</td>
      <td>{users.observers[index].email}</td>
      <td>--/--/----</td>
      <td>---</td>
      <td>---</td>
      <td>
        <button className="btn btn-success" data-toggle="tooltip" title="Agregar permiso" onClick={()=>agregate({index})}>
          <i className="fa fa-plus" aria-hidden="true"></i>
        </button>
      </td>
      </tr>
    )
  }

  const agregate = ({index = undefined, position = undefined}) =>{
    let row = {};
    if (index !== undefined)
      row = { ...users.observers[index]};

    if (position !== undefined)
      row = {...row, ...data.data[select.selected.value].permissions[position]};

    MySwal.fire({
      title: 'Editar permiso',
      html: `
      <div>
        <label>email</label>
        <input id="newUser" class="swal2-input" value="${row.email}" disabled />
      </div>
      <label>deadline</label>
      <input type="datetime-local" value="${moment(row.deadline).format('YYYY-MM-DD HH:mm') ?? ''}" id="deadline" class="swal2-input required">
      ${(position !== undefined) ? `<label>visto</label><input id="viewed" type="checkbox" ${row.viewed ? 'checked' : 'disabled'} class="custom-control-input"/>`:''}
      
      `,
      text: 'Seleccione una dificultad',
      showCancelButton: true,
      preConfirm: async () => {
        let validate = true;
        let values = {};
        const newUser = Swal.getPopup().querySelector('#newUser')
        const deadline = Swal.getPopup().querySelector('#deadline').value
        if (!newUser.value || !deadline) {
          validate = false;
          Swal.showValidationMessage(`datos incompletos, reviselos e intente denuevo`)
        } else {
          const viewed = Swal.getPopup().querySelector('#viewed')?.checked
          if (position !== undefined) {
            values = { deadline, viewed };
            const update = await axios.put(`${ROOT_URL}/permissions/${row.id}`, values, getAuthHeaders());
            if (update.status === 200) {
              data.data[select.selected.value].permissions.splice(position,1,update.data)
              const temp = data.data;
              setData({data: temp})
            }
          } else {
            values = { user_id:row.id, observation_process_video_id: data.data[select.selected.value].opv_id, deadline, viewed:false, allowed:true };
            const create = await axios.post(`${ROOT_URL}/permissions`, values, getAuthHeaders());
            if (create.status === 200) {
              data.data[select.selected.value].permissions.push(create.data)
              const temp = data.data;
              setData({data: temp})
            }
          }

          
          
        }

      }
    })
  }



  return (
    <>
      <h1>Permisos:</h1>
      <Tabs defaultActiveKey={1} id="permissionTab" onSelect={(e)=>{console.log(e)}}>
        <Tab eventKey={1} title="por usuario">
          <div style={{padding: '20px'}}>
            <label><i className="fa fa-video-camera" aria-hidden="true"></i>  video:</label>
            <Select
              onChange = {val => changeSelect(val)}
              value={select.selected}
              multi={false}
              options={select.options}
            />
            {!loading ? ((data.data) ? (
              <>
              {select.selected ? (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombres</th>
                        <th>Email</th>
                        <th>deadline</th>
                        <th>visto</th>
                        <th>habilitado</th>
                        <th>acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.observers && users.observers.map((observer, i) => 
                        renderUser(i)
                      )}
                    </tbody>
                  </table>
                </>
              ) : (
                <p>Seleccione un video</p>
              )}
              </>
            ) : (
              <>
                <h1>No se encontró el registro</h1>
              </>
            )) : (
              <Loading />
            )}
          </div>
        </Tab>
        <Tab eventKey={2} title="en cantidad">
          <div style={{
            padding: '20px',
            display: 'flex',
            flexFlow: 'column',
            justifCcontent: 'center',
            alignItems: 'center'
          }}>
            <form onSubmit={handlePermissions}>
              <div className="col-md-6 col-xs-6">
                <label>videos</label>
                <MultiSelect
                  onChange = {val => handleChange(val, 'videos')}
                  value={form.videos}
                  options={options.videos}
                />
              </div>
              <div className="col-md-6 col-xs-6">
                <label>observadores</label>
                <MultiSelect
                  onChange = {val => handleChange(val, 'observers')}
                  value={form.observers}
                  options={options.observers}
                />
              </div>
              <div className="col-md-6 col-xs-6">
                <label>fecha cierre* </label>
                <Datetime
                inputProps={{
                    required: true
                }}
                locale='es'
                value={moment(form.deadline)}
                onChange={(value) => handleChange(moment(value).format('YYYY-MM-DD HH:mm'), 'deadline')}
                />
              </div>
              <div className="col-md-3 col-xs-3" style={{display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: '20px'}}>
                <button
                  type="submit"
                  className="btn btn-info">
                  registrar permisos
                </button>
              </div>
            </form>
          </div>
        </Tab>
      </Tabs>
      <div>
        <br></br>
        <br></br>
        <button
          onClick={() => props.history.push('/processes')}
          type="button"
          className="btn btn-danger">
          Volver
        </button>
      </div>
    </>
  );
}

export default Permissions;
