import { GET_LABELS, GET_LABEL, EDIT_LABEL, DELETE_LABEL, CREATE_LABEL, RESET } from '../actions/labelActions';

/**
 * Manejo de la parte del estado correspondiente a los labels para labeling.
 * Permite la actualización de labels, obtener un label por id.
 * Permite eliminar un label por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los labels)
 * @returns El estado con la modificación/acción realizada.
 */
const initialState = {
  labels: [],
  label: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LABELS:
      return {...state,
        labels: action.payload 
      }
    case GET_LABEL:
      return {...state,
        label: action.payload 
      }
    case DELETE_LABEL:
      return {...state,
        label: undefined 
      }
    case RESET:
      return initialState
    default:
      return state;
  }
}