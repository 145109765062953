import { LOG_IN_USER, LOG_OUT_USER, TOGGLE_USER_ROLE,RESET_USER_ROLE } from '../actions/authenticationActions';
import { USER_BECOME,USER_UNBECOME } from '../actions/authenticationActions';
/**
 * Manejo de la parte del estado correspondiente al usuario actual.
 * Permite en inicio de sesión de un usuario (guardado en el estado de su información).
 * Permite cerrar la sesión de un usuario (eliminación en el estado de su información).
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case LOG_IN_USER:
      return action.payload;
    case LOG_OUT_USER:
      return null;
    case TOGGLE_USER_ROLE:
      const role = action.payload
      state[role] = ! state[role]
      // console.log (role, state, state['toggledRoles'])
      if (state['toggledRoles'] === undefined) {
        return  {...state, toggledRoles: [role]}
      } else {
        if (state['toggledRoles'].includes(role)) {
          return  {...state, toggledRoles: state['toggledRoles'].filter(item => item !== role)}
        } else {
          return  {...state, toggledRoles: [...state['toggledRoles'] , role]}
        }
      }
    case RESET_USER_ROLE:
      // console.log(`reset ${state['toggledRoles']}`)
      state['toggledRoles'].forEach(role => {
        state[role] = ! state[role]
      });
      state['toggledRoles'] = undefined
      return {...state}
    case USER_BECOME:
      const become_user = action.payload
      return { ...become_user, impersonator : state}
    case USER_UNBECOME:
      state['impersonating'] = undefined
      return state.impersonator || null
    default:
      return state;
  }
}
