import _ from 'lodash';
import { GET_DEPARTMENTS, GET_DEPARTMENT, GET_DEPARTMENT_EXAMS, GET_DEPARTMENT_SEMESTERS} from '../actions/departmentsActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
      case GET_DEPARTMENTS:
        const departments = _.mapKeys(action.payload.departments, 'id');
        const pagination = action.payload.links;
        return { ...state, departments: departments, pagination: pagination};
      case GET_DEPARTMENT:
        const department = action.payload;
        return { ...state, [department.id]: department };
      case GET_DEPARTMENT_EXAMS:
        const exams= action.payload.exams;
        return { ...state, exams: exams };
      case GET_DEPARTMENT_SEMESTERS:
          const semesters= action.payload.semesters;
          return { ...state, semesters: semesters };
      default:
        return state;
    }
  }
  