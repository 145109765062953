import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las pruebas (preguntas) de un examen.

// Actualiza todos los cuestionarios en el estado de la aplicación.
export const GET_QUESTIONARIES = 'GET_QUESTIONARIES';

export const GET_QUESTIONARY = 'GET_QUESTIONARY';

export const DELETE_QUESTIONARY ='DELETE_QUESTIONARY';

export const GET_APPROVE_QUESTIONARY ='GET_APPROVE_QUESTIONARY';

export const GET_QUESTIONARY_MESSAGE='GET_QUESTIONARY_MESSAGE';


/**
 * Obtiene los cuestionarios disponibles.
 *
 * @export getTests
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getQuestionaries(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
    if (pagination===undefined){
      pagination = ""
    }
    const request = axios.get(`${ROOT_URL}/mc_questionaries${pagination}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_QUESTIONARIES,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  
  /**
   * Obtiene el cuestionario con el id dado por parámetro.
   *
   * @export getQuestionary
   * @param {*} id : El id de la pregunta.
   * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
   * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
   *                                           La función debe recibir por parámetro el error generado.
   * @returns Función de manejo de error o éxito de la petición.
   */
  export function getQuestionary(id, successCallback = () => {}, errorCallback = (error) => {}) {
    
    const request = axios.get(`${ROOT_URL}/mc_questionaries/${id}`, getAuthHeaders());
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_QUESTIONARY,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

   /**
 * Edita un cuestionario con los datos dados por parámetro
 *
 * @export editQuestionary
 * @param {*} questionary : Información del cuestionario a editar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editQuestionary(id, questionary, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/mc_questionaries/${id}`, {questionary: questionary}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_QUESTIONARY,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  /**
 * Crea un cuestionario con los datos dados por parámetro
 *
 * @export createQuestionary
 * @param {*} questionary : Información del examen a crear.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createQuestionary(questionary, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.post(`${ROOT_URL}/mc_questionaries`, {questionary: questionary}, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_QUESTIONARY,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  /**
 * Borrado del cuestionario con el id dado por parámetro.
 *
 * @export deleteQuestionary
 * @param {*} id : El id del examen a eliminar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteQuestionary(id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.delete(`${ROOT_URL}/mc_questionaries/${id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: DELETE_QUESTIONARY,
          payload: id
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  
  /**
 * Aprobacion del cuestionario con el id dado por parámetro.
 *
 * @export approveQuestionary
 * @param {*} id : El id del examen a aprobar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function approveQuestionary(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/mc_questionaries/${id}/approve`,null, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
       type: GET_APPROVE_QUESTIONARY,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


  
  /**
 * se añaden participantes a la vista previa del cuestionario (reciben el link de acceso)
 *
 * @export addParticipantsToQuestionaryPreview
 * @param {*} participantsInfo : El documento xlsx con los correos y el link en string
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function addParticipantsToQuestionaryPreview(participantsInfo, successCallback = () => {}, errorCallback = (error) => {}) {

  const request = axios.patch(`${ROOT_URL}/mc_questionaries/add_participants_preview`, participantsInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_QUESTIONARY_MESSAGE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}


