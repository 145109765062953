import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import swal from 'sweetalert2';
import Loading from '../Loading';
import { logInUser, sendLoginError } from '../../actions/authenticationActions';
import { REDIRECT_URI } from '../../actions/constants';

/**
 * Componente para le manejo del inicio de sesión.
 *
 * @class LogIn
 * @extends {Component}
 */
class LogIn extends Component {
  componentDidMount() {
    const values = {
      code: qs.parse(this.props.location.search).code,
      redirect_uri: REDIRECT_URI,
      type_of_account: qs.parse(this.props.location.search).state
    };
    this.props.logInUser(values, () => {
      this.props.history.push('/');
    }, (error) => {
      sendLoginError(error);
      swal(
        'Lo sentimos...',
        'Ocurrió un error al tratar de iniciar sesión',
        'error'
      );
    });
  }

  render() {
    return (
      <Loading />
    );
  }
}

export default connect(null, { logInUser })(LogIn);
