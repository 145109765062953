import _ from 'lodash';
import { GET_PENDING_EXAMS } from '../actions/answersActions';

/**
 * Manejo de la parte del estado correspondiente a respuestas pendientes (con estado pendiente).
 * Permite la actualización de respuestas pendientes.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las respuestas pendientes)
 * @returns El estado con la modificación/acción realizada.
 */
export default function(state = {}, action) {
  switch(action.type) {
    case GET_PENDING_EXAMS:
        const pendingExams = action.payload.exams
        const pagination = action.payload.links;
        const totalAnswers = action.payload.totalAnswers;
        const label = action.payload.label;
      return {...state, pendingExams: pendingExams, pagination: pagination,  totalAnswers: totalAnswers, label:label};
    default:
      return state;
  }
}