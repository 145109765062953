/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector , useDispatch} from 'react-redux';
import { getLabel, editLabel, createLabel, getLabels, replaceLabel, resetState } from "../../actions/labelActions";
import swal from "sweetalert2";
import Select from "react-select";
import { getGradingMatrices } from "../../actions/gradingMatricesActions";
import { getThemes } from "../../actions/themeActions";
import { getGradingCriterias } from "../../actions/gradingCriteriaActions";
import { getGradingCriterionOptions } from "../../actions/gradingCriteriaOptionActions";
import ReactQuill from 'react-quill';
import Loading from '../Loading';

// constantes
const STYLES = {
    main: {
        display: 'flex',
        minHeight: '75vh',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
    },
    box_style: {
        borderRadius: "15px",
        borderColor: "#E5E4E4",
        borderStyle: "solid",
        borderWidth: "thin",
        padding: "10px 0px",
        marginBottom: "9px"
    }
}
const MODULES = {
    toolbar: false
}

// componente
const LabelForm = (props) => {

    // constantes sacadas del redux
    const label = useSelector(state => state.labels.label)
    const themes = useSelector(state => state.themes?.themes)
    const matrices = useSelector(state => state.gradingMatrices?.gradingMatrices)
    const gradingCriterias = useSelector(state => state.gradingCriterias?.gradingCriterias)
    const gradingCriterionOptions = useSelector(state => state.gradingCriterionOptions?.gradingCriterionOptions)

    // constantes de los props
    const matrix_id = props.match.params.matrix_id
    const id = props.match.params.id

    // estados
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState({
        themes: [{label: 'sin tema', value: undefined}],
        matrices: [{label: 'sin matriz', value: undefined}],
        criterias: [{label: 'sin criterio', value: undefined}],
        gradingCriterias: [{label: 'sin nivel', value: undefined}]
    })
    const [form, setForm] = useState({
        name: '',
        description: '',
        theme_id: {label: 'sin tema', value: undefined},
        matrix_id: {label: 'sin matriz', value: undefined},
        criterion_id: {label: 'sin criterio', value: undefined},
        level_id: {label: 'sin nivel', value: undefined},
        color: '000'
    })

    // funciones
    const dispatch = useDispatch()

    const handleSubmit = e => {
        e.preventDefault()
        const data = validateForm()
        if (data) {
            const successUpdate = () => {
                swal({
                    type: 'success',
                    title: `Ventana ${id ? 'actualizada' : 'Creada'}`,
                    showConfirmButton: false,
                    timer: 1000
                })
                .then(()=> props.history.push(matrix_id ? `/gradingMatrices/${matrix_id}/labels` : '/labels'))
            }
            if (id) {
                dispatch(editLabel(id,data, successUpdate()))
            } else {
                dispatch(createLabel(data, successUpdate()))
            }
        }
    }

    const validateForm = () => {
        const data = {
            name: form.name,
            description: form.description,
            color: form.color,
            theme_id: form.theme_id.value,
            matrix_id: form.matrix_id.value,
            criterion_id: form.criterion_id.value,
            level_id: form.level_id.value,
        }

        if (form.theme_id.value === undefined && form.matrix_id.value === undefined) {
            return false
        }

		return data
	}

	const changeSelect = (key, value) => {
		if (key === 'matrix_id') {

			if (value && value.value !== undefined) {
				setOptions(currentState => ({
					...currentState,
					criterias: [ ...gradingCriterias.filter(criterias => criterias.grading_matrix_id === value.value).map(obj => ({ label: obj.title, value: obj.id })), {label: 'sin criterio', value: undefined} ]
				}))
			} 

			return setForm(currentState => ({
				...currentState,
				[key]: value ? value : {label: 'sin matriz', value: undefined},
				criterion_id: {label: 'sin criterio', value: undefined},
				level_id: {label: 'sin nivel', value: undefined}
			}))
		}

		if (key === 'criterion_id') {
			if (value && value.value !== undefined) {
				setOptions(currentState => ({
					...currentState,
					gradingCriterias: [ ...gradingCriterionOptions.filter(criterias => criterias.grading_matrix_id === form.matrix_id.value).map(obj => ({ label: obj.title, value: obj.id })), {label: 'sin nivel', value: undefined}]
				}))
			}

			return setForm(currentState => ({
				...currentState,
				[key]: value ? value : {label: 'sin criterio', value: undefined},
				level_id: {label: 'sin nivel', value: undefined},
			}))
		}

		
		setForm({
			...form,
			[key]: value || undefined
		})
	}

    // efectos
    useEffect(() => {
        if (id) {
            setLoading(true)
            dispatch(getLabel(id, ()=> {setLoading(false)}, ()=> {setLoading(false)}))
        }
        dispatch(getThemes())
        dispatch(getGradingMatrices())
        dispatch(getGradingCriterias())
        dispatch(getGradingCriterionOptions())
    },[])

    useEffect(() => {
        if (id && label) {
            setForm({ ...form, name: label.name, color: label.color, description: label.description })
            if (label.theme) {
                setForm(currentState => ({ ...currentState, theme_id: {label: label.theme.name, value: label.theme.id['$oid']} }))
            }

            if (label.matrix) {
                setForm(currentState => ({ ...currentState, matrix_id: { label: label.matrix.title, value: label.matrix.id }}))
                setOptions(currentState => ({
                    ...currentState,
                    criterias: [ ...gradingCriterias.filter(criterias => criterias.grading_matrix_id === label.matrix.id).map(obj => ({ label: obj.title, value: obj.id })), {label: 'sin criterio', value: undefined} ]
                }))
            }

            if (label.criterion) {
                setForm(currentState => ({ ...currentState, criterion_id: { label: label.criterion.title, value: label.criterion.id } }))
                setOptions(currentState => ({
                    ...currentState,
                    gradingCriterias: [ ...gradingCriterionOptions.filter(criterias => criterias.grading_matrix_id === label.matrix.id).map(obj => ({ label: obj.title, value: obj.id })), {label: 'sin nivel', value: undefined}]
                }))
            }

            if (label.level) {
                setForm(currentState => ({ ...currentState, level_id:  { label: label.level.title, value: label.level.id } }))
            }
        }
    },[label])


    useEffect(() => {
        if (Object.values(matrices).length > 0) {
            console.log(matrices)
            setOptions(currentState => ({
                ...currentState,
                matrices: [...Object.values(matrices).map(obj => ({ label: obj.title, value: obj.id }))]
            }))
        }

        if (themes.length > 0) {
            setOptions(currentState => ({
                ...currentState,
                themes: [{label: 'sin tema', value: undefined}, ...themes.map(obj => ({ label: obj.name, value: obj.id['$oid'] }))]
            }))
        }
    },[matrices, themes])

    if (loading) {
        return (<Loading />)
    } else {
        return (<>
            <form onSubmit={handleSubmit}>
            <section>
                <h1>{id ? 'Editar' : 'Crear'} Etiqueta</h1>
                <div className="form-group">
                    <div style={STYLES.box_style} className="row">
                        <div className="col-md-10 col-xs-10">
                            <label htmlFor='name'>
                                Nombre
                            </label>
                            <input
                                name="name"
                                required
                                type="text"
                                id="name"
                                className='form-control'
                                value={form.name}
                                onChange={({target}) => setForm({ ...form, name: target.value })}
                            />
                        </div>
                        <div className="col-md-2 col-xs-2">
                            <label htmlFor='labelColor'>
                                Color
                            </label>
                            <input
                                required
                                type="color"
                                id="labelColor"
                                className="form-control"
                                value={form.color}
                                onChange={({target}) => setForm({ ...form, color: target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div style={STYLES.box_style} className="row">
                        <div className="col-md-12 col-xs-12">
                            <label htmlFor="quill-container">Descripción</label>
                            <div id="quill-container" style= {{height: "127px", margin: '2rem 0'}} autoComplete="off" spellCheck="false">
                                <ReactQuill
                                    style= {{height: "127px"}}
                                    name="description"
                                    modules={MODULES}
                                    value={form.description}
                                    onChange={(newValue)=> changeSelect('description', newValue)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div style={STYLES.box_style} className="row">
                        <div className="col-md-12 col-xs-12">
                            <label htmlFor="tema">
                                Tema
                            </label>
                                <Select
                                    id="tema"
                                    onChange = {(val)=> changeSelect('theme_id', val)}
                                    class="form-control"
                                    value={form.theme_id}
                                    required={true}
                                    options={options.themes}
                                />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div style={STYLES.box_style} className="row">
                        <div className={`col-md-${form.matrix_id.value !== undefined ? ( form.criterion_id.value === undefined ? '6' : '4' ) : '12'} col-xs-4`}>
                            <label htmlFor="matriz">
                                Matriz
                            </label>
                            <Select
                                id="matriz"
                                onChange = {(val)=> changeSelect('matrix_id', val)}
                                value={form.matrix_id}
                                // multi={true}
                                required={true}
                                options={options.matrices}
                            />
                        </div>
                        <div className={`col-md-${(form.matrix_id.value !== undefined && form.criterion_id.value !== undefined) ? '4' : '6'} col-xs-4`}>
                            {form.matrix_id.value !== undefined && (
                                <>
                                    <label style={{width: '250px'}} htmlFor="criterio">
                                        Criterio
                                    </label>
                                    <Select
                                        id="criterio"
                                        disabled={form.matrix_id.value === undefined}
                                        onChange = {(val)=> changeSelect('criterion_id', val)}
                                        value={form.criterion_id}
                                        // multi={true}
                                        required={true}
                                        options={options.criterias}
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-md-4 col-xs-4">
                            {(form.matrix_id.value !== undefined && form.criterion_id.value !== undefined) && (
                                <>
                                    <label htmlFor="nivel">
                                        Nivel
                                    </label>
                                    <Select
                                        id="nivel"
                                        disabled={form.criterion_id.value === undefined}
                                        onChange = {(val)=> changeSelect('level_id', val)}
                                        value={form.level_id}
                                        // multi={true}
                                        required={true}
                                        options={options.gradingCriterias}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                }}
            >
                <button
                    onClick={() => props.history.push( matrix_id ? `/gradingMatrices/${matrix_id}/labels` : '/labels')}
                    type="button"
                    className="btn btn-danger">
                    Volver
                </button>
                <button
                    type="submit"
                    className="btn btn-info">
                    {id ? 'Actualizar' : 'Crear'} Etiqueta
                </button>
            </section>
            </form>
        </>)

    }
}

export default LabelForm