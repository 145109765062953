import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las pruebas (preguntas) de un examen.

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_TESTS = 'GET_TESTS';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_TEST = 'GET_TEST';

// Remueve la pregunta del estado de la aplicación.
export const DELETE_TEST = 'DELETE_TEST';

// Constantes sin uso por ahora.

// Actualiza el codificador de una pregunta en el estado.
export const UPDATE_TEST_GRADER = 'UPDATE_TEST_GRADER';

// Actualiza el estado de vista de actualización de un codificador.
export const TOOGLE_UPDATE_GRADER = 'TOOGLE_UPDATE_GRADER';


/**
 * Obtiene las preguntas disponible.
 *
 * @export getTests
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTests(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/tests${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TESTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getTest
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTest(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/tests/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

// Dispatch del cambio de estado para el manejo de edición del codificador.
export const setUpdateToogleGrader = ( ) => ({
      type: TOOGLE_UPDATE_GRADER
})

/**
 * Crea una nueva pregunta con los datos dados por parámetro.
 *
 * @export createTest
 * @param {*} test : Objecto con la información de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createTest(test, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/tests`, {test: test}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la pregunta con el id dado por parámetro.
 *
 * @export updateTest
 * @param {*} id : El id del test dado por parámetro
 * @param {*} test : El objeto con los datos de la nueva pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateTest(id, test, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/tests/${id}`, {test: test}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina la pregunta con el id dado por parámetro.
 *
 * @export deleteTest
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteTest(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/tests/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: DELETE_TEST,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

// -----------------------------------------------------------------------------------------------------------
// Sin uso por ahora
// -----------------------------------------------------------------------------------------------------------
/**
 * Actualiza los codificadores asignados a la pregunta por nivel (Pensado antes de que hubieran examenes).
 *
 * @export editParticipantsByLevelTest
 * @param {*} id : El id de la pregunta
 * @param {*} participantsInfo : La información de codificadores
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editParticipantsByLevelTest(id, participantsInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/tests/${id}/edit_participants_by_level`, participantsInfo, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza los codificadores asignados a la pregunta (Pensado antes de que hubieran examenes).
 *
 * @export editParticipantsByLevelTest
 * @param {*} id : El id de la pregunta
 * @param {*} participantsInfo : La información de codificadores
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editParticipantsTest(id, participantsInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/tests/${id}/edit_participants`, participantsInfo, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Elimina la asignación de un codificador a la pregunta dada.
 *
 * @export removeGraderTest
 * @param {*} id : El id de la pregunta.
 * @param {*} graderId : El id del codificador.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function removeGraderTest(id, graderId, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/tests/${id}/remove_grader`, { grader_id: graderId }, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualización del codificador de una pregunta.
 *
 * @export updateGraderTest
 * @param {*} id : El id de la pregunta.
 * @param {*} graderIdRemove : El id del codificador a cambiar.
 * @param {*} graderIdNew : El id del nuevo codificador.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateGraderTest(id, graderIdRemove, graderIdNew, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/tests/${id}/update_grader`, { grader_id_remove: graderIdRemove,
                                                                         grader_id_new: graderIdNew }, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: UPDATE_TEST_GRADER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
