import React, { Component } from 'react';
import _ from 'lodash';
import differenceBy from 'lodash/differenceBy';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

/**
 * Formulario para elección de codificadores y revisores
 *
 * @class ParticipantsForm
 * @extends {Component}
 */
class ParticipantsForm extends Component{

    constructor(props) {
        super(props);
        this.state = {
          gradersOptionsLoading: true,
          gradersOptions: [],
          reviewersOptionsLoading: true,
          reviewersOptions: [],
          isUpdating:true
        };
    }

    componentDidMount(){
        if(this.state.isUpdating){
            let allGraders = [];
            let allReviewers = [];
            let gradersOptions = [];
            let reviewersOptions = [];
            if(this.props.graders && this.props.reviewers){
                allGraders = this.receiveParticipants(this.props.graders,this.props.gradersLevels);
                allReviewers = this.receiveParticipants(this.props.reviewers,this.props.reviewersLevels);

                gradersOptions = allGraders;
                reviewersOptions = allReviewers;
            }
            if(this.props.gradingMatrix){
                let onMatrixGraders = [];
                let onMatrixReviewers = [];
                onMatrixGraders = this.receiveParticipants(this.props.gradingMatrix.graders,this.props.gradersLevels);
                onMatrixReviewers = this.receiveParticipants(this.props.gradingMatrix.reviewers,this.props.reviewersLevels);
                gradersOptions = this.intersectParticipants(gradersOptions, onMatrixGraders);
                reviewersOptions = this.intersectParticipants(reviewersOptions, onMatrixReviewers);
            }

            this.setState({gradersOptions: gradersOptions,
                           gradersOptionsLoading: false,
                           reviewersOptions: reviewersOptions,
                           reviewersOptionsLoading: false,
                           isUpdating: false});
        }
    }

    receiveParticipants(participantList,gradersLevels) {
        if(!_.isEmpty(participantList)) {
          return _.map(participantList, (participant) => {
            if(gradersLevels){
              return {
                label: participant.email+" ("+gradersLevels[participant.id]+")",
                value: participant.id
              };
            }else{
              return {
                label: participant.email,
                value: participant.id
              };
            }
          });
        } else {
          return undefined;
        }
    }

    intersectParticipants(allParticipants, onMatrixParticipants){
        let participantOpt = [];
        if(onMatrixParticipants === undefined){
            participantOpt = allParticipants;
        } else {
            participantOpt = differenceBy(allParticipants, onMatrixParticipants, 'value');
        }
        return participantOpt;
    }

    renderParticipantsSelect = ({ input: { onChange, value }, placeholder, options, isLoading }) => {
        return (
          <Select
            onChange={onChange}
            value={value || []}
            placeholder={placeholder}
            multi={true}
            options={options}
            isLoading={isLoading}
            required={false}
          />
        );
      };

    render() {
        const { handleSubmit } = this.props;
        return (<form onSubmit={handleSubmit(this.props.onFormSubmit)}>
                    <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <h4>Codificadores</h4>
                        <div className="form-group">
                        <Field
                            name="graders_ids"
                            placeholder="Codificadores"
                            options={this.state.gradersOptions}
                            isLoading={this.state.gradersOptionsLoading}
                            component={this.renderParticipantsSelect}
                        />
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <h4>Revisores</h4>
                        <Field
                        name="reviewers_ids"
                        placeholder="Revisores"
                        options={this.state.reviewersOptions}
                        isLoading={this.state.reviewersOptionsLoading}
                        component={this.renderParticipantsSelect}
                        />
                    </div>
                    </div>
                    {this.props.module === 'AssignationNew' && ( //hace que se renderice el campo de descripción solo si fue llamada con el prop module = AssignationNew
                      <div>
                        <div className="row">
                          <div className="col-md-12">
                            <h3>Descripción de la asignación</h3>
                            <p>A continuación puede ingresar un texto para describir la asignación que se va a crear</p>                    
                          </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <Field
                                  component="textarea"
                                  name="description"
                                  rows="10"
                                  required
                                  placeholder="Descripción de la asignación"
                                  className="form-control"
                                />
                              </div>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            Marque esta casilla si desea que se incluyan respuestas no finalizadas.
                            <Field
                              name="all_answers"
                              component="input"
                              type ="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    )}                    
                    <button
                    type="submit"
                    className="btn btn-primary pull-right">
                    Guardar
                    </button>
                </form>);
    }
}

ParticipantsForm = reduxForm({
    form: 'ParticipantsForm'
  })(ParticipantsForm)

export default ParticipantsForm;
