import React, { Component, useEffect, useState } from 'react';
import Loading from '../Loading';
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronRight, BsChevronDoubleRight } from 'react-icons/bs';
import {ButtonGroup} from 'react-bootstrap';
/**
 * Componente de paginación. Recibe por props las opciones de paginación (last, next, prev, first)
 * y una función a la cual pasar las opciones.
 *
 * @export
 * @class Pagination
 * @extends {Component}
 */

const PaginationSearch  = ({paginationOptions, search = '', extraParams = {}, onPagination}) => {
    const [updating, setUpdating] = useState(false)

    useEffect(()=>{
        setUpdating(false)
    },[paginationOptions])

    const onPaginationClick = (option) =>{
        setUpdating(true)
        onPagination(option);
    }

    const getNumberPage = (string) => {
      return string.split(/[?&]?page?%5\w+%5D=/gm)[1]
    }
    
    const objConfig = {display: 'inline-block', verticalAlign: 'middle'} 
    const paginationStyles = {listStyle: 'none', display: 'flex', flexFlow: 'column nowrap'}

    if (updating){
        return <div align="center">
                <Loading />
                </div>;
    } else {
        return (
            <div align="center">
                <ul style={paginationStyles}>
                    <li>
                    <ButtonGroup>
                        {paginationOptions.first &&
                            <button className="btn btn-default color-blue d-flex" onClick={()=>{onPaginationClick({...extraParams, pagination: paginationOptions.first, search})}}>
                                <BsChevronDoubleLeft style={objConfig} />
                            </button>
                        }
                        {paginationOptions.prev &&
                            <button className="btn btn-default color-blue" onClick={()=>{onPaginationClick({...extraParams, pagination: paginationOptions.prev, search})}}>
                                <BsChevronLeft style={objConfig} />
                            </button>
                        }
                        {paginationOptions.next &&
                            <button className="btn btn-default color-blue" onClick={()=>{onPaginationClick({...extraParams, pagination: paginationOptions.next, search})}}>
                                <BsChevronRight style={objConfig} />
                            </button>
                        }
                        {paginationOptions.last &&
                            <button className="btn btn-default color-blue" onClick={()=>{onPaginationClick({...extraParams, pagination: paginationOptions.last, search})}}>
                                <BsChevronDoubleRight style={objConfig} />
                            </button>
                        }
                    </ButtonGroup>
                    </li>
                    <li>
                    página {getNumberPage(paginationOptions.self)} de {getNumberPage(paginationOptions.last || paginationOptions.self)}
                    </li>
                </ul>
            </div>
        );
    }
}

export default PaginationSearch