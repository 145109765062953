import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import moment from 'moment/moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ROOT_URL } from '../../../actions/constants';
import { generateExamTestReports, getExamTestReports} from '../../../actions/reportsActions';
import ReportList from '../../reports/ReportList';
import { FORBIDDEN_STATUS_CODE,REPORT_PAGINATION, COURSE_REPORT_COLORS, DEFAULT_DATA_TABLE_ORDER } from '../../../actions/constants';
import Loading from '../../Loading';
import {Chart} from 'chart.js'
import _ from 'lodash';
import html2pdf from 'html2pdf.js';
import IconTooltip from '../../utils/IconTooltip';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import TestQuantities from './TestQuantities';

/**
 * Componente para mostrar y descargar el reporte de curso
 *
 * @class CoursesReport
 * @extends {Component}
 */
class Report extends Component {
  constructor(props) {
    super(props);
    this.downloadXls = this.downloadXls.bind(this);
    this.onReloadReport = this.onReloadReport.bind(this);
    this.onPaginateReport = this.onPaginateReport.bind(this);
    this.onGenerateReportSubmit = this.onGenerateReportSubmit.bind(this);
    this.onChangeDefaultReport = this.onChangeDefaultReport.bind(this);
    this.state = {
      currentReport:0
    };
  }

  componentDidMount() {
    if ( $.fn.dataTable.isDataTable('#tblHistoricStudents')){
      $('#tblHistoricStudents').DataTable().destroy()
   }
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
      $('#tblHistoricStudents').DataTable(DEFAULT_DATA_TABLE_ORDER)
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }
    this.props.getExamTestReports(this.props.exam_test_option_id, REPORT_PAGINATION, successCallback,errorCallback);

  }


  onPaginateReport(paginate){
    if ( $.fn.dataTable.isDataTable('#tblHistoricStudents')){
      $('#tblHistoricStudents').DataTable().destroy()
   }
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
      $('#tblHistoricStudents').DataTable(DEFAULT_DATA_TABLE_ORDER)
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }
    this.props.getExamTestReports(this.props.exam_test_option_id, REPORT_PAGINATION, successCallback,errorCallback);
  }
  onReloadReport(){
    if ( $.fn.dataTable.isDataTable('#tblHistoricStudents')){
      $('#tblHistoricStudents').DataTable().destroy()
   }
    const successCallback = () => {
      swal({
        type: 'success',
        showConfirmButton: false,
        timer: 1000
      });
      $('#tblHistoricStudents').DataTable(DEFAULT_DATA_TABLE_ORDER)
    };
    const errorCallback = (error) => {
      console.log('Error creating the exam', error.request);
      if(error.request.status === FORBIDDEN_STATUS_CODE) {
        this.props.history.push('/');
        swal(
          'Ups...',
          `Ocurrió un error al tratar de obtener los reportes: ${JSON.parse(error.request.response).error ||JSON.parse(error.request.response).title}`,
          'error'
        );
      } else{
        swal(
          'Ups...',
          'Ocurrió un error al tratar de obtener los reportes',
          'error'
        );
      };
    }

    this.props.getExamTestReports(this.props.exam_test_option_id, REPORT_PAGINATION, successCallback,errorCallback);

  }
  
  componentDidUpdate(prevProps, prevState){
    if(this.props.reports !==null && this.props.reports !== prevProps.reports &&
      this.props.reports.reportList[this.state.currentReport]!==undefined ){
      this.criteria_to_graph(this.props.reports.reportList[this.state.currentReport].reportInfo)
    }
    if(this.state.currentReport!== prevState.currentReport ){
      this.criteria_to_graph(this.props.reports.reportList[this.state.currentReport].reportInfo)
    }
  }
  renderGrades = (level_grades, criteria) => {
    level_grades = level_grades.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    
    if (_.isEmpty(level_grades)){
      return (criteria.map((criterion) => {
        return (
          <td>-</td>
        );
      }))
    }else{
      return level_grades.map((grade) => {
        if(grade.grading_criterion) {
          return (
            <td className={grade.level_class}>{grade.level}</td>
          );
        } else {
          return (
            <td key={grade.level}>-</td>
          );
        }
    });
  }
  }
  excelReportLink() {
    const { assignation } = this.props;
    const params = `user_email=${sessionStorage.getItem('email')}&user_token=${sessionStorage.getItem('authenticationToken')}`;

    return `${ROOT_URL}/tests/${assignation.id}/report.xlsx?${params}`;
  }

  downloadXls(elementId, filename){
    const table_div = document.getElementById(elementId);

    const reporte = XLSX.utils.table_to_book(table_div, {sheet:"Reporte"});
    const archivo_reporte = XLSX.write(reporte, {bookType:'xlsx', bookSST:true, type: 'binary', cellStyles: true});
    var buffer = new ArrayBuffer(archivo_reporte.length);
    var view = new Uint8Array(buffer);
    for (var i=0; i<archivo_reporte.length; i++) view[i] = archivo_reporte.charCodeAt(i) & 0xFF;

    saveAs(new Blob([buffer],{type:""}), filename);
  }

  downloadAsPdf(elementId, filename) {
    const report = document.getElementById(elementId);
    const options = { margin: [5, 5, 5, 5],
                      filename: filename,
                      image: { type: 'png', quality: 1 },
                      html2canvas: { logging: true },
                      jsPDF: { unit: 'mm', format: 'a4', orientation: 'l'}
                    };
    html2pdf().from(report).set(options).toPdf().save();
  }

 onChangeDefaultReport(newCurrentReport){
        this.setState({
            currentReport: newCurrentReport
        });
  }
  onGenerateReportSubmit(event) {
    event.preventDefault();
    swal({
      title: '¿Estás seguro?',
      text: 'Se generará un nuevo reporte a la fecha',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if(result.value) {
        const successCallback = () => {
          swal({
            type: 'success',
            title: 'El reporte se está generando',
            showConfirmButton: false,
            timer: 1200
          });
        };

        const errorCallback = (error) => {
          console.log('Error overwriting the grade', error.request);
          if(error.request.status === FORBIDDEN_STATUS_CODE) {
            swal(
              'Ups...',
              `Ocurrió un error al tratar de generar el reporte`,
              'error'
            );
          } else {
            swal(
              'Ups...',
              'Ocurrió un error al tratar de generar el reporte',
              'error'
            );
          }
        };

        this.props.generateExamTestReports(this.props.exam_test_option_id, successCallback, errorCallback);

      }
    });
  }

  renderCriteriaReport(course){
    return (
      <div >
      {course.criteria.map((criterion) => {
      return (
        <div>
        <div id={criterion.title+"report"} className=" col-xs-12">
        <div  className=" col-xs-8">
        <h3>Distribución de resultados para el criterio: {criterion.title} </h3>
        <p> {criterion.description} </p>
          <table id={criterion.id + "tbl"} className="table table-responsive  table-striped">
                <thead>
                  <tr>
                    <th>Nivel </th>
                    <th>Cantidad de resultados </th>
                    <th>Porcentaje </th>
                  </tr>
                </thead>
                <tbody>
                {criterion.levels? criterion.levels.map((level) => {
                    if(level==null){

                    }else{
                    return (
                      <>
                      {level.level_count? 
                      <tr key= {level.id} > 
                        <td>{level.level}</td>
                        <td>{level.level_count}</td>
                        <td>{(level.level_count/course.total_ans *100).toFixed(1)}%</td>
                        </tr>
                      : 
                      <tr>
                      <td>{level.level}</td>
                      <td>{level.grading_criterion_option}</td>
                      <td>0</td>
                      <td>0</td>
                      </tr>
                      }
                    </>
                    )};
                }):"a"}
                </tbody>
              </table>
          </div>
         <div className="graphic col-xs-4">
            <br></br>
            <div id={"div-"+ criterion.title} ><canvas id={criterion.title}  height="500px" width="500px"></canvas></div>
          </div>  
        </div>
        <div className="html2pdf__page-break"></div>
        </div>
      )})}
      </div>
      
    );
  }
  criteria_to_graph(course){
    course.criteria.forEach(criterion=>{
      this.render_graph(this.options_to_graph(criterion, course.total_ans));
    })
  }
  options_to_graph(criterion, total_ans){
    var data = [];
    var labels =[];
    var dataColor= [];
    var max = total_ans;
    var i=0
    if (criterion.levels){ 
    criterion.levels.forEach(element =>{
      if(element!=null){
      dataColor[i]=COURSE_REPORT_COLORS[element.value]
      data[i]= element.level_count
      labels[i]= element.level
      i=i+1;}
    })}
    var options = { title: criterion.title,
                    labels: labels,
                    data: data,
                    dataColor: dataColor,
                    max: max}
    return options
  }

  render_graph(options){
    document.getElementById("report-container").hidden= false
  if (options.data.length===0){
    document.getElementById("report-container").hidden= true
  }else{
      if(document.getElementById(`div-${options.title}`)==null){
        return 
      }
      document.getElementById(`div-${options.title}`).innerHTML = `<canvas id="${options.title}" height="500px" width="500px" ></canvas>`;
      var ctx = document.getElementById(options.title).getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
            labels: options.labels,
            datasets: [{
                data: options.data,
                backgroundColor: options.dataColor,
                borderColor: options.dataColor,
                borderWidth: 1
            }]
        },
        options: {
          legend: {
            display: false
            },
            scales: {
                yAxes: [{
                  ticks: {
                    min: 0,
                    max: options.max,
                    callback: function (value) {
                      return (value / options.max * 100).toFixed(0) + '%'; 
                    },
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Porcentaje'
                  },
                }
              ]
            }
        }
      });
    }
  }

  renderStudentsReport(course){
    return (
      <div>
      <div className= "col-md-12">
            <h3>Resultados de estudiantes del curso</h3>
              <table id="tblHistoricStudents" className="table table-striped table-bordered dt-responsive">
                <thead className="height">
                  <tr className="height">
                    <th >Código</th>
                    <th >Estudiante</th>
                    <th >Examen</th>
                    <th >Periodo</th>  
                    <th >Carrera</th>                              
                    {course.criteria.map((criterion) => {
                      return (
                        <th >{criterion.title}</th>
                      );
                    })}
                    <th >Promedio</th>  
                  </tr>
                </thead>
                <tbody>
                  {course.students.map((student) => {
                    return (
                      <tr key={student.id}>
                        <td>{student.code}</td>
                        <td>{student.email}</td>
                        <td>{student.exam}</td>
                        <td>{student.semester}</td>
                        <td>{student.career}</td>
                        {this.renderGrades(student.level_grades, course.criteria)}
                        {student.averages? <>
                        <td className={student.averages.average_class} >{student.averages.average.toFixed(2)}</td>
                        </> 
                        : <>
                        <td>-</td>
                          </> }
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
            <div className="col-md-12 col-xs-12">
              <button
                onClick={() => {
                  let fecha = new Date();
                  fecha = moment(fecha).format('D [de] MMMM [de] YYYY');
                  let examen = course.exam ? '_'+course.exam : ''
                  this.downloadXls('tblHistoricStudents',
                                  `Reporte de estudiantes ${course.name}${examen}_${fecha}.xlsx`)
                }}
                type="button"
                className="btn btn-default pull-right"
              ><IconTooltip text="Descargar" icon="fa-download"/>
              </button>
            </div>
      </div>
    );
  }

  renderCourseReport(course) {
    if(!course) {
      return (
        
          <Loading />
      );
    } else if(course) {
      return (
        <div>
          <div id="total_report">
            <h1> Reporte de codificaciones para el examen: {course.name}</h1>
            <h4>En la pregunta <strong>{course.exam}</strong></h4>
            <br></br>
            <TestQuantities exam_test_option_id={this.props.exam_test_option_id} />
            <br></br>
            <div id="report-container">
              {this.renderCriteriaReport(course)}
            </div>
            <div className="row">
              <button className="btn btn-default pull-right"
                    onClick={() => {
                      let fecha = new Date();
                      fecha = moment(fecha).format('D [de] MMMM [de] YYYY');
                      this.downloadAsPdf('report-container',
                                        `Reporte de distribución ${course.name}_${fecha}.pdf`)}}>
                    <IconTooltip text="Descargar" icon="fa-download"/>
              </button>
            </div>
            <div id="report-historic-course-container">
              {this.renderStudentsReport(course)}
            </div>
          </div>
        </div>
      );
    }
  }
  

  render() {
    const { reports, pagination} = this.props;
    return (
      <div>
          <ReportList reports={reports ? reports: 
                              {reportList: []}}
                      reportType= 'Examen'
                      pagination = {pagination}
                      onReloadReport = {this.onReloadReport}
                      onPaginateReport={this.onPaginateReport}
                      onGenerateReportSubmit ={this.onGenerateReportSubmit}
                      onChangeDefaultReport ={this.onChangeDefaultReport}
                      description="En este reporte puede ver el resultado con respecto al total de estudiantes que presentaron el examen">
          </ReportList>
          <div id="rep-container">
            {reports && reports.reportList.length!==0 && 
              this.renderCourseReport(this.props.reports.reportList[`${this.state.currentReport}`].reportInfo)
            }
          </div>  
          
      </div>
    );
  } 
}

function mapStateToProps(state) {
  return { reports: state.reports.examTest, pagination: state.reports.examTestPagination};
}

export default connect(mapStateToProps, { 
  getExamTestReports,
  generateExamTestReports
})(Report);