import React, { Component } from 'react';
import moment from 'moment';

/**
 * Componente para mostrar un periodo de tiempo
 *
 * @export
 * @class CountdownTimer
 * @extends {Component}
 */
export default class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLeft: moment(this.props.targetDate).fromNow(true)
    };

    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    moment.relativeTimeThreshold('s', 40);
    moment.relativeTimeThreshold('ss', 3);
    moment.relativeTimeThreshold('m', 40);
    moment.relativeTimeThreshold('h', 20);
    moment.relativeTimeThreshold('d', 25);
    moment.relativeTimeThreshold('w', 4);  // enables weeks
    moment.relativeTimeThreshold('M', 10);
    this.timer = setInterval(this.countDown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countDown() {
    this.setState({timeLeft: moment(this.props.targetDate).fromNow(true)});
  }

  render() {
    return (
      <div>
        <h3>
          <small>
            Cierra en {this.state.timeLeft} 
          </small>
        </h3>
      </div>
    );
  }
}
