import React from 'react';
import {MAIN_TEXT}  from '../actions/constants';
import Embed from './embed/Embed';

// Función para mostrar el mensaje de bienvenida a la aplicación.
const Home = () => {
  return (
    <Embed src={MAIN_TEXT} title="" height="700px"/>
    );
};

export default Home;

