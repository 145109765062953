import _ from 'lodash';
import { GET_ANSWER_GRADER, GET_ANSWER_GRADERS } from '../actions/answerGradersActions';

/**
 * Manejo de la parte del estado correspondiente a codificadores.
 * Permite la actualización de codificadores y obtener un codificadores por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los codificadores)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = { answerGrader: null }, action) {
  switch (action.type) {
    case GET_ANSWER_GRADERS:

      const answerGraders = _.mapKeys(action.payload.answerGraders, 'id');
      const pagination = action.payload.links;
      const countAnswerGraders = action.payload.countAnswerGraders
      return {...state, countAnswerGraders:countAnswerGraders, answerGraders:answerGraders, pagination: pagination};
    case GET_ANSWER_GRADER:
      const answerGrader = action.payload;
      if (!answerGrader.id) return state;
      return {
        ...state,
        [answerGrader.id]: answerGrader,
        answerGrader: answerGrader
      };
    default:
      return state;
  }
}
