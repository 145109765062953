import { GET_GRADING_CRITERION_OPTIONS } from '../actions/gradingCriteriaOptionActions';

/**
 * Manejo de la parte del estado correspondiente a matrices de codificación.
 * Permite la actualización de matrices de codificación, obtener una matriz de codificación por id.
 * Permite eliminar una matriz de codificación de la lista de matrices de codificación disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las matrices)
 * @returns El estado con la modificación/acción realizada.
 */

const initialState = {
  gradingCriterionOptions: [],
  gradingCriterionOption: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GRADING_CRITERION_OPTIONS:
      return {...state, gradingCriterionOptions: action.payload};
    default:
      return state;
  }
}
