/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconTooltip from "../utils/IconTooltip";
import swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { allByExam, getAllForExam, resetLabelAnswers } from "../../actions/labelAnswerActions";

const STYLES = {
  MAIN: {
    display: 'flex', 
    minHeight: '75vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
  },
  noMargin: {
    margin: 0
  }
}

const Downloads = ({match:{params:{id:idExam}}}) => {

  const reportData= useSelector(state => state.labelAnswers.report)
  const dispatch = useDispatch()

  const [downloads, setDownloads] = useState(0)

  const generateReport = (normal = false) => {
    swal({
      title: "Descargando...",
      showConfirmButton: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },
    })
    if (normal) {
      return dispatch(allByExam(idExam, ()=> {setDownloads(downloads + 1); return (swal.close())}, ()=> swal.close())) 
    }
    
    return dispatch(getAllForExam(idExam, ()=> {setDownloads(downloads + 1); return (swal.close())}, ()=> swal.close())) 
  }

  const descargarArchivo = () => {
    swal({
      title: "Creando Archivo...",
      showConfirmButton: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },
    })
    // contenidoEnBlob, nombreArchivo
    const nombreArchivo = 'archivo.json'
    let contenidoEnBlob = new Blob([JSON.stringify(reportData)], {
      type: 'text/json'
    })
    // parametros anteriores generarTexto({nombre: 'aswin', telefono: '3046278346', fecha: 'hoy'}), 'archivo.json'
    
    
    //creamos un FileReader para leer el Blob
    var reader = new FileReader();
    //Definimos la función que manejará el archivo
    //una vez haya terminado de leerlo
    reader.onload = function (event) {
      //Usaremos un link para iniciar la descarga
      var save = document.createElement('a');
      save.href = event.target.result;
      save.target = '_blank';
      //Truco: así le damos el nombre al archivo
      save.download = nombreArchivo || 'archivo.dat';
      var clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      //Simulamos un clic del usuario
      //no es necesario agregar el link al DOM.
      save.dispatchEvent(clicEvent);
      //Y liberamos recursos...
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    };
    //Leemos el blob y esperamos a que dispare el evento "load"
    reader.readAsDataURL(contenidoEnBlob);
    swal.close()
  }

  // EFECTOS
  useEffect(()=>{
    console.log('HEYYY');
    if (reportData) {
      descargarArchivo(reportData)
    }
    return () => {
      dispatch(resetLabelAnswers())
    }
  },[downloads])

  
  useEffect(()=>{console.log(idExam);},[])
  return (
    <main style={STYLES.MAIN}>
      <section>
        <h1>Descargas de etiquetados</h1>
        <p>descarcas: {downloads}</p>
        <button onClick={()=> generateReport()} className="btn btn-default">
          Descarga en formato label studio
        </button>
        <button onClick={()=> generateReport(true)} className="btn btn-default">
          Descarga documento
        </button>
      </section>
      <div className="row">
        <div className="col-md-6 col-xs-6">
          <Link to={`/exams`} className="btn btn-danger ">
            Volver
          </Link>
        </div>
      </div>
    </main>
  )
}

export default Downloads