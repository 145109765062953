import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import moment from 'moment/moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ROOT_URL } from '../../actions/constants';
import ReportList from '../reports/ReportList';
import { FORBIDDEN_STATUS_CODE,REPORT_PAGINATION } from '../../actions/constants';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Tab from 'react-bootstrap/lib/Tab'
import Tabs from 'react-bootstrap/lib/Tabs'
import ReactTooltip from "react-tooltip";
import { Field,reduxForm,formValueSelector } from 'redux-form';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {Chart} from 'chart.js'
import html2pdf from 'html2pdf.js';
import IconTooltip from '../utils/IconTooltip';


/**
 * Componente para mostrar y descargar el reporte de progreso de respuestas de un examen
 *
 * @class ReportMockups
 * @extends {Component}
 */
class ReportMockups extends Component {
  constructor(props) {
    super(props);
    this.downloadXls = this.downloadXls.bind(this);
    this.state = {
      optionsLoading: false,
      currentReport:0,
      colors: ['red',
              'blue',
              'green',
              'purple',
              'yellow'],
      student:{email: "sbohorquezd1@uniandes.edu.co",
              periods: [{id: 1, title: "2020-20"}, {id: 2, title: "2021-10"}, {id:3, title:"2021-20"}],
              criteria: [{id:1, title:"Oraciones", description:"Corresponde al criterio de oraciones", 
                          results:[{period: "2020-20", value:5, label:"alto"},{period: "2021-10", value:5, label:"alto"}, {period: "2021-20",value:4, label:" intermedio-alto"}]},
                          {id:1, title:"Parrafos", description:"Corresponde al criterio de parrafos", 
                          results:[{period: "2020-20", value:3, label:"Intermedio"},{period: "2021-10", value:2, label:"Intermedio bajo"}, {period: "2021-20",value:1, label:"bajo"}]},
                          {id:1, title:"Coherencia", description:"Corresponde al criterio de coherencia", 
                          results:[{period: "2020-20", value:1, label:"Bajo"},{period: "2021-10",value:3, label:"Intermedio"}, {period: "2021-20",value:4, label:"Intermedio-alto"}]},
                          {id:1, title:"Ortografia", description:"Corresponde al criterio de ortografia", 
                          results:[{period: "2020-20", value:4, label:"Intermedio alto"},{period: "2021-10",value:5, label:"Alto"}, {period: "2021-20",value:1, label:"bajo"}]},
                         ]


      }
    }
  }

  
  componentDidMount() {
    this.criteria_to_graph(this.state.student)
  }

  excelReportLink() {
    const { assignation } = this.props;
    const params = `user_email=${sessionStorage.getItem('email')}&user_token=${sessionStorage.getItem('authenticationToken')}`;

    return `${ROOT_URL}/tests/${assignation.id}/report.xlsx?${params}`;
  }

  downloadXls(elementId, filename){
    const table_div = document.getElementById(elementId);

    const reporte = XLSX.utils.table_to_book(table_div, {sheet:"Reporte"});
    const archivo_reporte = XLSX.write(reporte, {bookType:'xlsx', bookSST:true, type: 'binary'});
    var buffer = new ArrayBuffer(archivo_reporte.length);
    var view = new Uint8Array(buffer);
    for (var i=0; i<archivo_reporte.length; i++) view[i] = archivo_reporte.charCodeAt(i) & 0xFF;

    saveAs(new Blob([buffer],{type:"application/octet-stream"}), filename);
  }   
  renderHistoricReport(student){
    return (
      <div>
      <div className= "col-md-12">
            <h3>Resultado histórico del estudiante: {student.email} </h3>
            <ul className="table-responsive  list-group " >
              <table id="tblStudents" className="table table-striped">
                <thead className="height">
                  <tr className="height">
                    
                    <th className=" blocked">Criterio</th>
                    <th className=" blocked">Descripción</th>
                    {student.periods.map((period) => {
                      return (

                        <th className="col-xs-1">{period.title}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                {student.criteria.map((criteria) => {
                    return (
                      <tr key={criteria.id} className="height">
                        <td className=" blocked" >{criteria.title}</td>
                        <td className=" blocked" >{criteria.description}</td>
                        {criteria.results? criteria.results.map((result) => {
                          return (<><td className=" blocked" >{result.label}</td> </>);}):""}
                      </tr>
                    );
                })}
                </tbody>
              </table>
              </ul> 
          </div>
            <div className="col-md-12 col-xs-12">
              <button
                onClick={() => {
                  let fecha = new Date();
                  fecha = moment(fecha).format('D [de] MMMM [de] YYYY');
                  this.downloadXls('tblStudents',
                                  `Reporte historico de estudiante ${student.email}_${fecha}.xlsx`)
                }}
                type="button"
                className="btn btn-default pull-right"
              ><IconTooltip text="Descargar" icon="fa-download"/>
              </button>
            </div>
      </div>
    );
  }
  renderCriteriaReport(student){
    return (
      <div>
      
        <div>
        <div id={"result-report"} className="table-responsive  col-xs-12">
        <h3>Distribución de resultados para por criterio </h3>
         <div className="graphic col-xs-12">
            <br></br>
            <canvas id={"historic-graph"}  height="auto" width="auto">
            </canvas>
            
          </div>  
        </div>
        <div className="html2pdf__page-break"></div>
        </div>
      </div>
      
    );
  }
  criteria_to_graph(student){
    this.render_graph(this.options_to_graph(student));
  }
  options_to_graph(student){
    var datasets= [];
    var labels = [];
    var max = 6
    var i =0
    var k =0
   student.periods.forEach(period=>{
      labels[i]= period.title
      i=i+1;
   })
   
    student.criteria.forEach(criterion=>{
    var data = [];
    var j=0
      criterion.results.forEach(element =>{
      data[j]= element.value
     
      j=j+1;
      })
      var dataset= {label: criterion.title,
                    data: data,
                    borderColor: this.state.colors[k],
                    backgroundColor: 'rgba(255, 255, 255, 0)'}
      datasets.push(dataset);
      k=k+1;
    })
    var options = { title: student.email,
                    labels: labels,
                    datasets: datasets,
                    max: max}
    return options
  }

render_graph(options){
  document.getElementById("report-container").hidden= false
if (options.datasets.length===0){
  document.getElementById("report-container").hidden= true
}else{
    if(document.getElementById("historic-graph")==null){
      return 
    }
    var ctx = document.getElementById("historic-graph").getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
          labels: options.labels,
          datasets: options.datasets
      },
      options: {
        elements:{
          line: {tension:0}
        },
        responsive: true,
        legend: {
          display: true
          },
          scales: {
              yAxes: [{
                ticks: {
                  stepSize: 1,
                  min: 0,
                  max: options.max                  
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Desempeño'
                },
              }
            ]
          }
      }
    });
  }
}
           
  render() {
      return (
        <div>
           <div id="report-container">
           {this.renderHistoricReport(this.state.student) }
           </div>
           {this.renderCriteriaReport(this.state.student) }
        </div>
      );
    } 
}
const selector = formValueSelector('ReportGenerator')
export default (reduxForm({
    form: 'ReportGenerator',
    
  }))(
      connect(state => {
    const dataValue = selector(state, 'data')
    const agregacionValue = selector(state, 'agregacion')
    return {
    agregacionValue,
    dataValue
    }
  })(ReportMockups))

