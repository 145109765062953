import Axios from "axios";
import React, { useEffect, useState } from "react";
import { hostname, ROOT_URL } from "../actions/constants";

const Version = () => {
  const [backVersion, setBackVersion] = useState('cargando....')
  useEffect(() => {
    Axios(ROOT_URL).then(({data:{version}}) => {
      setBackVersion(version);
    }).catch(() => {
      setBackVersion('sin conexión');
    })
  },[])

  const front_version = process.env.REACT_APP_VERSION
  return (<small style={{position: 'absolute', bottom: '1px', color: 'white', zIndex: '1'}}>
    <p style={{margin: '0px'}}>front V.{front_version}</p>
    <p style={{margin: '0px'}}>back V.{backVersion}</p>
  </small>)
}

export default Version