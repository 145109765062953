import React, { Component } from 'react';

/**
 * Componente para el manejo de un elemento embebido básico
 *
 * @export
 * @class Embed
 * @extends {Component}
 */
export default class Embed extends Component {

  render() {
      return <div id="frameDivId" width="100%" height={this.props.height}>
                <iframe id="mainFrameId" scrolling="no" src={this.props.src} title={this.props.title}
                          frameBorder="0" width="100%" height={this.props.height}>
                </iframe>
             </div>;
  }
}
