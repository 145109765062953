import _ from 'lodash';
import { GET_COURSES, GET_COURSE, GET_COURSE_EXAMS, GET_COURSE_REPORT, GET_COURSE_SEMESTERS , GET_COURSE_MESSAGE, GET_COURSE_SUPER_MATRICES, SET_COURSE_STUDENTS} from '../actions/coursesActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
      case GET_COURSES:
        const courses = _.mapKeys(action.payload.courseSections, 'id');
        const pagination = action.payload.links;
        return { ...state, courses: courses, pagination: pagination};
      case GET_COURSE:
        const course = action.payload;
        return { ...state, [course.id]: course };
      case GET_COURSE_EXAMS:
        const exams= action.payload.exams;
        return { ...state, exams: exams };
      case GET_COURSE_MESSAGE:
        const message= action.payload.message;
        return { ...state, message: message };
      case GET_COURSE_SEMESTERS:
          const semesters= action.payload.semesters;
          return { ...state, semesters: semesters };
      case GET_COURSE_REPORT:
        const report= action.payload.report;
        return { ...state, report: report  };
      case GET_COURSE_SUPER_MATRICES:
        const superMatrices= action.payload.superMatrices;
        return { ...state, superMatrices: superMatrices };
      case SET_COURSE_STUDENTS:
        const result= action.payload.result;
        return { ...state, message: result };
      default:
        return state;
    }
  }