import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de los departamentos.

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_COURSES = 'GET_COURSES';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE = 'GET_COURSE';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE_EXAMS = 'GET_COURSE_EXAMS';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE_SUPER_MATRICES = 'GET_COURSE_SUPER_MATRICES';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE_SEMESTERS = 'GET_COURSE_SEMESTERS';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE_REPORT = 'GET_COURSE_REPORT';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_COURSE_MESSAGE = 'GET_COURSE_MESSAGE';

//Añade estudiantes al curso
export const SET_COURSE_STUDENTS = 'SET_COURSE_STUDENTS';

/**
 * Obtiene las preguntas disponible.
 *
 * @export getCourses
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCourses(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/courses${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getCourseExams
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCourseExams(id,semester, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${id}/semester_exams?semester=${semester}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_EXAMS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getCourseExams
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getCourseSuperMatrices(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${id}/super_matrices`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_SUPER_MATRICES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene la pregunta con el id dado por parámetro.
 *
 * @export getCourseSemesters
 * @param {*} id : El id de la pregunta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCourseSemesters(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_SEMESTERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getCourseReport
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCourseReport(exam_test_id,id,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${id}/agregate_report/?exam_test_option_id=${exam_test_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}
/**
 * Obtiene el resultado de la operacion de asociar docentes.
 *
 * @export addTeachersToCourses
 
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function addTeachersToCourses(participantsInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/courses/add_teachers`, participantsInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_MESSAGE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}

/**
* Obtiene el resultado de la operacion de asociar docentes.
*
* @export addStudentsToCourses
 
* @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
* @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
*                                           La función debe recibir por parámetro el error generado.
* @returns Función de manejo de error o éxito de la petición.
*/
export function addStudentsToCourses(participantsInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/courses/add_students`, participantsInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SET_COURSE_STUDENTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene las matrices de los estudiantes del curso_sección_periodo
 *
 * @export getCoursePeriodSuperMatrices
 * @param {*} id : El id del curso_seccion_periodo
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getCoursePeriodSuperMatrices(id, period, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${id}/super_matrices_course_period?period=${period}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_COURSE_SUPER_MATRICES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}