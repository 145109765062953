import _ from 'lodash';
import { GET_ANSWER_REVIEWER, GET_ANSWER_REVIEWERS, GET_ANSWER_REVIEWER_GRADE } from '../actions/answerReviewersActions';

/**
 * Manejo de la parte del estado correspondiente a revisores.
 * Permite la actualización de revisores y obtener un revisor por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los revisores)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_ANSWER_REVIEWERS:
      const answerReviewers = _.mapKeys(action.payload.answerReviewers, 'id');
      const pagination = action.payload.links;
      const countAnswerReviewers = action.payload.countAnswerReviewers;
      return {...state, countAnswerReviewers:countAnswerReviewers, answerReviewers: answerReviewers, pagination: pagination};
    case GET_ANSWER_REVIEWER:
      const answerReviewer = action.payload;
      return { ...state, answerReviewer: answerReviewer };
      case GET_ANSWER_REVIEWER_GRADE:
      const answerReviewerGrade = action.payload;
      return { ...state, grade: answerReviewerGrade.grade };
    default:
      return state;
  }
}
