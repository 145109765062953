import classNames from 'classnames';
import React from 'react';
// import PropTypes from 'prop-types';

import SafeAnchor from './SafeAnchor';
import createChainedFunction from './createChainedFunction';


const NavItem = ({ active,disabled,onClick,className,style,onSelect,eventKey,...props}) => {

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      if (onSelect) {
        onSelect(eventKey, e);
      }
    }
  };

  delete props.onSelect;
  delete props.eventKey;

  delete props.activeKey;
  delete props.activeHref;

  if (!props.role) {
    if (props.href === '#') {
      props.role = 'button';
    }
  } else if (props.role === 'tab') {
    props['aria-selected'] = active;
  }

  return (
    <li
      role="presentation"
      className={classNames(className, { active, disabled })}
      style={style}
    >
      <SafeAnchor
        {...props}
        disabled={disabled}
        onClick={createChainedFunction(onClick, handleClick)}
      />
    </li>
  );
};

export default NavItem;

// const propTypes = {
//   active: PropTypes.bool,
//   disabled: PropTypes.bool,
//   role: PropTypes.string,
//   href: PropTypes.string,
//   onClick: PropTypes.func,
//   onSelect: PropTypes.func,
//   eventKey: PropTypes.any
// };

// const defaultProps = {
//   active: false,
//   disabled: false
// };

/**
 * Componente de navegación, muestra un tab según los componentes
 * con ruta disponibles para el usuario.
 *
 * @class NavItem
 * @extends {React.Component}
 */

// class NavItem extends React.Component {
//   constructor(props, context) {
//     super(props, context);

//     this.handleClick = this.handleClick.bind(this);
//   }

//   handleClick(e) {
//     if (this.props.disabled) {
//       e.preventDefault();
//     } else {
//       if (this.props.onSelect) {
//         this.props.onSelect(this.props.eventKey, e);
//       }
//     }
//   }

//   render() {
//     const {
//       active,
//       disabled,
//       onClick,
//       className,
//       style,
//       ...props
//     } = this.props;

//     delete props.onSelect;
//     delete props.eventKey;

//     // These are injected down by `<Nav>` for building `<SubNav>`s.
//     delete props.activeKey;
//     delete props.activeHref;

//     if (!props.role) {
//       if (props.href === '#') {
//         props.role = 'button';
//       }
//     } else if (props.role === 'tab') {
//       props['aria-selected'] = active;
//     }

//     return (
//       <li
//         role="presentation"
//         className={classNames(className, { active, disabled })}
//         style={style}
//       >
//         <SafeAnchor
//           {...props}
//           disabled={disabled}
//           onClick={createChainedFunction(onClick, this.handleClick)}
//         />
//       </li>
//     );
//   }
// }

// NavItem.propTypes = propTypes;
// NavItem.defaultProps = defaultProps;

// export default NavItem;

