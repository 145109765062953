import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

/**
 * Componente wrapper para evitar el renderizado de componentes/rutas
 * accesibles para cualquier persona con el link
 *
 * @export
 * @param {*} ComposedComponent
 * @returns
 */
export default function(ComposedComponent) {
  class NotRequireUser extends Component {
      
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { currentUser: state.currentUser };
  }

  return connect(mapStateToProps)(NotRequireUser);
}