import { GET_THEMES, GET_THEME, EDIT_THEME, DELETE_THEME, CREATE_THEME } from '../actions/themeActions';

/**
 * Manejo de la parte del estado correspondiente a los labels para labeling.
 * Permite la actualización de labels, obtener un label por id.
 * Permite eliminar un label por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a los labels)
 * @returns El estado con la modificación/acción realizada.
 */
const initialState = {
  themes: [],
  theme: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_THEMES:
      return {...state,
        themes: action.payload 
      }
    case GET_THEME:
      return {...state,
        theme: action.payload 
      }
    case DELETE_THEME:
      return {...state,
        theme: undefined 
      }
    default:
      return state;
  }
}