import _ from 'lodash';
import { GET_SUPER_MATRICES, GET_SUPER_MATRIX, DELETE_SUPER_MATRIX, GET_SUPER_MATRIX_USERS, GET_SUPER_MATRIX_ASSOCIATED_MATRICES } from '../actions/superMatricesActions';

/**
 * Manejo de la parte del estado correspondiente a matrices de codificación.
 * Permite la actualización de matrices de codificación, obtener una matriz de codificación por id.
 * Permite eliminar una matriz de codificación de la lista de matrices de codificación disponibles.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las matrices)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_SUPER_MATRICES:
      const superMatrices = _.mapKeys(action.payload.superMatrices, 'id');
      const pagination = action.payload.links;
      return {...state, superMatrices: superMatrices, pagination: pagination};
    case GET_SUPER_MATRIX:
      const superMatrix = action.payload;
      return { ...state, [superMatrix.id]: superMatrix };
    case DELETE_SUPER_MATRIX:
      return {...state, superMatrices: _.omit(state.superMatrices, action.payload)};
    case GET_SUPER_MATRIX_USERS:
      const students = action.payload.students
      const pagination_students = action.payload.links;
      return {...state, students: students, pagination_students: pagination_students};
    case GET_SUPER_MATRIX_ASSOCIATED_MATRICES:
      const gradingMatrices = action.payload.gradingMatrices
      return {...state, gradingMatrices: gradingMatrices }
    default:
      return state;
  }
}