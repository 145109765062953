import _ from 'lodash';
import { GET_EXAM_TESTS, GET_EXAM_TEST,
         GET_EXAM_TEST_DISCREPANCY_COD,
         GET_EXAM_TEST_DISCREPANCY_CRIT,
         GET_EXAM_TEST_DISCREPANCY_CRIT_COD,
         GET_EXAM_TEST_PROGRESS, 
         GET_EXAM_TESTS_COURSES, 
         GET_EXAM_TEST_ANSWERS,
         GET_COURSE,
         HAS_ANSWERS_AVIABLE_REQUEST,
         HAS_ANSWERS_AVIABLE_SUCCES,
         HAS_ANSWERS_AVIABLE_FAILURE,
         COUNT_ANSWERS_AVIABLE_REQUEST,
         COUNT_ANSWERS_AVIABLE_SUCCES,
         COUNT_ANSWERS_AVIABLE_FAILURE,
         NO_EFECT
        } from '../actions/examTestsActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas de examen.
 * Permite la actualización de preguntas de examen, obtener una pregunta de examen por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_EXAM_TESTS:
      const examTests = _.mapKeys(action.payload.examTestOptions, 'id');
      const pagination = action.payload.links;
      return { ...state, examTests: examTests, pagination: pagination };
    case GET_EXAM_TESTS_COURSES:
      const pagination_c = action.payload.links
      const examTestsCourses = _.mapKeys(action.payload.courseSections, 'id');
      return { ...state, examTestsCourses: examTestsCourses, pagination_c: pagination_c };
    case GET_EXAM_TEST:
      const examTest = action.payload;
      return { ...state, [examTest.id]: examTest };
    case GET_COURSE:
      const course = action.payload.report;
      return { ...state, course: course };
    case GET_EXAM_TEST_DISCREPANCY_CRIT_COD:
      const discrepancy_crit_cod = action.payload;
      return { ...state, discrepancy_crit_cod: discrepancy_crit_cod};
    case GET_EXAM_TEST_DISCREPANCY_COD:
      const discrepancy_cod = action.payload;
      return { ...state, discrepancy_cod: discrepancy_cod};
    case GET_EXAM_TEST_DISCREPANCY_CRIT:
      const discrepancy_crit = action.payload;
      return { ...state, discrepancy_crit: discrepancy_crit};
    case GET_EXAM_TEST_ANSWERS:
      const answers_report = action.payload;
      return { ...state, answers_report: answers_report};
    case GET_EXAM_TEST_PROGRESS:
      const progress = action.payload;
      return { ...state, progress: progress};
    case HAS_ANSWERS_AVIABLE_REQUEST:
      var pending_has_answers = true;
      return {...state, pending_has_answers}
    case HAS_ANSWERS_AVIABLE_SUCCES:
      const has_answer = action.payload;
      var pending_has_answers = false;
      return { ...state, has_answer: has_answer, pending_has_answers: pending_has_answers};
    case HAS_ANSWERS_AVIABLE_FAILURE:
      var pending_has_answers = false;
      return {...state, pending_has_answers: pending_has_answers};
    case COUNT_ANSWERS_AVIABLE_REQUEST:
      var pending_count_answers = true;
      return {...state, pending_count_answers}
    case COUNT_ANSWERS_AVIABLE_SUCCES:
      const count_answer = action.payload;
      var pending_count_answers = false;
      return { ...state, count_answer: count_answer, pending_count_answers: pending_count_answers};
    case COUNT_ANSWERS_AVIABLE_FAILURE:
      var pending_count_answers = false;
      return {...state, pending_count_answers: pending_count_answers}
    case NO_EFECT:
      return state;
    default:
      return state;
  }
}
