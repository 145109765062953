import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de usuarios en el storage.
export const GET_PROCESSES = 'GET_PROCESSES';
export const GET_VIDEOPROCESSES = 'GET_VIDEOPROCESSES';
export const SEARCH_PROCESSES = 'SEARCH_PROCESSES';




/**
 * Obtiene los procesos asociados a un usuario.
 *
 * @export getProcesses
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getProcesses({pagination = '', search = '', isObserver = false}, successCallback = () => {}, errorCallback = (error) => {}) {
    const searchQuery = search ? `&search%5Bquery%5D=${search.query}&search%5Bvalue%5D=${search.value}` : ''
    const request = axios.get(`${ROOT_URL}/observation_processes${pagination}&[isObserver]=${isObserver}${searchQuery}`, getAuthHeaders());
   
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_PROCESSES,
          payload: search ? {...data, search} : data
        });
        successCallback(data);
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

  /**
 * Obtiene los usuarios de la aplicación.
 *
 * @export getVideoProcesses
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getVideoProcesses(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/observation_processes?[video_id]=${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_VIDEOPROCESSES,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


  export function searchProcess(pagination, text = '', successCallback = () => {}, errorCallback = (error) => {}) {
    const url = `${pagination}&[search]=${encodeURIComponent(text)}`;
    const request = axios.get(`${ROOT_URL}/observation_processes${url}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: SEARCH_PROCESSES,
          payload: {...data, text}
        });
        successCallback();
      }).catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
