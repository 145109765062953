import React, { useState, useEffect } from 'react';
import 'moment/locale/es';
import { ROOT_URL, getAuthHeaders } from '../../actions/constants'
import moment from 'moment';
import axios from 'axios';
import EmbedFile from './EmbedFile' ;

import { getprocessVideos } from "../../actions/videoActions";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "../../actions/constants";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Loading from '../Loading';
import swal from 'sweetalert2';

const ViewProcess = ({currentUser,match,location,history}) => {
  const {isObserverAdmin} = currentUser
  const processId = match.params.id;
  const [form, setForm] = useState({
    id: '',
    createdAt: '',
    deadline: '',
    instructions_file: '',
    title: '',
    updatedAt: '',
  });

  const [members, setMembers] = useState({})
  const [loading, setLoading] = useState(false)
  const fromObserver = location.pathname.includes('/observers/');

  const description = React.useRef(null);
  const instructions = React.useRef(null);

  const dispatch = useDispatch()
  const processVideos = useSelector(state => state.videos.processVideos) 

  useEffect(()=>{
    setLoading(true);
    const getProcess = async () => {
      try {
        const request = await axios.get(`${ROOT_URL}/observation_processes/${processId}?[isObserver]=${fromObserver}`, getAuthHeaders());
        if (request.status === 200) {
          const data = request.data.observationProcess.observationProcess;
          setForm({
            ...form,
            createdAt: data.createdAt,
            deadline: data.deadline,
            instructions_file: data.instructions_file,
            title: data.title,
            updatedAt: data.updatedAt,
          });
          setLoading(false);
          instructions.current.innerHTML = data.instructions_text;
          description.current.innerHTML = data.description;
        }
        if (isObserverAdmin && !fromObserver) {
          const members =  await axios.get(`${ROOT_URL}/observation_processes/members?id=${processId}`, getAuthHeaders());
          request.status === 200 && setMembers(members.data)
        }
              
      } catch (error) {
        history.push('/');
        swal({
          type: 'error',
          title: error.response.data.error,
          showConfirmButton: false,
          timer: 2600
        })
      }
    }
    getProcess();

    dispatch(getprocessVideos(PAGINATION, {processId, isObserver: fromObserver}, () => {}, (error) => {console.log(error)}))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const compareDates = (deadline) => {
    const today= moment().format('YYYY-MM-DD HH:mm')
    if (deadline >= today){
      return true
    } else {
      return false
    }
  }

  const renderHeader = [
    { selector: row => row.id, name: '#', sortable: true},
    { selector: row => row.title, name: 'Titulo',
      cell: (row) => {

        if (fromObserver && row.deadline && compareDates(moment(row.deadline).format('YYYY-MM-DD HH:mm'))) {
          return (<Link to={`/${fromObserver ? 'observers/': ''}videos/${row.id}`} >
            {row.title}
          </Link>)
        } else if (isObserverAdmin && !fromObserver) {
            return (<Link to={`/${fromObserver ? 'observers/': ''}videos/${row.id}`} >
              {row.title}
            </Link>)
        } else {
          return (row.title)
        }
      },
      sortable: true
    },
    { selector: row => row.deadline,
      name: 'Disponible hasta',
      format: (row) => {
        return (row?.deadline ? moment(row?.deadline).format('DD/MM/YYYY HH:mm') : '--/--/----');
      },
    }
  ];

  const renderHeaderObservers = [
    { selector: row => row.id, name: 'ID', sortable: true},
    { selector: row => row.name, name: 'Nombres', sortable: true},
    { selector: row => row.email, name: 'E-mail', sortable: true}
  ]

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  let table_style = {
      maxWidth: "0",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
  }

  let box_style = {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  }

  return (<>{loading ? (<Loading />) : (
    <>
      {(isObserverAdmin && !fromObserver) ? (
        <p>Informacion sobre el proceso de observación #{processId}</p>
      ): (
        <h4>proceso de observación</h4>
      )}
      <h2>{form.title}</h2>
      <br />
      <div className="form-group">
        <div style={box_style} className="row">
          {(isObserverAdmin && !fromObserver) && (
            <>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p>creado el dia</p>
                <h4>{moment(form.createdAt).format('LLLL')}</h4>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p>actualizado por ultima vez el dia</p>
                <h4>{moment(form.updatedAt).format('LLLL')}</h4>
              </div>
            </>
          )}
          <div className="col-md-4 col-sm-6 col-xs-12">
            <p>cierra el dia </p>
            <h4>{moment(form.deadline).format('LLLL')}</h4>
          </div>
        </div>      
      </div>
      <div style={box_style} className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group">
            <h4>Descripcion</h4>
            <br />
            <div ref={description}></div>
          </div>
        </div>
      </div>
      <div style={box_style} className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group">
            <h4>Instrucciones </h4>
            <div ref={instructions}></div>
            <EmbedFile url={form.instructions_file} />
          </div>
        </div>
      </div>
      {/* PARTICIPANTES!!! */}
      {(isObserverAdmin && !fromObserver) && (
        <div style={box_style} className="row">
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <h4>Gestores de observación asociados</h4>
              <DataTable
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage= {5}
                paginationRowsPerPageOptions ={[5,10,15,20]}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="300px"
                data={members?.observerAdmins}
                columns={renderHeaderObservers}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <h4>Observadores asociados</h4>
              <DataTable
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage= {5}
                paginationRowsPerPageOptions ={[5,10,15,20]}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="300px"
                data={members?.observers}
                columns={renderHeaderObservers}
              />
            </div>
          </div>
        </div>
      )}
      {/* VIDEOS */}
      <div style={box_style} className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group">
            <h4>Videos asociados </h4>
            <div>
              <DataTable
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage= {5}
                paginationRowsPerPageOptions ={[5,10,15,20]}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="800px"
                data={processVideos}
                columns={renderHeader}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={() => history.push(`/${fromObserver ? 'observers/': ''}processes`)}
          className="btn btn-danger">
            Volver
        </button>
        { (isObserverAdmin && !fromObserver) ? (
          <button
          onClick={() => history.push(`/processes/edit/${processId}`)}
          className="btn btn-primary pull-right">
          Editar
        </button>
        ):<></>}
        
      </div>
      <br />
      <br />
      <br />
    </>
  )}</>)
}


export default ViewProcess
