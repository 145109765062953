import { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from '../actions/constants';

function useGetRequest(url) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetchIndex, setRefetchIndex] = useState(0);
 
  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${ROOT_URL}/${url}`, getAuthHeaders());
        if (!didCancel) {
          setData(response.data);
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [url, refetchIndex]);

  const refetch = () => {
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  };

  return { data, setData, isLoading, error, refetch };
}

export default useGetRequest;
