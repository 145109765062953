/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import '../../assets/stylesheets/StudentsInfo.css';

export default function Acordion({children = <></>, title = '', open = false, customHeader = <></>, noAfter = false}) {
  const handleChange = (e) => {
    const { target:accordionHeader } = e
    const accordionBody = accordionHeader.nextElementSibling;

    accordionHeader.classList.toggle('accordion__header--active');
    accordionBody.classList.toggle('accordion__body--expand');

  }

  return (<>
    <div className="acordion">
      <div
        className={`accordion__header ${noAfter && 'no_after'} ${ open && 'accordion__header--active' }`}
        onClick={handleChange}
        data-toggle={'tooltip'}
        title={'Ver calificaciones'}
      >
        <h4>{title || ''}</h4>
        {customHeader}
      </div>
      <div
        className={`accordion__body ${ open && 'accordion__body--expand' }`}
      >
        {children}
      </div>
    </div>
  </>)
}