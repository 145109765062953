import _ from 'lodash';
import { GET_FACULTIES, GET_FACULTY, GET_FACULTY_EXAMS , GET_FACULTY_SEMESTERS} from '../actions/facultiesActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
      case GET_FACULTIES:
        const faculties = _.mapKeys(action.payload.faculties, 'id');
        const pagination = action.payload.links;
        return { ...state, faculties: faculties, pagination: pagination};
      case GET_FACULTY:
        const faculty = action.payload;
        return { ...state, [faculty.id]: faculty };
      case GET_FACULTY_EXAMS:
        const exams= action.payload.exams;
        return { ...state, exams: exams };
        case GET_FACULTY_SEMESTERS:
          const semesters= action.payload.semesters;
          return { ...state, semesters: semesters };
      default:
        return state;
    }
  }
  