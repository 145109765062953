import _ from 'lodash';
import { GET_QUESTIONARIES, GET_QUESTIONARY, DELETE_QUESTIONARY, GET_APPROVE_QUESTIONARY, GET_QUESTIONARY_MESSAGE} from '../actions/questionariesActions';

/**
 * Manejo de la parte del estado correspondiente a preguntas.
 * Permite la actualización de preguntas, obtener una pregunta por id.
 * Permite eliminar una pregunta de la lista de preguntas disponibles.
 *
 * NOTA: Dos acciones no se utilizan por el momento: Actualizar el codificador de una pregunta y
 * una variable para manejo de estado (falso o verdadero). Se utilizaban anteriormente
 * para cambiar una asignación (antes de que hubiera examenes).
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
    switch(action.type) {
      case GET_QUESTIONARIES:
        const questionaries = _.mapKeys(action.payload.mcQuestionaries, 'id');
        const pagination = action.payload.links;
        return { ...state, questionaries: questionaries, pagination: pagination};
      case GET_QUESTIONARY:
        const questionary = action.payload;
        return { ...state, [questionary.id]: questionary };
      case DELETE_QUESTIONARY:
        return {...state, questionaries: _.omit(state.questionaries, action.payload)};
      case GET_APPROVE_QUESTIONARY:
        return {...state, questionaries: _.omit(state.questionaries, action.payload)};
      case GET_QUESTIONARY_MESSAGE:
        const questionary_message= action.payload.message;
        return { ...state, questionary_message: questionary_message };
      default:
        return state;
    }
  }