//Completar

import React, { Component } from 'react' ;
import html2pdf from 'html2pdf.js';
// import EmbedFile from '../embed/EmbedFile' ;
import { connect } from 'react-redux';

/**
 * Componente para las respuestas de cuestionario en estado Cerrado
 * Muestra el cuestionario cerrado con los valores seleccionados por el estudiante
 *
 * @class McAnswerClosed
 * @extends {Component}
 */
class McAnswerClosed extends Component {

  downloadAsPdf(){
    const report = document.getElementById('report');
    const options = { margin: [15, 10, 15, 10],// top, left, bottom, right
                      filename: 'reporte.pdf',
                      image: { type: 'png', quality: 2 },
                      html2canvas: { logging: true },
                      jsPDF: { unit: 'mm', format: 'a4', orientation: 'p'} };

    html2pdf().from(report).set(options).toPdf().save();
  }

  renderMcAnswerStructure() {
    const { mcAnswer } = this.props;
    if (mcAnswer.complete) {
      return (
        <div id={"mc_answer_structure"}>
          <div>
              <h2>Tu respuesta fue recibida correctamente</h2>
          </div>
          
        </div>
      );
    } else {
      return <h3>Tu respuesta no fue completada.</h3>
    }
  }
  
  renderMcAnswer() {
    const { mcAnswer } = this.props;
    if ( (mcAnswer.structure===null && mcAnswer.status === "to_do")|| (mcAnswer.structure===null && mcAnswer.status === "in_progress" ) ){
      return <div >Tu respuesta no fue completada.</div>;
    } else if (mcAnswer) {
      return (
        <div>
          {this.renderMcAnswerStructure()}
        </div>
      )
    } else {
      return <div>No hay ninguna respuesta</div>;
    }
  }

  render() {

    const {mcAnswer} = this.props;

    return (
      <div >
        <div className="col-md-12 col-xs-12" id="report">
            {this.renderMcAnswer()}
        </div>
      </div>
    )
  }
}

export default connect(null, {})(McAnswerClosed);
