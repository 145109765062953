import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las matrices de codificación.

// Actualiza las matrices en el estado de la aplicación.
export const GET_GRADING_CRITERION_OPTIONS = 'GET_GRADING_CRITERION_OPTIONS';

/**
 * Obtiene los criterios de todas las matrices disponibles.
 *
 * @export getGradingCriterias
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=(data) => {}] : Función a llamar en caso de petición exitosa.
 *                                             La función debe recibir por parámetro los datos de la respuesta exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getGradingCriterionOptions(successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.get(`${ROOT_URL}/grading_matrices/criterion_options`, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADING_CRITERION_OPTIONS,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
