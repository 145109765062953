import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/lazy'
import { getMultimediaUrl } from '../../actions/answersActions';
import { AZURE_STORAGE_MULTIMEDIA_CONTAINER, AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER} from '../../actions/constants';
import swal from 'sweetalert2';
import '../../assets/stylesheets/ecentroevaluacion/videoStyles.css';
/**
 * Componente que muestra el reproductor de contenido multimedia de azure.
 *
 * @export AnswerMultimedia
 * @class AnswerMultimedia
 * @extends {Component}
 */


// TODO ----> REFACTOR HOOKS!

export class AnswerMultimediaPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            urlMultimedia: "",
            contentType: "",
            progress: ""
          };    
        this.reloadVideo = this.reloadVideo.bind(this);    
        this.setProgress = this.setProgress.bind(this);    
    }

    getContentType(filename){
        try {
            var format = filename.split(".")
        }
        catch (error) {
            console.log("No es formato valido")
        }
        if (["mp4", "mpeg", "mov","webm"].includes(format[format.length -1])){
            var  contentType ="video"
        } else if (["mp3"].includes(format[format.length -1])){
            var  contentType ="audio"
        }
        return contentType;
    }

    async componentDidUpdate(prevProps){
        if (prevProps.url!== this.props.url && this.props.url !== undefined){
            const { BlobServiceClient } = require('@azure/storage-blob');
            const blobSasUrl = this.props.url
            const blobServiceClient = new BlobServiceClient(blobSasUrl);
            let containerName;
            if (!this.props.forAnswers){
               containerName = AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER;
            } else {
                containerName = AZURE_STORAGE_MULTIMEDIA_CONTAINER;
            }
            const containerClient = blobServiceClient.getContainerClient(containerName);
            var blob =containerClient.getBlockBlobClient(this.props.filename);
            const contentType=this.getContentType(this.props.filename);
            this.setState({urlMultimedia: blob.url ,contentType: contentType}); 
        }
    }
    fmtMSS(s){return (s-(s%=60))/60+(9<s?':':':0')+s}

    reloadVideo(e){
        const time= this.state.progress
        var formattedTime =this.fmtMSS(Math.ceil(time))
        // console.log("video-reloaded")
        swal({
            type: 'warning',
            title: 'Ha ocurrido un error, tiempo de video transcurrido: '+ formattedTime ,
            showConfirmButton: true,
          }).then(
            this.props.onReloadVideo()
            )
    }
    setProgress(e){
        this.setState({progress: e.playedSeconds})
    }
  
    renderPlayer(){
        if (this.state.contentType==="video"){
            return (
            <ReactPlayer
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onProgress ={e => this.setProgress(e) }
            onError={e => this.reloadVideo(e)}
            onContextMenu={e => e.preventDefault()}
            url={this.state.urlMultimedia}
            className="react-player video"
            controls
            width="854"
            height="480"
            />
            );
        }
        if (this.state.contentType==="audio"){
            return (
                <audio src={this.state.urlMultimedia} controls={true} preload="metadata"></audio>
            );  
        } else {
            if (this.props.forAnswers){  
            return (
                <div>Ocurrió un error al reproducir el archivo multimedia, intenta descargar el archivo desde este <a  href={this.state.urlMultimedia} target="_blank" rel="noopener noreferrer"> enlace </a></div>
            );  }
        }
    }



    render() {
        if (this.props.filename===null){
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div>   
                    {this.props.forAnswers && 
                        <h3>Respuesta multimedia:</h3>
                    }
                    {this.renderPlayer()}
                </div>
            );
        }
    }
}
function mapStateToProps(state) {
    return { state: state};
}

export default connect(mapStateToProps,{})(AnswerMultimediaPlayer);
