/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import DataTable from "react-data-table-component";
import { useSelector , useDispatch} from 'react-redux';
import { PAGINATIONCOMPONENTOPTIONS } from "../../contants";
import swal from "sweetalert2";
import { deleteTheme, getThemes } from "../../actions/themeActions";

const STYLES = {
	main: {
		display: 'flex', 
		minHeight: '75vh',
		flexFlow: 'column nowrap',
		justifyContent: 'space-between',
	},
	rowCenter: {
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'row',
	},
	noMargin: {
		margin: 0
	},
	section: {
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',
	}
}

const Themes = (props) => {

	const labels = useSelector(state => state.themes.themes)
  const dispatch = useDispatch()

	const renderHeaderStudents = [
		{ selector: row => row.name, name: 'Tema',  sortable: true },
		{ 
			selector: row => row.id,
			name: 'Acciones',
			width: "200px",
			// eslint-disable-next-line react/display-name
			cell: (row) => (
				<>
					<button
						className="btn btn-info"
						data-toggle="tooltip"
						title="Eliminar tema"
						onClick={
								()=> removeTheme(row.id)
						}
					>
						<i className="fa fa-trash" aria-hidden="true"></i>
					</button>
					<button
						onClick={() => props.history.push(`/themes/form/${row.id.$oid}`)}
						className="btn btn-info"
					>
						Editar
					</button>
				</>
			)
		}
	]

	const removeTheme = ({$oid:theme_id}) => {
    swal({
      title: '¿Estás seguro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        dispatch(deleteTheme(theme_id, () => { dispatch(getThemes()) }))
        swal({
          type: 'success',
          title: 'Integrante eliminado',
          showConfirmButton: false,
          timer: 1200
        });
      }
    });
  }

	useEffect(()=>{
		dispatch(getThemes())
	},[])

	return (
	<>
		<main style={STYLES.main}>
			<section>
				<h1>Temas</h1>
				{labels?.length > 0 ? (
					<DataTable
						paginationComponentOptions={PAGINATIONCOMPONENTOPTIONS}
						paginationPerPage= {5}
						paginationRowsPerPageOptions ={[5,10,15,20]}
						pagination
						fixedHeader
						fixedHeaderScrollHeight="35vh"
						data={labels}
						columns={renderHeaderStudents}
						customStyles={{table: {style: {zIndex: 0}}}}
					/>
				) : (
					<>
						<h3>No hay temas disponibles, ¡crea uno!</h3>
					</> 
				)}
			</section>
			<section style={STYLES.section}>
				<button
					style={{marginRight: 'auto'}}
					onClick={() => props.history.push('/')}
					type="button"
					className="btn btn-danger">
					Volver
				</button>
				<button
					onClick={() => props.history.push(`/themes/form`)}
					className="btn btn-info">
					Nuevo tema
				</button>
			</section>
		</main>
	</>)
}

export default Themes