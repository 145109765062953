import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de codificaciones de codificación de respuestas.

// Actualiza las codificaciones de codificación disponibles en el estado.
export const GET_ANSWER_GRADERS = 'GET_ANSWER_GRADERS';

// Obtiene la asignación de codificación pedida y la pone en el estado.
export const GET_ANSWER_GRADER = 'GET_ANSWER_GRADER';

/**
 * Obtener todas las codificaciones de codificación disponibles (respuestas-codificadores).
 *
 * @export getAnswerGraders
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerGraders(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_graders${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtener una asignación de codificación por id (respuesta-codificador).
 *
 * @export getAnswerGrader
 * @param {*} id : El id de la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswerGrader(id, is_bank_answer=false, begins=false, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/answer_graders/${id}?is_bank_answer=${is_bank_answer}&begins=${begins}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Permite establecer la información de la codificación de la asignación con el id dado
 * pero sin cambiar el estado de la misma.
 *
 * @export partialGradeAnswerGrader
 * @param {*} id : El id de la asignación.
 * @param {*} grades : Información de la codificación asociada a la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function partialGradeAnswerGrader(id, grades,is_bank_answer=false, successCallback = () => {}, errorCallback = (error) => {}) {

  const request = axios.patch(`${ROOT_URL}/answer_graders/${id}/partial_grade?is_bank_answer=${is_bank_answer}`, grades, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADER,
        payload: data
      });
      successCallback();
      
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Permite establecer la información de la codificación de la asignación con el id dado
 * cambiando el estado de la misma.
 *
 * @export gradeAnswerGrader
 * @param {*} id : El id de la asignación.
 * @param {*} grades : Información de la codificación asociada a la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function gradeAnswerGrader(id, grades,is_bank_answer=false, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_graders/${id}/grade?is_bank_answer=${is_bank_answer}`, grades, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Permite sobreescribir la información de la codificación de la asignación con el id dado.
 *
 * @export overwriteAnswerGrader
 * @param {*} id : El id de la asignación.
 * @param {*} grades : Información de la codificación asociada a la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function overwriteAnswerGrader(id, grades, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_graders/${id}/overwrite`, grades, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Permite sobreescribir la información de la codificación de la asignación con el id dado.
 *
 * @export updateComment
 * @param {*} id : El id de la asignación.
 * @param {*} grades : Información de la codificación asociada a la asignación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateComments(id, comments, is_bank_answer=false,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/answer_graders/${id}/update_comments?is_bank_answer=${is_bank_answer}`, {comments: comments[0], author: comments[1]} , getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
