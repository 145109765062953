/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Datetime from 'react-datetime'
import moment from "moment";
import usePostRequest from "../../../customHooks/usePostRequest";
import usePutRequest from "../../../customHooks/usePutRequest ";
import Loading from "../../Loading";
import swal from 'sweetalert2';
import { useSelector , useDispatch} from 'react-redux';
import { getExam } from "../../../actions/examsActions";
import { getApwindow } from "../../../actions/apwindowsActions";

const styles = {
  main: {
    display: 'flex', 
    minHeight: '75vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  box_style: {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  }
}

const ApwindowForm = (props) => {

  // id's segun la url
  const id = props.match.params.id;
  const id_ventana = props.match?.params?.apwindow_id;
  
  // estados del reducer
  const exam = useSelector(state => state?.exams);
  const currentApwindow = useSelector(state => state?.apwindows?.apwindow);
  
  // despachador
  const dispatch = useDispatch()

  // estados
  const [examInfo, setExamInfo] = useState({
    title: '',
    initialDate: '',
    finalDate:  '',
  })

  const [form, setForm] = useState({
    title: '',
    startDate: '',
    deadline:  '',
  })

  // custom hooks
  const {  sendRequest:createApwindow, loading:createLoader, data:createData, error: createError  } = usePostRequest('apwindows')
  const {  sendRequest:updateApwindow, loading:updateLoader, data:updateData, error:updateError } = usePutRequest(`apwindows/${id_ventana}`)

  // eventos
  const handleChange = ({name, value}) => {
    setForm({ ...form, [name]: value })
  }

  const saveChanges = async (e) => {
    e.preventDefault()
    const dataform = {
      title: form.title,
      start_date: form.startDate,
      deadline: form.deadline
    }

    if (id_ventana) {
      await updateApwindow(dataform)
    } else {
      await createApwindow({...dataform, exam_id: id})
    }
  }

  // validaciones
  const emptyInfo = () => {
    swal({
      title: '¡hey!...',
      text: 'El examen y/o ventana no existe',
      allowOutsideClick: false,
      type: 'error'
    }
    ).then((() => props.history.push(`/exams`)))
  }

  const successUpdate = () => {
    swal({
      type: 'success',
      title: `Ventana ${id_ventana ? 'actualizada' : 'Creada'}`,
      showConfirmButton: false,
      timer: 1000
    }).then(()=> props.history.push(`/exams/${id}/apwindows`))
  }

  const renderError = (error) => {
    if (!error.response) return (<li className="has-error">{error.message}</li>) 
    const {data} = error?.response

    return (<ul className="text-danger">
      {data.exception ? (
        <li key={`${data.error}_${data.status}`}>{data.exception}</li>
      ) : Object.keys(data).map((field, i) => (<React.Fragment key={`${field}_${i}`}>
        {data[field]?.map((reason, i) => (
          <li key={`${reason}_${i}`}>{reason}</li>
        ))}
      </React.Fragment>))}
    </ul>)
  }

  // Efectos
  useEffect(() => {
    if (currentApwindow && id_ventana) {
      let {title, initialDate, finalDate } = currentApwindow.exam
      let {title:apwindowTitle, startDate, deadline} = currentApwindow
      setExamInfo({
        title, initialDate, finalDate
      })

      setForm({
        title: apwindowTitle, startDate, deadline
      })
    } else 
    if (exam && exam[id]) {
      const {title, initialDate, finalDate } = exam[id]
      setExamInfo({
        title, initialDate, finalDate
      })
    }
  },[exam, currentApwindow])

  useEffect(() => {
    if (id_ventana) {
      dispatch(getApwindow(id_ventana,()=>{}, ()=> {setExamInfo(undefined)}))
    } else {
      dispatch(getExam(id,()=>{}, ()=> {setExamInfo(undefined)}))
    }
  },[])

  // render

  if (updateData || createData) return <>{successUpdate()}</>
  
  if (!examInfo) return <h2>{emptyInfo()}</h2>

  if (updateLoader ||  createLoader) return <Loading />

  return (<>
    <form onSubmit={saveChanges}>
    <main style={styles.main}>
      <section>
        <h1>{id_ventana ? 'Editar' : 'Nueva'} ventana de aplicación</h1>
        <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'end', justifyContent: 'space-between'}}>
          <p><b>Examen:</b> {examInfo.title}</p>
          <p>
            <b>Comienza:</b> {moment(examInfo.initialDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
            &nbsp;&nbsp; - &nbsp;&nbsp;
            <b>Finaliza:</b> {moment(examInfo.finalDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
          </p>
        </div>
        <div className="form-group">
          <div style={styles.box_style} className="row">
            <div className="col-md-6 col-xs-12">
              <label>Nombre de la ventana de aplicación</label>
              <input
                type="text"
                name="title"
                id="processName"
                required
                value={form.title}
                className='form-control'
                onChange={(e) => handleChange(e.target)}
              />
            </div>
            <div className="col-md-3 col-xs-6">
              <label>Inicia a las:</label>
              <Datetime
                inputProps={{
                    required: true
                }}
                locale='es'
                value={moment(form.startDate)}
                onChange={(value) => handleChange({name: 'startDate', value:  moment(value).format('YYYY-MM-DD HH:mm')})}
              />
            </div>
            <div className="col-md-3 col-xs-6">
              <label>Finaliza a las:</label>
              <Datetime
                inputProps={{
                    required: true
                }}
                locale='es'
                onChange={(value) => handleChange({name: 'deadline', value:  moment(value).format('YYYY-MM-DD HH:mm')})}
                value={moment(form.deadline)}
              />
            </div>
          </div>
        </div>
        <div>
          {(createError || updateError) && renderError(createError || updateError)}
        </div>
      </section>
      <section 
        style={{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
        }}
      >
        <button
          onClick={() => props.history.push(`/exams/${id}/apwindows`)}
          type="button"
          className="btn btn-danger">
          Volver
        </button>
        <button
          type="submit"
          className="btn btn-info">
          {id_ventana ? 'Actualizar' : 'Crear'} ventana
        </button>
      </section>
    </main>
    </form> 
  </>)
}

export default ApwindowForm