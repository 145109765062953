import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de acciones sobre el estado de los integrantes de las ventanas de aplicacion.

// Actualización de los integrantes de las ventanas de aplicación en el estado
export const GET_ALL_APWINDOW_USERS = 'GET_ALL_APWINDOW_USERS';

// Actualización de los integrantes de las ventanas de aplicación en el estado
export const GET_APWINDOW_USERS = 'GET_APWINDOW_USERS';

// Actualización del integrante de las ventanas de aplicación en el estado
export const GET_APWINDOW_USER = 'GET_APWINDOW_USER';

// Actualización del integrante de las ventanas de aplicación en el estado
export const DELETE_APWINDOW_USER = 'DELETE_APWINDOW_USER';



/**
 * Obtiene todos los integrantes de una ventana de aplicación.
 *
 * @export getApwindowUsers
 * @param apwindowId : id de la ventana de aplicación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getApwindowUsers(apwindowId, successCallback = () => {}, errorCallback = (error) => {}) {

	const request = axios.get(`${ROOT_URL}/apwindow_user_options/${apwindowId}/from_apwindow`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: GET_APWINDOW_USERS,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if(!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		})
	}
}

/**
 * Borra un integrante de una ventana de aplicación.
 *
 * @export removeApwindowUser
 * @param apwindowUserId : id del integrante a eliminar de la ventana de aplicación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function removeApwindowUser(apwindowUserId, successCallback = () => {}, errorCallback = (error) => {}) {

	const request = axios.delete(`${ROOT_URL}/apwindow_user_options/${apwindowUserId}`, getAuthHeaders());

	return (dispatch) => {
		request.then(({data}) => {
			dispatch({
				type: DELETE_APWINDOW_USER,
				payload: data
			});
			successCallback();
		}).catch((error) => {
			if(!isUnauthorizedError(error, dispatch)) {
				errorCallback(error);
			}
		})
	}
}