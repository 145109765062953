import { GET_APWINDOW_USERS, } from '../actions/apwindowUsersActions';

/**
 * Manejo de la parte del estado correspondiente a examenes.
 * Permite la actualización de examenes, obtener un examen por id.
 * Permite eliminar un examen por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
const initialState = {
  allUsers: [],
  fromApwindow: {},
  user:[]
}

export default function (state = initialState, action) {
  switch(action.type) {
    case GET_APWINDOW_USERS:
      return {
        ...state,
        fromApwindow: action.payload 
      };
    default:
      return state;
  }
}
