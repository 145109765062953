import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_EXAM_TESTS = 'GET_EXAM_TESTS';

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_EXAM_TESTS_COURSES = 'GET_EXAM_TESTS_COURSES';

// Actualiza todas las preguntas en el estado de la aplicación.
export const GET_COURSE = 'GET_COURSE';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_EXAM_TEST = 'GET_EXAM_TEST';

// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_DISCREPANCY_CRIT_COD = 'GET_EXAM_TEST_DISCREPANCY_CRIT_COD';

// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_DISCREPANCY_COD = 'GET_EXAM_TEST_DISCREPANCY_COD';

// Actualiza los datos de discrepancia de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_DISCREPANCY_CRIT = 'GET_EXAM_TEST_DISCREPANCY_CRIT';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_PROGRESS = 'GET_EXAM_TEST_PROGRESS';

// Actualiza los datos de avance de codificación (codificación) de la pregunta actual en el estado de la aplicación
export const GET_EXAM_TEST_ANSWERS = 'GET_EXAM_TEST_ANSWERS';

// Indica que se envia la petición para saber si el examen tiene respuestas disponibles
export const HAS_ANSWERS_AVIABLE_REQUEST = 'HAS_ANSWERS_AVIABLE_REQUEST';


// Indica que se envia la petición para saber si el examen tiene respuestas disponibles
export const COUNT_ANSWERS_AVIABLE_REQUEST = 'COUNT_ANSWERS_AVIABLE_REQUEST';

// Obtiene un booleano indicando si el exam_test tiene preguntas sin asignar e indica que la petición acabó
export const HAS_ANSWERS_AVIABLE_SUCCES = 'HAS_ANSWERS_AVIABLE_SUCCES';

// Obtiene un booleano indicando si el exam_test tiene preguntas sin asignar e indica que la petición acabó
export const COUNT_ANSWERS_AVIABLE_SUCCES = 'COUNT_ANSWERS_AVIABLE_SUCCES';

// Indica que acabó la petición para saber si el examen tiene respuestas disponibles y hubo un error
export const HAS_ANSWERS_AVIABLE_FAILURE = 'HAS_ANSWERS_AVIABLE_FAILURE';

// Indica que acabó la petición para saber si el examen tiene respuestas disponibles y hubo un error
export const COUNT_ANSWERS_AVIABLE_FAILURE = 'COUNT_ANSWERS_AVIABLE_FAILURE';

// Indica que acabó la petición para saber si el examen tiene respuestas disponibles y hubo un error
export const NO_EFECT = 'NO_EFECT';

/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getExamTests
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTests(exam_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${pagination}&exam_id=${exam_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TESTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene una pregunta con el test_id dado por parámetro, de un examen con el exam_id dado por parámetro.
 *
 * @export getExamTest
 * @param {*} exam_test_id : El id de la pregunta de examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTest(exam_test_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) { 
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el reporte de codificaciones de una pregunta en un examen con el exam_test_id dado.
 *
 * @export getExamTestsAnswers
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestsAnswers(exam_test_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/answers_report`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_ANSWERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el reporte de codificaciones de una pregunta en un examen con el exam_test_id dado.
 *
 * @export getExamTestReport
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestReport(exam_test_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/report`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el reporte de diferencia de medias entre codificadores por criterio en un examen con el exam_test_id dado.
 *
 * @export getExamTestDiscrepancyCritCod
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestDiscrepancyCritCod(exam_test_id, assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/report_discrepancy_crit_cod?assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_DISCREPANCY_CRIT_COD,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el reporte de diferencia de medias entre codificadores por criterio en un examen con el exam_test_id dado.
 *
 * @export getExamTestDiscrepancyCod
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestDiscrepancyCod(exam_test_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/report_discrepancy_cod?assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_DISCREPANCY_COD,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene el reporte de diferencia de medias entre codificadores por criterio en un examen con el exam_test_id dado.
 *
 * @export getExamTestDiscrepancyCod
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestDiscrepancyCrit(exam_test_id,assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/report_discrepancy_crit?assignation_id=${assignation_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_DISCREPANCY_CRIT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el reporte de diferencia de medias entre codificadores por criterio en un examen con el exam_test_id dado.
 *
 * @export getExamTestGradingProgressReport
 * @param {*} exam_test_id : El id de la pregunta del examen
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestGradingProgressReport(exam_test_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/report_grading_progress`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST_PROGRESS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la información de los codificadores y revisores en la pregunta del examen con exam_test_id dado y la
 * información en el parámetro participantsInfo (graders_ids, reviewers_ids)
 *
 * @export
 * @param {*} exam_test_id : El id de la pregunta del examen a actualizar.
 * @param {*} participantsInfo : Ids de los codificadores y los revisores.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function saveParticipantsExamTest(exam_test_id, participantsInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exam_test_options/${exam_test_id}/save_participants`, participantsInfo, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}



// Sin uso por ahora
/**
 * Elimina la asignación de un codificador con el id dado por parámetro,
 * de la pregunta con id dado por parámetro, del examen con el exam_id dado por parámetro
 *
 * @export removeGraderExamTest
 * @param {*} id : El id de la pregunta.
 * @param {*} exam_id: El id del examen.
 * @param {*} graderId: El id del codificador.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function removeGraderExamTest(exam_test_id, graderId, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exam_test_options/${exam_test_id}/remove_grader`, { grader_id: graderId}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TEST,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene las preguntas del examen con el id dado.
 *
 * @export getExamTestsCourses
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExamTestsCourses(exam_test_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/courses/${pagination}&exam_test_option_id=${exam_test_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM_TESTS_COURSES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


export function hasAnswersAviable(exam_test_id, successCallback = () => {}, errorCallback = (error) => {} ){
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/has_answers_for_assign`, getAuthHeaders());

  return (dispatch) => {
    dispatch({
      type: HAS_ANSWERS_AVIABLE_REQUEST
    })
    request.then(({data}) => {
      dispatch({
        type: HAS_ANSWERS_AVIABLE_SUCCES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) { 
        dispatch({
          type: HAS_ANSWERS_AVIABLE_FAILURE
        });
        errorCallback(error);
      }
    });
  }
}

export function countAnswersAviable(exam_test_id, onlyFinished = false, successCallback = () => {}, errorCallback = (error) => {} ){
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/count_answers_for_assign?onlyFinished=${onlyFinished}`, getAuthHeaders());

  return (dispatch) => {
    dispatch({
      type: COUNT_ANSWERS_AVIABLE_REQUEST
    })
    request.then(({data}) => {
      dispatch({
        type: COUNT_ANSWERS_AVIABLE_SUCCES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) { 
        dispatch({
          type: COUNT_ANSWERS_AVIABLE_FAILURE
        });
        errorCallback(error);
      }
    });
  }
}

export function getTestQuantities(exam_test_id, successCallback = () => {}, errorCallback = (error) => {} ){
  const request = axios.get(`${ROOT_URL}/exam_test_options/${exam_test_id}/get_quantities`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: NO_EFECT,
        payload: data
      });
      successCallback(data);
    })
    .catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) { 
        dispatch({
          type: NO_EFECT
        });
        errorCallback(error);
      }
    });
  }
}






